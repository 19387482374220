import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TbUserEdit as ReassignIcon, TbPencil as EditIcon } from 'react-icons/tb';
import { IoIosArrowForward as DetailsIcon } from 'react-icons/io';
import { mdyyyyFromDate } from '../../../helpers/DateFormatter';
import GeneralCell from '../../GeneralCell';
import CheckBox from '../../shared/CheckBox';
import StickyColumnCell from '../../shared/StickyColumnCell';
import { calculateOverdue, formatRxStatus } from './helpers';
import { RX_STATUS, personPropTypes } from './types';
import EmpanelmentCell from '../shared/EmpanelmentCell';
import Button from '../../shared/Button';
import MemberCell from '../../MemberCell';
import AppointmentOccurrenceCell from '../../AppointmentOccurrenceCell';
import { usePrescriptionsQueueContext } from './usePrescriptionsQueueContext';

const noPermissionMessage = 'You can only edit tasks that are ready for review.';

function PrescriptionQueueTableRow({ testID, row, selected, onRowCheckboxChange, containerElement }) {
  const {
    member,
    status,
    reason,
    appointmentOccurrence,
    assignee,
    dueDate,
    empanelmentGroup,
    detailsUrl,
    onReassignRow,
    onEditRow,
    canManageRxQueue,
  } = row || {};
  const { canEditFinishedTasks } = usePrescriptionsQueueContext();

  const formattedDueDate = mdyyyyFromDate(dueDate);
  const statusFormat = formatRxStatus(status);
  const overdueDate = calculateOverdue(dueDate);
  const readyForReview = status === RX_STATUS.READY_FOR_REVIEW;

  const canEdit = readyForReview || canEditFinishedTasks;

  const onCheckboxToggle = () => onRowCheckboxChange(row);

  return (
    <>
      <GeneralCell
        testID={`checkbox-${testID}`}
        classes="ml-3 mb-2"
        data={<CheckBox checked={selected} onChange={onCheckboxToggle} />}
      />
      <MemberCell testID={`member-${testID}`} member={member} />
      <GeneralCell
        testID={`status-${testID}`}
        data={statusFormat.status}
        classes={classNames('mt-2 mb-2', statusFormat.color, statusFormat.weight)}
      />
      <GeneralCell
        testID={`reason-${testID}`}
        classes={classNames('mt-2 mb-2', { 'text-gray-700 italic': !reason })}
        data={reason || 'None provided'}
      />
      <AppointmentOccurrenceCell testID={`appointment-${testID}`} appointmentOccurrence={appointmentOccurrence} />
      <GeneralCell
        testID={`due-date-${testID}`}
        classes={classNames('mt-2', { 'mb-2': !overdueDate })}
        subText={readyForReview && overdueDate && <span className="font-semibold text-error-700">{overdueDate}</span>}
        data={formattedDueDate}
      />
      <GeneralCell
        testID={`assignee-${testID}`}
        classes="mt-2 mb-2"
        data={assignee && `${assignee.firstName} ${assignee.lastName}`}
      />
      <EmpanelmentCell empanelment={empanelmentGroup} testID={`empanelment-${testID}`} />
      <StickyColumnCell containerElement={containerElement}>
        {canManageRxQueue && (
          <>
            <button
              type="button"
              title="Reassign"
              className="w-9 h-9 rounded-full border-2 border-teal-700 flex items-center justify-center hover:bg-gray-100 cursor-pointer"
              onClick={() => onReassignRow(row)}
              data-testid={`reassign-${testID}`}
            >
              <ReassignIcon className="w-6 h-6 text-teal-800" />
            </button>
            <Button
              type="button"
              title="Edit"
              classes="w-9 h-9 rounded-full border-2 border-teal-700 flex items-center justify-center hover:bg-gray-100 cursor-pointer disabled:opacity-20 disabled:cursor-not-allowed"
              onClick={() => onEditRow(row)}
              data-testid={`edit-${testID}`}
              isDefaultButton={false}
              disabled={!canEdit}
              disabledMessage={noPermissionMessage}
            >
              <EditIcon className="w-6 h-6 text-teal-800" />
            </Button>
          </>
        )}
        <a
          href={detailsUrl}
          target="_top"
          title="Details"
          className="w-9 h-9 rounded-full bg-gray-800 flex items-center justify-center hover:bg-gray-700 cursor-pointer"
          rel="noreferrer"
          data-testid={`details-url-${testID}`}
        >
          <DetailsIcon className="w-6 h-6 text-white" />
        </a>
      </StickyColumnCell>
    </>
  );
}

PrescriptionQueueTableRow.propTypes = {
  testID: PropTypes.string.isRequired,
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    member: personPropTypes.isRequired,
    status: PropTypes.oneOf(Object.values(RX_STATUS)).isRequired,
    reason: PropTypes.string,
    appointmentOccurrence: PropTypes.shape({
      id: PropTypes.number.isRequired,
      appointmentType: PropTypes.string.isRequired,
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        })
      ).isRequired,
      memberStatus: PropTypes.string,
      scheduledAt: PropTypes.string.isRequired,
      status: PropTypes.string,
    }),
    dueDate: PropTypes.string,
    assignee: personPropTypes,
    empanelmentGroup: PropTypes.shape({
      id: PropTypes.number.isRequired,
      dateTime: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      counselor: personPropTypes,
      provider: personPropTypes,
    }),
    detailsUrl: PropTypes.string.isRequired,
    onReassignRow: PropTypes.func.isRequired,
    onEditRow: PropTypes.func.isRequired,
    canManageRxQueue: PropTypes.bool,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  onRowCheckboxChange: PropTypes.func.isRequired,
  containerElement: PropTypes.instanceOf(HTMLElement),
};

PrescriptionQueueTableRow.defaultProps = {
  containerElement: null,
};

export default PrescriptionQueueTableRow;
