import { uniq, isEmpty } from 'lodash';
import { FILTER_TYPES, OUTBOUND_FORM_TYPE } from './constants';
import { isConditionalQuestionVisibleChain } from '../../documentation/helpers/healthieFormHelpers';
import { convertToSupportedTimeZone } from '../../../helpers/TimezoneHelper';
import { convertObjKeysToCamelCase } from '../../../helpers/utils';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from '../../../helpers/DateFormatter';

export const addUniqueIds = (data) =>
  data.map((item) => ({
    ...item,
    unique_id: item.id ? `dp_${item.type}_${item.id}` : `mrn_${item.type}_${item.chartingNotes[0]?.externalId}`,
  }));

export const sortEncounters = (data, sortingKey, desc) => {
  if (!data) return undefined;

  switch (sortingKey) {
    case 'scheduledAt':
      return [...data].sort((a, b) => {
        if (desc) return new Date(b.eventAt) - new Date(a.eventAt);
        return new Date(a.eventAt) - new Date(b.eventAt);
      });
    case 'scheduledAtTime':
      return data
        .map((item) => {
          const [dateTimeWithOffset] = convertToSupportedTimeZone(item.eventAt);
          return {
            ...item,
            localTime: dateTimeWithOffset.format('HH:mm'),
          };
        })
        .sort((a, b) => {
          if (desc) return b.localTime.localeCompare(a.localTime);
          return a.localTime.localeCompare(b.localTime);
        })
        .map(({ localTime: _localTime, ...rest }) => rest);
    case 'location':
      return [...data].sort((a, b) => {
        if (!a.locations && !b.locations) return 0;
        if (!b.locations) return -1;
        if (!a.locations) return 1;

        const aLocations = a.locations.map(({ name }) => name).join();
        const bLocations = b.locations.map(({ name }) => name).join();

        if (desc) return bLocations.localeCompare(aLocations);
        return aLocations.localeCompare(bLocations);
      });
    case 'document':
      return [...data].sort((a, b) => {
        const joined_a = a.chartingNotes.map(({ name }) => name).join();
        const joined_b = b.chartingNotes.map(({ name }) => name).join();

        if (desc) return joined_b.localeCompare(joined_a);
        return joined_a.localeCompare(joined_b);
      });
    case 'staff':
      return [...data].sort((a, b) => {
        const sorted_a = a.staff.toSorted().join();
        const sorted_b = b.staff.toSorted().join();

        if (desc) return sorted_b.localeCompare(sorted_a);
        return sorted_a.localeCompare(sorted_b);
      });
    case 'type':
      return [...data].sort((a, b) => {
        const type_a = a.type + a.subtype;
        const type_b = b.type + b.subtype;

        if (desc) return type_b.localeCompare(type_a);
        return type_a.localeCompare(type_b);
      });
    default:
      return [...data].sort((a, b) => {
        const value_a = a[sortingKey];
        const value_b = b[sortingKey];

        if (!value_a && !value_b) return 0;
        if (!value_b) return -1;
        if (!value_a) return 1;

        if (desc) return value_b?.localeCompare?.(value_a);
        return value_a?.localeCompare?.(value_b);
      });
  }
};

export const filterEncounters = (encounters, filters) =>
  encounters?.filter((data) =>
    Object.entries(filters).every(([key, value]) => {
      if (!value || value === '*' || value.length === 0) return true;
      if (value.some?.(({ value: pickerValue }) => pickerValue === '*')) return true;

      switch (key) {
        case FILTER_TYPES.fromDate:
          return data.eventAt && value.isBefore?.(data.eventAt);
        case FILTER_TYPES.toDate:
          return data.eventAt && value.endOf('day').isAfter?.(data.eventAt);
        case FILTER_TYPES.facility:
          return value.some?.(({ value: pickerValue }) =>
            data.locations?.some?.((location) => location.id === pickerValue)
          );
        case FILTER_TYPES.staff:
          return value.some?.(({ label: staffMember }) => data.staff?.includes?.(staffMember));
        case FILTER_TYPES.type:
          return value.some(({ value: pickerValue }) => data.type === pickerValue);
        case FILTER_TYPES.appointmentType:
          return value.some(({ value: pickerValue }) => data.subtype === pickerValue);
        case FILTER_TYPES.documentStatus:
          return (
            (value.some(({ value: pickerValue }) => pickerValue === 'locked') &&
              data.chartingNotes?.some(({ isLocked }) => isLocked)) ||
            (value.some(({ value: pickerValue }) => pickerValue === 'unlocked') &&
              data.chartingNotes?.some(({ isLocked }) => !isLocked))
          );
        case FILTER_TYPES.document:
          return value.some(({ value: pickerValue }) => data.chartingNotes?.some(({ name }) => name === pickerValue));
        case FILTER_TYPES.status:
          return value.some(({ label: status }) => data.status?.toLowerCase() === status?.toLowerCase());
        default:
          return value.some(({ value: pickerValue }) => data[key] === pickerValue);
      }
    })
  );

export const getDocumentsNames = (timelineEntries) =>
  uniq(
    timelineEntries
      .flatMap(({ chartingNotes }) => chartingNotes?.map(({ name }) => name))
      .filter((item) => !isEmpty(item))
  ).map((name) => ({ label: name, value: name }));

const getNoteReadModeAnswer = (answer) => {
  if (answer.customModule?.modType === 'read_only')
    return answer.answer || answer.customModule.optionsArray?.join?.('') || '-';

  if (!answer.answer) return '-';

  return answer.answer;
};

export const prepareNoteReadModeProps = (note) => ({
  questions: note.formAnswers
    ?.filter((answer) => isConditionalQuestionVisibleChain(answer, note.formAnswers))
    .map((answer) => ({
      formAnswerId: answer.id,
      question: answer.label,
      answer: getNoteReadModeAnswer(answer),
      type: answer.customModule?.modType,
    })),
  signer: {
    name: note.lockedBy?.name || '-',
    date: note.lockedAt,
  },
  coSigners: note.coSigners?.map((coSigner) => ({
    name: coSigner.signedBy,
    date: coSigner.signedAt,
  })),
  addendums: note.addendums?.map((addendum) => ({
    author: addendum.createdBy,
    date: addendum.createdAt,
    addendum: addendum.content,
  })),
});

export const getDateTime = (dateTime) => {
  const [timeWithOffset, supportedTimeZone] = convertToSupportedTimeZone(dateTime);
  return `${timeWithOffset.format(DEFAULT_DATE_FORMAT)}, ${timeWithOffset.format(DEFAULT_TIME_FORMAT)} ${supportedTimeZone.abbrev}`;
};

export const getStatusColor = (status, type) => {
  if (!status) return 'text-gray-600';
  if (type === OUTBOUND_FORM_TYPE) return '';

  switch (status) {
    case 'Rx Not Sent':
    case 'Rx Cancelled':
    case 'Rx Staging Error':
    case 'Rx Failed to Send':
    case 'No-Show':
    case 'Cancelled':
      return 'text-error-700';
    default:
      return '';
  }
};

export const transformTimelineItems = (memberTimeline, { memberId }) =>
  convertObjKeysToCamelCase(memberTimeline).reduce((accumulator, currentItem) => {
    switch (currentItem.type) {
      case 'charting_note': {
        const currentItemChartingNotesSessionIdentifiers = currentItem.chartingNotes
          .map((currentItemChartingNotes) => currentItemChartingNotes.sessionIdentifier)
          .filter((sessionIdentifier) => sessionIdentifier);

        const foundItem = accumulator.find((item) =>
          item.chartingNotes.some((chartingNote) =>
            currentItemChartingNotesSessionIdentifiers.includes(chartingNote.sessionIdentifier)
          )
        );

        if (foundItem) {
          foundItem.staff = uniq([foundItem.staff, currentItem.staff]);
          foundItem.chartingNotes = [...foundItem.chartingNotes, ...currentItem.chartingNotes];
        } else {
          accumulator.push(currentItem);
        }
        break;
      }
      case 'appointment': {
        accumulator.push({
          ...currentItem,
          chartingNotes: !isEmpty(currentItem.chartingNotes)
            ? currentItem.chartingNotes.filter((chartingNote) => chartingNote.memberId === memberId)
            : [],
        });
        break;
      }
      default:
        accumulator.push(currentItem);
        break;
    }

    return accumulator;
  }, []);
