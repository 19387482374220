/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { PiCaretLeftBold } from 'react-icons/pi';
import get from 'lodash/get';
import MemberInformation from '../../member_summary/MemberInformation';
import { convertObjKeysToCamelCase } from '../../../helpers/utils';
import OneMonthStats from '../../member_summary/OneMonthStats';
import GroupInformation from '../../member_summary/GroupInformation';
import Notes from '../../member_summary/Notes';
import MemberDetailsBox from '../../member_summary/MemberDetailsBox';
import MemberSummary from '../member_summary/Main';
import HistoricalDetailsSection from '../../member_summary/HistoricalDetailsSection';
import useToasts from '../../shared/hooks/use-toasts';
import Toasts from '../../shared/Toasts';
import ChartingNotes from './ChartingNotes';
import MedicalSection from '../../member_summary/MedicalSection';
import { useTimelineURLs } from './useTimelineURLs';
import { PAGES, OUTBOUND_FORM_TYPE, CHARTING_NOTE_TYPE } from './constants';
import { appendReferrerParams } from '../../../helpers/ReferrerParams';
import { SmartPhrasesPropType } from '../../documentation/helpers/types';

function AddDocumentation({ member, authenticityToken, selectedDocumentationIds, states, type, smartPhrases }) {
  const { toasts, removeToast } = useToasts();
  const { encounterHistoryUrl } = useTimelineURLs();

  const [isFullWidth, setIsFullWidth] = useState(false);
  const toggleFullWidth = useCallback(() => setIsFullWidth((prevState) => !prevState), []);

  const parsedMember = convertObjKeysToCamelCase(member, ['variables']) || {};
  const { groupInformation, memberSummaries, ...memberData } = parsedMember;
  const { clinical, medical, demographics, historicalDetails, historicalStats, monthToDate, healthieProfile } =
    memberSummaries || {};
  const shouldDisplayMemberDetails = medical || clinical || demographics;
  const shouldDisplayHistoricalDetails = historicalStats || historicalDetails;

  const healthieProfileNote = get(healthieProfile, 'componentData.note', '');

  return (
    <>
      {encounterHistoryUrl && (
        <a href={appendReferrerParams(encounterHistoryUrl, { page: PAGES.TIMELINE })} target="_top">
          <div className="flex items-center">
            <PiCaretLeftBold />
            <div className="text-teal-700 text-base font-semibold ml-3 uppercase">Back to Timeline</div>
          </div>
        </a>
      )}

      <div className="mt-4">
        <Toasts toasts={toasts} removeToast={removeToast} className="mb-6" />
      </div>
      <div className="bg-white mt-8 p-4">
        <div className="flex flex-col md:flex-row justify-between gap-10">
          <div className="flex-1">
            <MemberInformation member={memberData} statuses={get(healthieProfile, 'componentData.tags', [])} />
          </div>
          {monthToDate && (
            <div className="flex-1">
              <OneMonthStats monthData={monthToDate.componentData} />
            </div>
          )}
        </div>

        <div className="flex flex-col md:flex-row justify-between my-8 gap-10">
          {groupInformation && (
            <div className="flex-1">
              <GroupInformation {...groupInformation} />
            </div>
          )}
          <div className="flex-1 md:w-1/2">{healthieProfileNote && <Notes notes={healthieProfileNote} />}</div>
        </div>

        <div className="h-full flex flex-row justify-between items-start flex-wrap">
          <ChartingNotes
            toggleFullWidth={toggleFullWidth}
            isFullWidth={isFullWidth}
            member={parsedMember}
            authenticityToken={authenticityToken}
            selectedDocumentationIds={selectedDocumentationIds}
            states={states}
            hideActions={type === OUTBOUND_FORM_TYPE}
            smartPhrases={smartPhrases}
          />
          {isFullWidth && (
            <div className="w-6/12">
              {shouldDisplayMemberDetails && (
                <>
                  {medical && <MedicalSection member={memberData} medical={medical} />}
                  {clinical && <MemberDetailsBox {...clinical} />}
                  {demographics && <MemberDetailsBox {...demographics} />}
                </>
              )}
            </div>
          )}
          {(shouldDisplayMemberDetails || shouldDisplayHistoricalDetails) && (
            <div className="flex flex-col w-6/12 p-2">
              {shouldDisplayMemberDetails && !isFullWidth && (
                <div className="flex-1 flex flex-col gap-3">
                  {medical && <MedicalSection member={memberData} medical={medical} />}
                  {clinical && <MemberDetailsBox {...clinical} />}
                  {demographics && <MemberDetailsBox {...demographics} />}
                </div>
              )}
              {shouldDisplayHistoricalDetails && (
                <div className="flex-1">
                  <HistoricalDetailsSection
                    historicalStatsProps={{
                      data: historicalStats?.componentData,
                      lastSyncedDate: historicalStats?.updatedAt,
                    }}
                    attendanceWeeksProps={{
                      data: historicalDetails?.componentData,
                      lastSyncedDate: historicalDetails?.updatedAt,
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

AddDocumentation.propTypes = {
  member: PropTypes.shape({
    ...MemberSummary.propTypes.member,
    episodeOfCares: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        created_at: PropTypes.string.isRequired,
        updated_at: PropTypes.string.isRequired,
        start_date: PropTypes.string.isRequired,
        end_date: PropTypes.string.isRequired,
        discharge_reason: PropTypes.string,
        member_id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  authenticityToken: PropTypes.string.isRequired,
  selectedDocumentationIds: PropTypes.arrayOf(PropTypes.string),
  states: PropTypes.objectOf(PropTypes.string),
  type: PropTypes.oneOf([OUTBOUND_FORM_TYPE, CHARTING_NOTE_TYPE]),
  smartPhrases: SmartPhrasesPropType,
};

AddDocumentation.defaultProps = {
  selectedDocumentationIds: [],
  states: null,
  type: CHARTING_NOTE_TYPE,
  smartPhrases: null,
};

export default AddDocumentation;
