import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const defaultValues = {
  getMemberAttendance: () => {},
  getChangeHandler: () => {},
};

const AttendanceTrackerContext = createContext(defaultValues);

export function AttendanceTrackerController({ getMemberAttendance, getChangeHandler, children }) {
  const memoizedValue = useMemo(
    () => ({ getMemberAttendance, getChangeHandler }),
    [getMemberAttendance, getChangeHandler]
  );

  return <AttendanceTrackerContext.Provider value={memoizedValue}>{children}</AttendanceTrackerContext.Provider>;
}

AttendanceTrackerController.propTypes = {
  getMemberAttendance: PropTypes.func.isRequired,
  getChangeHandler: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export const useAttendanceTrackerContext = (memberId) => {
  const { getMemberAttendance, getChangeHandler } = useContext(AttendanceTrackerContext);

  return {
    attendance: getMemberAttendance(memberId),
    getChangeHandler: (field) => getChangeHandler(memberId, field),
  };
};
