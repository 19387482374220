import { useEffect, useState } from 'react';
import useHttp from '../../shared/hooks/use-http';

const useChartingNote = (chartingNoteIds) => {
  const { isLoading, sendRequest, error } = useHttp();
  const [requestResponses, setRequestResponses] = useState([]);

  useEffect(() => {
    const getChartingNotes = async () => {
      try {
        const response = await sendRequest(`/staff/documentation/charting_notes`, {
          params: {
            charting_note_ids: chartingNoteIds,
          },
        });

        setRequestResponses(response?.data?.formAnswerGroups);
      } catch (e) {
        window.Sentry?.captureException(e);
      }
    };

    if (chartingNoteIds && chartingNoteIds.length > 0) {
      getChartingNotes();
    }
  }, [chartingNoteIds, sendRequest]);

  return {
    chartingNotes: requestResponses,
    isLoading,
    error,
  };
};

export default useChartingNote;
