import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { LuChevronsUpDown as ChevronIcon } from 'react-icons/lu';
import { IoClose as ClearIcon } from 'react-icons/io5';

import Select, { components } from 'react-select';
import { customStyles, theme } from '../helpers/FilterStyles';

function DropdownIndicator(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <ChevronIcon />
    </components.DropdownIndicator>
  );
}

function ClearIndicator(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.ClearIndicator {...props}>
      <ClearIcon />
    </components.ClearIndicator>
  );
}

function Filters(props) {
  const [filters, setFilters] = useState([]);
  const [selectedOptions, setSelectedOption] = useState(props.data[0]);
  const testID = `filters-${props.testID}`;
  const defaultValue = props.value || selectedOptions;

  useMemo(() => {
    if (props.removeAllSelection) {
      setFilters(props.data?.filter((data) => data.value !== '*'));
    } else {
      setFilters(props.data);
    }

    if (props.defaultValue) {
      setSelectedOption(props.defaultValue);
    }

    if (props.placeholder) {
      setSelectedOption(null);
    }

    // FOR CACHING
    if (props.pageToCache) {
      const localStorageFilters = window.localStorage.getItem(`${props.pageToCache}-${props.type}`);
      const jsonFilters = JSON.parse(localStorageFilters);

      setSelectedOption(jsonFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.data,
    props.removeAllSelection,
    props.defaultValue,
    props.placeholder,
    props.pageToCache,
    props.type,
    props.value,
  ]);

  const filter = (selectedFilters, e) => {
    setSelectedOption(selectedFilters);

    props.stateChanger(selectedFilters, e);
    props.onFilter(props.type, selectedFilters);

    // FOR CACHING
    if (props.pageToCache) {
      window.localStorage.setItem(`${props.pageToCache}-${props.type}`, JSON.stringify(selectedFilters));
    }
  };

  const handleSearch = (query) => {
    if (!query || !props.isSearch) {
      return;
    }
    props.onHandleSearch(query);
  };

  return (
    <div
      data-testid={testID}
      className={`react-inline ${props.error && 'danger'} ${props.classes} ${props.disabled && 'pointer-events-none'}`}
      style={props.style}
    >
      {(filters.length > 0 || props.isSearch) && (
        <div>
          <div
            data-testid={`label-${props.testID}`}
            className="filter-label"
            style={props?.isFormLabel ? { color: 'black', fontWeight: 700 } : props.labelStyle}
          >
            {props.label}
          </div>

          {props.disabled && props.disabledMessage && (
            <div className={`filter-disabled-message ${props.disabled && 'overflow-visible'}`}>
              {props.disabledMessage}
            </div>
          )}

          <Select
            // eslint-disable-next-line react/no-unstable-nested-components
            noOptionsMessage={() =>
              props.isSearch ? <div>Type member name or MRN to see options</div> : <div>No options</div>
            }
            placeholder={props.placeholder ? props.placeholder : 'Search'}
            value={isEmpty(defaultValue) ? null : defaultValue}
            isMulti={props.isMulti}
            isSearchable={props.isSearchable}
            isDisabled={props.disabled}
            options={filters}
            className="basic-multi-select"
            classNamePrefix={testID}
            onChange={filter}
            styles={customStyles}
            theme={theme}
            onInputChange={handleSearch}
            components={{ DropdownIndicator, ClearIndicator }}
          />
        </div>
      )}
      {props.error && <p className="text-red-500 text-xs italic">{props.error}</p>}
    </div>
  );
}

Filters.propTypes = {
  testID: PropTypes.string.isRequired,
  classes: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.array,
  defaultValue: PropTypes.object,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  removeAllSelection: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  pageToCache: PropTypes.string,
  isFormLabel: PropTypes.bool,
  labelStyle: PropTypes.object,
  isSearch: PropTypes.bool,

  // functions
  stateChanger: PropTypes.func.isRequired,
  onFilter: PropTypes.func,
  onHandleSearch: PropTypes.func,
};

Filters.defaultProps = {
  /** data {array}
   * @param data {array} array of objects that contain filter data
   *
   * element in array
   * @param dataElement.value The value of the filter object (i.e. ID of staff member)
   * @param dataElement.label The label shown for the filter object
   *
   * example: [
   *   { value: '*', label: 'All Staff' },
   *   { value: 1, label: 'Example Staff'},
   *   { value: 2, label: 'SecondExample StaffTwo' }
   * ]
   */
  data: [],
  classes: '',
  style: {},
  defaultValue: null,
  isMulti: true,
  isSearchable: true,
  removeAllSelection: false,
  placeholder: null,
  error: null,
  value: null,
  disabled: false,
  disabledMessage: 'Disabled',
  pageToCache: null,
  isFormLabel: false,
  labelStyle: null,
  isSearch: false,
  onFilter: () => {},
  onHandleSearch: () => {},
};

export default Filters;
