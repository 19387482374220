import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Checkbox from '../../../shared/CheckBox';
import GeneralCell from '../../../GeneralCell';
import SelectInput from '../../../shared/SelectInput';
import { ATTENDANCE_STATUSES } from '../shared/constants';
import SelectAndTextInput from '../../../shared/SelectAndTextInput';
import { formatLocationLabel } from '../shared/helpers';
import { useAttendanceTrackerContext } from './useAttendanceTrackerContext';
import { shouldRequireExtraColumns } from './helpers';
import { AddressOptionCamelCasePropTypes, AttendanceCamelCasePropTypes } from '../shared/types';

const getRowClasses = (isPaddingBottom = true) =>
  classNames('flex items-center ml-3 mr-3 mb-2 h-28 pt-7', { 'pb-7': isPaddingBottom });

function MemberAttendanceRow({ testID, row, selected, onRowCheckboxChange }) {
  const locationRef = useRef();
  const { id, firstName, lastName, memberLocations } = row || {};
  const {
    attendance: { location, status, isLocationFocusable },
    getChangeHandler,
  } = useAttendanceTrackerContext(id);
  const onCheckboxToggle = () => onRowCheckboxChange(id);
  const parsedLocations = Object.entries(memberLocations ?? {}).map(([key, loc]) => ({
    value: loc.id,
    label: formatLocationLabel(key, loc.name),
    fullAddress: loc.fullAddress,
  }));
  const areExtraColumnsRequired = shouldRequireExtraColumns(status);

  useEffect(() => {
    if (isLocationFocusable) locationRef.current.focus();
  }, [isLocationFocusable]);

  const isLocationAddressVisible = !location?.wasCreated && location?.fullAddress;

  return (
    <>
      <GeneralCell
        testID={`checkbox-${testID}`}
        classes={getRowClasses()}
        data={<Checkbox checked={selected} onChange={onCheckboxToggle} />}
      />
      <GeneralCell classes={getRowClasses()} data={`${firstName} ${lastName}`} />
      <GeneralCell
        classes={getRowClasses()}
        data={
          <SelectInput
            name="status"
            value={status}
            onChange={getChangeHandler('status')}
            options={ATTENDANCE_STATUSES}
            placeholder="Select status"
            showError={false}
            wrapperClassNames="w-full"
            classes="w-full"
            usePortal
          />
        }
      />
      <GeneralCell
        classes={getRowClasses(!isLocationAddressVisible)}
        data={
          <div className="flex flex-col gap-2 w-full">
            <SelectAndTextInput
              ref={locationRef}
              name="location"
              value={areExtraColumnsRequired ? location : null}
              onChange={getChangeHandler('location')}
              options={parsedLocations}
              placeholder="Select location"
              disabled={!areExtraColumnsRequired}
              error={areExtraColumnsRequired && !location}
              usePortal
              isOtherOptionEnabled
            />
            {isLocationAddressVisible && <span className="text-sm text-gray-700">{location.fullAddress}</span>}
          </div>
        }
      />
    </>
  );
}

MemberAttendanceRow.propTypes = {
  testID: PropTypes.string.isRequired,
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    memberLocations: PropTypes.shape({
      location: AddressOptionCamelCasePropTypes,
      default_facility: AddressOptionCamelCasePropTypes,
      empanelment_group: AddressOptionCamelCasePropTypes,
      uds_pickup_facility: AddressOptionCamelCasePropTypes,
    }).isRequired,
    attendance: AttendanceCamelCasePropTypes,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  onRowCheckboxChange: PropTypes.func.isRequired,
};

export default MemberAttendanceRow;
