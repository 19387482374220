import React from 'react';
import PropTypes from 'prop-types';

function TaskTableMemberCell(props) {
  return (
    <td>
      <a
        className="font-bold my-1 underline hover:no-underline no-underline cursor-pointer"
        href={`/staff/members/${props?.id}`}
      >
        {props?.name}
      </a>
      <p className="text-sm font-light text-gray-700 my-1">{props?.mrn}</p>
    </td>
  );
}

TaskTableMemberCell.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  mrn: PropTypes.string.isRequired,
};

export default TaskTableMemberCell;
