export const HEALTH_CHECKIN_STATUS_CLINICALLY_CONCERNING = 'clinically_concerning';
export const HEALTH_CHECKIN_STATUS_RISKY = 'risky';
export const NO_SHOW_PREFIX = 'No Show';
export const RESCHEDULED_PREFIX = 'Rescheduled';
export const CANCELLED_APPOINTMENT_STATUS = 'cancelled';

export const PENDING_ATTENDANCE_STATUS = {
  value: 'pending',
  label: 'Pending',
};

export const ATTENDED_ATTENDANCE_STATUS = {
  value: 'attended',
  label: 'Attended',
};

export const NO_SHOW_ATTENDANCE_STATUS = {
  value: 'no-show',
  label: 'No-show',
};

export const RESCHEDULED_ATTENDANCE_STATUS = {
  value: 'rescheduled',
  label: 'Rescheduled',
};

export const ATTENDED_STATUSES = [PENDING_ATTENDANCE_STATUS.value, ATTENDED_ATTENDANCE_STATUS.value];

export const ATTENDANCE_STATUSES = [
  PENDING_ATTENDANCE_STATUS,
  ATTENDED_ATTENDANCE_STATUS,
  NO_SHOW_ATTENDANCE_STATUS,
  RESCHEDULED_ATTENDANCE_STATUS,
];

export const DEFAULT_ATTENDANCE_STATUS = PENDING_ATTENDANCE_STATUS;
