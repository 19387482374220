/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import HistoricalStats, { historicalStatsPropTypes } from './HistoricalStats';
import AttendanceWeeks, { attendanceWeeksPropTypes } from './AttendanceWeeks';

function HistoricalDetailsSection({ historicalStatsProps, attendanceWeeksProps }) {
  return (
    <div className="flex flex-col gap-4">
      <h3 className="font-body font-bold text-lg">Attendance & Screenings</h3>
      {!historicalStatsProps && !attendanceWeeksProps && <p>No data available</p>}
      {historicalStatsProps && <HistoricalStats {...historicalStatsProps} />}
      {attendanceWeeksProps && <AttendanceWeeks {...attendanceWeeksProps} />}
    </div>
  );
}

HistoricalDetailsSection.propTypes = {
  historicalStatsProps: PropTypes.shape(historicalStatsPropTypes),
  attendanceWeeksProps: PropTypes.shape(attendanceWeeksPropTypes),
};

HistoricalDetailsSection.defaultProps = {
  historicalStatsProps: null,
  attendanceWeeksProps: null,
};

export default HistoricalDetailsSection;
