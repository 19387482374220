import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useHttp from '../../shared/hooks/use-http';
import { convertObjKeysToCamelCase } from '../../../helpers/utils';
import LockAndSignConfirmationModal from '../../LockAndSignConfirmationModal';
import DocumentationActionPerformedSummaryModal from '../../DocumentationActionPerformedSummaryModal';
import { ERROR_SUFFIX } from '../../documentation/helpers/constants';

function LockAndSign({
  authenticityToken,
  chartingNoteIds,
  members,
  templateName,
  onSuccess,
  isModalOpen,
  closeModal,
  isCarePlan,
  carePlanId,
}) {
  const { sendRequest, isLoading } = useHttp();

  const [requestResponse, setRequestResponse] = useState({});
  const [summaryModalPayload, setSummaryModalPayload] = useState({});
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);

  const getStatusMessagePrefix = (isError) =>
    `${isCarePlan ? 'Care Plan' : 'Note'} has ${isError ? 'not ' : ''}been successfully ${!isCarePlan ? 'locked and ' : ''}signed`;

  const lockAndSign = async () => {
    const errorPrefix = getStatusMessagePrefix(true);
    const summaryModalErrorPayload = {
      status: 'error',
      message: `${errorPrefix}${chartingNoteIds?.length > 1 ? ' for all members' : ''}. ${ERROR_SUFFIX}`,
    };
    try {
      if (isCarePlan) {
        const response = await sendRequest(`/staff/members/${members[0].id}/care_plans/${carePlanId}/sign`, {
          method: 'POST',
          headers: {
            'X-CSRF-Token': authenticityToken,
          },
        });

        const { signedAt: date, signedBy: author, userId } = convertObjKeysToCamelCase(response);
        setRequestResponse({ date, author });

        setSummaryModalPayload({
          status: 'success',
          message: `${getStatusMessagePrefix()}.`,
        });

        if (onSuccess) onSuccess({ userId, date, author });
        closeModal();
        setIsSummaryModalOpen(true);
        return;
      }

      const response = await sendRequest('/staff/documentation/charting_notes/sign_and_lock', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': authenticityToken,
        },
        data: {
          charting_note_ids: chartingNoteIds,
        },
      });

      const { templates, requestProcessedAt, author, fullyCompleted, successfullyProcessed, processedNotesMembersIds } =
        convertObjKeysToCamelCase(response);

      setRequestResponse({
        templates,
        author,
        fullyCompleted,
        successfullyProcessed,
        date: requestProcessedAt,
      });

      setSummaryModalPayload({
        status: 'success',
        message: `${getStatusMessagePrefix()}.`,
      });

      if (!fullyCompleted) {
        setSummaryModalPayload(summaryModalErrorPayload);
      }

      if (onSuccess)
        onSuccess({
          chartingNoteIds: successfullyProcessed || [],
          lockedAndSignedBy: author,
          lockedAt: processedNotesMembersIds.reduce(
            (prevMemberHealthieMrns, memberHealthieMrn) => ({
              ...prevMemberHealthieMrns,
              [memberHealthieMrn]: requestProcessedAt,
            }),
            {}
          ),
        });
      closeModal();
      setIsSummaryModalOpen(true);
    } catch (err) {
      setSummaryModalPayload(summaryModalErrorPayload);
      closeModal();
      setIsSummaryModalOpen(true);
    }
  };

  const summaryModalContent = [
    { label: 'Signer', value: requestResponse.author, type: 'text' },
    { label: 'Date/Time', value: requestResponse.date, type: 'date' },
  ];

  const content = isCarePlan
    ? summaryModalContent
    : [{ label: 'Template', value: requestResponse.templates?.join(', '), type: 'text' }, ...summaryModalContent];

  return (
    <>
      <DocumentationActionPerformedSummaryModal
        isOpen={isSummaryModalOpen}
        setIsOpen={setIsSummaryModalOpen}
        header={isCarePlan ? 'Sign Care Plan' : 'Lock & Sign Note'}
        message={summaryModalPayload.message}
        status={summaryModalPayload.status}
        content={content}
      />
      <LockAndSignConfirmationModal
        isOpen={isModalOpen}
        onClose={closeModal}
        templateName={templateName}
        members={members}
        onLockAndSign={lockAndSign}
        isLockAndSignLoading={isLoading}
        isCarePlan={isCarePlan}
      />
    </>
  );
}

LockAndSign.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  chartingNoteIds: PropTypes.arrayOf(PropTypes.string.isRequired),
  members: LockAndSignConfirmationModal.propTypes.members.isRequired,
  templateName: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  isCarePlan: PropTypes.bool,
  carePlanId: PropTypes.number,
};

LockAndSign.defaultProps = {
  chartingNoteIds: null,
  onSuccess: undefined,
  isModalOpen: false,
  isCarePlan: false,
  carePlanId: null,
};

export default LockAndSign;
