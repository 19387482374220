import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import AddTaskModal from './AddTaskModal';
import AddTaskModalConfirmation from './AddTaskModalConfirmation';

import addTaskValidationSchema from '../../../schemas/addTask';

function AddPrescriptionTask({ isModalOpen, onClose, isLoading, prescriptionReasons, authenticityToken, onAddTask }) {
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const form = useForm({
    defaultValues: {
      member: null,
      reason: null,
      due_date: moment(),
      note: '',
    },
    resolver: yupResolver(addTaskValidationSchema),
  });

  useEffect(() => {
    if (!isModalOpen) {
      form.reset();
      setConfirmationVisible(false);
    }
  }, [isModalOpen, form]);

  const proceedToConfirmation = async () => {
    const validationResult = await form.trigger();
    if (validationResult) {
      setConfirmationVisible(true);
    }
  };

  const addTask = async () => {
    const {
      member: { value: member_id },
      note,
      due_date,
      reason,
    } = form.getValues();

    onAddTask({ member_id, note, due_date, prescription_reason_id: reason?.value });
  };

  return (
    <>
      {confirmationVisible && (
        <AddTaskModalConfirmation
          isOpen={isModalOpen}
          values={form.getValues()}
          onBack={() => setConfirmationVisible(false)}
          isLoading={isLoading}
          onSubmitConfirm={addTask}
        />
      )}
      {!confirmationVisible && (
        <AddTaskModal
          form={form}
          isOpen={isModalOpen}
          onClose={onClose}
          onConfirm={proceedToConfirmation}
          prescriptionReasons={prescriptionReasons}
          authenticityToken={authenticityToken}
        />
      )}
    </>
  );
}

const valuePropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

AddPrescriptionTask.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddTask: PropTypes.func.isRequired,
  prescriptionReasons: PropTypes.arrayOf(valuePropType).isRequired,
  authenticityToken: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default AddPrescriptionTask;
