import React from 'react';
import PropTypes from 'prop-types';

import GroupsModal from '../../GroupsModal';
import ListItem from '../../shared/ListItem';
import { mdyyyy } from '../../../helpers/DateFormatter';

function AddTaskModalConfirmation({ isOpen, onBack, onSubmitConfirm, values, isLoading }) {
  const {
    member: { label },
    note,
    due_date,
    reason,
  } = values || {};

  return (
    <GroupsModal
      testID="add-task-modal-confirmation"
      isOpen={isOpen}
      header="Are you sure?"
      subHeader='Clicking "confirm" will add the following prescription task:'
      footerButtons={[
        {
          label: 'Back',
          isSecondary: true,
          onClick: onBack,
        },
        {
          label: 'Confirm',
          isPrimary: true,
          onClick: onSubmitConfirm,
          isLoading,
          type: 'submit',
        },
      ]}
      size="small"
      isLoading={isLoading}
    >
      <div>
        <ListItem label="Member" text={label} />
        <ListItem label="Due date" text={mdyyyy(due_date)} />
        <ListItem label="Reason" text={reason?.label || '-'} />
        <ListItem label="Note" text={note.length === 0 ? '-' : note} />
      </div>
    </GroupsModal>
  );
}

const valuePropType = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

AddTaskModalConfirmation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmitConfirm: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  values: PropTypes.shape({
    member: PropTypes.shape(valuePropType).isRequired,
    due_date: PropTypes.string.isRequired,
    note: PropTypes.string,
    reason: PropTypes.shape(valuePropType),
  }).isRequired,
};

export default AddTaskModalConfirmation;
