import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useHttp from '../../shared/hooks/use-http';
import { convertObjKeysToCamelCase } from '../../../helpers/utils';
import AddAddendumModal from '../../AddAddendumModal';
import { FormAnswerGroupPropType } from '../../documentation/helpers/types';
import DocumentationActionPerformedSummaryModal from '../../DocumentationActionPerformedSummaryModal';
import { ERROR_SUFFIX } from '../../documentation/helpers/constants';

function AddAddendum({ authenticityToken, onSuccess, isModalOpen, closeModal, chartingNotes, members, isGroupNote }) {
  const [requestResponse, setRequestResponse] = useState({});
  const [summaryModalPayload, setSummaryModalPayload] = useState({});

  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);

  const { sendRequest, isLoading } = useHttp();

  const prepareRequestPayload = (formValues) => {
    if (isGroupNote) {
      const allMembersSelected = formValues.members?.some((member) => member.value === 'all');
      const selectedMemberIds = allMembersSelected
        ? members?.map((member) => member.value)
        : formValues.members?.map((member) => member.value);
      const selectedTemplateIds = formValues.templates?.map((template) => template.value);
      const filteredChartingNotes = chartingNotes.filter((chartingNote) =>
        selectedTemplateIds.includes(chartingNote.id)
      );

      const chartingNoteIds = [];

      filteredChartingNotes.forEach((chartingNote) => {
        selectedMemberIds.forEach((selectedMemberId) => {
          const chartingNoteIdAssignedToMember = chartingNote?.ids[selectedMemberId];
          if (chartingNoteIdAssignedToMember) {
            chartingNoteIds.push(chartingNoteIdAssignedToMember);
          }
        });
      });

      return {
        chartingNoteIds,
      };
    }

    return {
      chartingNoteIds: formValues.templates?.map((template) => template.value),
    };
  };

  const addAddendum = async (values) => {
    const { chartingNoteIds } = prepareRequestPayload(values);
    const summaryModalErrorPayload = {
      status: 'error',
      message: `${
        chartingNoteIds.length > 1 ? 'Addendum has not been added for all members.' : 'Addendum has not been added.'
      } ${ERROR_SUFFIX}`,
    };
    try {
      const response = await sendRequest('/staff/documentation/charting_notes/addendum', {
        method: 'POST',
        data: {
          charting_note_ids: chartingNoteIds,
          note: values.addendum,
        },
        headers: {
          'X-CSRF-Token': authenticityToken,
        },
      });

      const { createdAt, author, addendum, templates, fullyCompleted, successfullyProcessed } =
        convertObjKeysToCamelCase(response);

      setRequestResponse({
        addendum,
        author,
        createdAt,
        fullyCompleted,
        successfullyProcessed,
        templates,
        members: values.members?.map((member) => member.label),
      });

      setSummaryModalPayload({
        status: 'success',
        message: 'Addendum has been successfully added.',
      });

      if (!fullyCompleted) {
        setSummaryModalPayload(summaryModalErrorPayload);
      }

      if (onSuccess)
        onSuccess({
          addendum,
          createdAt,
          createdBy: author,
          chartingNoteIds: successfullyProcessed || [],
        });
      closeModal();
      setIsSummaryModalOpen(true);
    } catch (err) {
      setSummaryModalPayload(summaryModalErrorPayload);
      closeModal();
      setIsSummaryModalOpen(true);
    }
  };

  const summaryModalContent = [
    { label: 'Member(s)', value: requestResponse.members?.join(', '), type: 'text' },
    { label: 'Author', value: requestResponse.author, type: 'text' },
    { label: 'Date/Time', value: requestResponse.createdAt, type: 'date' },
    { label: 'Template', value: requestResponse.templates?.join(', '), type: 'text' },
    { label: 'Addendum', value: requestResponse.addendum, type: 'text' },
  ];

  return (
    <>
      <DocumentationActionPerformedSummaryModal
        isOpen={isSummaryModalOpen}
        setIsOpen={setIsSummaryModalOpen}
        header="Add Addendum"
        message={summaryModalPayload.message}
        status={summaryModalPayload.status}
        content={summaryModalContent}
      />
      <AddAddendumModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onAddAddendum={addAddendum}
        isAddAddendumLoading={isLoading}
        chartingNotes={chartingNotes}
        members={members}
      />
    </>
  );
}

AddAddendum.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  isModalOpen: PropTypes.bool,
  isGroupNote: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  chartingNotes: PropTypes.arrayOf(FormAnswerGroupPropType),
  members: AddAddendumModal.propTypes.members.isRequired,
};

AddAddendum.defaultProps = {
  onSuccess: undefined,
  isModalOpen: false,
  isGroupNote: false,
  chartingNotes: [],
};

export default AddAddendum;
