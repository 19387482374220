/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import InfoRow from './InfoRow';
import { convertToSupportedTimeZone } from '../../../helpers/TimezoneHelper';
import { DEFAULT_DATETIME_FORMAT } from '../../../helpers/DateFormatter';

function NoteReadModeAdditionalInfo({ signer, coSigners, addendums }) {
  const formatDateTime = (date) => {
    const [dateTimeWithOffset, supportedTimeZone] = convertToSupportedTimeZone(date);
    return `${dateTimeWithOffset.format(DEFAULT_DATETIME_FORMAT)} ${supportedTimeZone.abbrev}`;
  };

  if (!signer?.date && !coSigners.length && !addendums.length) return <></>;

  return (
    <div className="border border-grey-300 bg-blue-100">
      {(signer?.date || coSigners.length) && (
        <div data-testid="note-read-mode-additional-info-signers-section" className="p-5">
          <div className="font-bold text-lg">Signers</div>
          {signer?.date && (
            <div className="mt-4">
              <InfoRow data-testid="note-read-mode-additional-info-signer" label="Signer:" value={signer.name} />
              <InfoRow
                data-testid="note-read-mode-additional-info-signer-date"
                label="Date/Time:"
                value={formatDateTime(signer.date)}
              />
            </div>
          )}

          {coSigners.map((coSigner, i) => (
            <div className="mt-4" key={`${coSigner.name}-${i}`}>
              <InfoRow
                data-testid={`note-read-mode-additional-info-co-signer-${i}`}
                label="Co-Signer:"
                value={coSigner.name}
              />
              <InfoRow
                data-testid={`note-read-mode-additional-info-co-signer-date-${i}`}
                label="Date/Time:"
                value={formatDateTime(coSigner.date)}
              />
            </div>
          ))}
        </div>
      )}
      {(signer?.date || coSigners.length) && addendums.length > 0 && <hr />}
      {addendums.length > 0 && (
        <div data-testid="note-read-mode-additional-info-addendum-section" className="p-5">
          <div className="font-semibold text-lg">Addendums</div>
          {addendums.map((addendum, i) => (
            <div className="mt-4" key={`${addendum.addendum}-${i}`}>
              <InfoRow
                data-testid={`note-read-mode-additional-info-addendum-author-${i}`}
                label="Author:"
                value={addendum.author}
              />
              <InfoRow
                data-testid={`note-read-mode-additional-info-addendum-date-${i}`}
                label="Date/Time:"
                value={formatDateTime(addendum.date)}
              />
              <InfoRow
                data-testid={`note-read-mode-additional-info-addendum-desc-${i}`}
                label="Addendum:"
                value={addendum.addendum}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const signerPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
});

NoteReadModeAdditionalInfo.propTypes = {
  signer: PropTypes.shape(signerPropTypes),
  coSigners: PropTypes.arrayOf(PropTypes.shape(signerPropTypes)),
  addendums: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      addendum: PropTypes.string.isRequired,
    })
  ),
};

NoteReadModeAdditionalInfo.defaultProps = {
  signer: undefined,
  coSigners: [],
  addendums: [],
};

export default NoteReadModeAdditionalInfo;
