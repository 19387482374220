import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// IMPORTS
import axios from 'axios';
import { debounce } from 'lodash';

// COMPONENTS
import Filters from '../../Filters';

// HELPERS
import { labelStyle } from './styles';

function MemberSearchFilter(props) {
  const [members, setMembers] = useState([]);

  const [selectedMembers, setSelectedMembers] = useState(props.members);
  useEffect(() => {
    setSelectedMembers(props.members);
  }, [props.members]);

  const stateChange = (selected, e) => {
    if (e.action === 'remove-value' || e.action === 'pop-value' || e.action === 'clear') {
      setSelectedMembers(selected);
      props.onSelect(selected);
    } else {
      const previouslySelected = selectedMembers;
      const allSelected = previouslySelected ? [previouslySelected, selected]?.flat() : selected;

      const ids = allSelected.map(({ value }) => value);
      const filtered = allSelected.filter(({ value }, index) => !ids.includes(value, index + 1));

      setSelectedMembers(filtered);
      props.onSelect(filtered);
    }
  };

  const searchMembers = (query) => {
    const params = { query };
    axios
      .get('/staff/members/fetch_members_search_filters', { params })
      .then((response) => {
        const mem = selectedMembers ? [...selectedMembers, response?.data]?.flat() : response?.data;

        const ids = mem.map(({ value }) => value);
        const filtered = mem.filter(({ value }, index) => !ids.includes(value, index + 1));

        setMembers(filtered);
      })
      .catch((error) => {
        window.Sentry.captureException(error);
      });
  };

  return (
    <Filters
      isSearch
      labelStyle={labelStyle}
      label={props.label}
      placeholder="Search by Name or MRN"
      data={members}
      value={selectedMembers}
      error={props.formErrors.members}
      stateChanger={(selected, e) => stateChange(selected, e)}
      onHandleSearch={debounce((query) => searchMembers(query), 100)}
    />
  );
}

MemberSearchFilter.propTypes = {
  members: PropTypes.array.isRequired,
  formErrors: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default MemberSearchFilter;
