import * as Yup from 'yup';
import moment from 'moment';
import { isEmptyObject } from './helpers';
import { CLINICAL_MODEL_LABELS } from '../helpers/constants';

export const clinicalModelRequirement = {
  [CLINICAL_MODEL_LABELS.COUNSELOR_MAKEUP_GROUP]: {
    counselor: true,
    provider: false,
  },
  [CLINICAL_MODEL_LABELS.PROVIDER_MAKEUP_GROUP]: {
    counselor: true,
    provider: true,
  },
  [CLINICAL_MODEL_LABELS.PROVIDER_ONLY_MAKEUP_GROUP]: {
    counselor: false,
    provider: true,
  },
  [CLINICAL_MODEL_LABELS.RX_ASSISTANCE_GROUP]: {
    counselor: false,
    provider: true,
  },
  [CLINICAL_MODEL_LABELS.STANDARD_CARE_MODEL]: {
    counselor: true,
    provider: true,
  },
  [CLINICAL_MODEL_LABELS.STEPDOWN]: {
    counselor: true,
    provider: true,
  },
  [CLINICAL_MODEL_LABELS.WEEKLY_PROVIDER_GROUP]: {
    counselor: true,
    provider: true,
  },
};

const requiredText = 'This field is required';

export default Yup.object().shape({
  facility: Yup.object()
    .required(requiredText)
    .test('is-empty', requiredText, (value) => !isEmptyObject(value)),
  counselor: Yup.object().when('clinicalModel', {
    is: (clinicalModel) => clinicalModelRequirement[clinicalModel?.label]?.counselor,
    then: () =>
      Yup.object()
        .required(requiredText)
        .test('is-empty', requiredText, (value) => !isEmptyObject(value)),
  }),
  provider: Yup.object().when('clinicalModel', {
    is: (clinicalModel) => clinicalModelRequirement[clinicalModel?.label]?.provider,
    then: () =>
      Yup.object()
        .required(requiredText)
        .test('is-empty', requiredText, (value) => !isEmptyObject(value)),
  }),
  officeManager: Yup.object()
    .required(requiredText)
    .test('is-empty', requiredText, (value) => !isEmptyObject(value)),
  dayOfWeek: Yup.string().required(requiredText),
  startTime: Yup.object().required(requiredText),
  clinicalModel: Yup.object()
    .required(requiredText)
    .test('is-empty', requiredText, (value) => !isEmptyObject(value)),
  groupOccurrence: Yup.date()
    .required(requiredText)
    .when('dayOfWeek', ([dayOfWeek], schema) => {
      if (!dayOfWeek) {
        return schema;
      }
      return schema.test('is-same-day', 'Must be the same day as specified in day of week', (value) => {
        const dayOfWeekValue = moment(value).day(dayOfWeek).startOf('day');
        const valueDate = moment(value).startOf('day');
        return dayOfWeekValue.isSame(valueDate);
      });
    }),
  timezone: Yup.object()
    .required(requiredText)
    .test('is-empty', requiredText, (value) => !isEmptyObject(value)),
  modality: Yup.object()
    .required(requiredText)
    .test('is-empty', requiredText, (value) => !isEmptyObject(value)),
  secondaryGroupTypes: Yup.array(),
});
