/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

// COMPONENTS FOR LIBRARY
import Tabs from '../../Tabs';
import Filters from '../../Filters';
import Button from '../../shared/Button';
import Header from '../../shared/Header';
import FormRow from '../../shared/FormRow';
import TextArea from '../../shared/TextArea';
import ButtonRow from '../../shared/ButtonRow';
import RadioSelect from '../../shared/RadioSelect';
import SearchFilter from '../../shared/SearchFilter';
import ExpandButton from '../../shared/ExpandButton';
import CustomPagination from '../../shared_dashboard/CustomPagination';
import GenericModal from '../../shared/GenericModal';
import Tooltip from '../../shared/Tooltip';
import FormCell from '../../shared/FormCell';
import FormLineBreak from '../../shared/FormLineBreak';
import MuiDatePicker from '../../shared/MuiDatePicker';
import MuiTimePicker from '../../shared/TimePicker';

// PANEL SUMMARY
import MonthBar from '../../shared/MonthBar';
import StatusTag from '../../shared/StatusTag';
import TextRowContainer from '../../shared/TextRowContainer';
import MemberSummary from '../../panel_summary/MemberSummary';
import StatusTagsContainer from '../../shared/StatusTagsContainer';
import NumeratorDenominator from '../../shared/NumeratorDenominator';
import NumeratorDenominatorContainer from '../../shared/NumeratorDenominatorContainer';

// TABLE COMPONENTS
import PageContainer from '../../shared/PageContainer';
import PageTableContainer from '../../shared/PageTableContainer';
import TableHeader from '../../shared_dashboard/TableHeader';
import TableFooter from '../../shared_dashboard/TableFooter';

// TABLE CELLS
import IconCell from '../../shared_dashboard/IconCell';
import TimeCell from '../../shared_dashboard/TimeCell';
import MemberCell from '../../shared_dashboard/MemberCell';
import DefaultCell from '../../shared_dashboard/DefaultCell';
import IconTextCell from '../../shared_dashboard/IconTextCell';
import EmpanelmentCell from '../../shared_dashboard/EmpanelmentCell';

// COMPONENTS FOR FILE
import ExampleBlock from './ExampleBlock';
import PageHeader from '../../shared/PageHeader';
import ComponentExampleBlock from './ComponentExampleBlock';

// HELPERS
import code_examples from './code_examples';

// HOOKS
import useSharedFilters from '../../shared/hooks/use-shared-filters';
import useTableHeaders from '../../daily_action_list/hooks/use-table-headers';

function ComponentLibrary() {
  const flexBoxStyle = 'flex overflow-scroll';

  // PANEL SUMMARY
  const statuses = [
    { label: 'On Track', status: 'SUCCESS' },
    { label: 'Care Plan Expired', status: 'ERROR' },
    { label: 'Rx Expiring', status: 'WARNING' },
    { label: 'Recent Hospitalization', status: 'STANDARD' },
    { label: 'Ongoing Substance Use', status: null },
  ];

  // BUTTONS
  const [showMore, setShowMore] = useState(false);

  // FILTERS
  const { dayOptions } = useSharedFilters();

  // DATE PICKER
  const [date, _setDate] = useState(new Date());

  // FILTERS
  const [member] = useState(null);

  // RADIO SELECT
  const [radioSelect, setRadioSelect] = useState('Active');

  // PAGINATION
  const [page, setPage] = useState(1);

  // TABLE
  const { tableHeaders, updateHeaders } = useTableHeaders();
  useEffect(() => {
    updateHeaders(false, 'member');
  }, []);

  // TABS
  const [tabs] = useState([
    { id: 1, name: 'First Tab', key: 'first_tab' },
    {
      id: 2,
      name: 'SecondTab',
      key: 'second_tab',
      count: 1,
    },
  ]);

  // GENERIC MODAL
  const [formModalVisible, setFormModalVisible] = useState(false);

  const [activeTab, setTab] = useState({ id: 1, name: 'First Tab', key: 'first_tab' });
  const tabChange = async (tab) => {
    setTab(tab);
  };

  return (
    <div>
      <PageHeader text="Component Library" />

      <div className="mb-2">
        This library contains examples of our current re-usable components. Anytime we create a new re-usable component,
        we should add them to this document. Additions should include (1) live examples and (2) code examples for how to
        call the component.
      </div>

      <div className="mb-2">
        To document:
        <li>Go to app/javascript/components/admin/component_library/ComponentLibraryDashboard.js</li>
        <li>
          Add an 'ExampleBlock' with the header either being the component name or a relevant component section title
        </li>
        <li>Add a 'ComponentExampleBlock'</li>
        <li>Add a live example call as a child in the 'ComponentExampleBlock'</li>
        <li>
          Add a component code example to the code_examples.js file, and then call the example as part of the codeText
          attribute in the 'ComponentExampleBlock'
        </li>
      </div>

      <div className="mb-10">
        For new development, check the MUI library <a href="https://mui.com/">(https://mui.com/)</a> (or another react
        package library), to see if a package for the component currently exist.
      </div>

      <ExampleBlock headerText="Panel Summary">
        <ComponentExampleBlock codeText={code_examples.panel_summary} styledBackground={false}>
          <MemberSummary
            data={{
              memberInfo: {
                id: 1,
                name: 'Steve Grumplestein',
              },
              statuses: [
                { label: 'On Track', status: 'SUCCESS' },
                { label: 'Care Plan Expired', status: 'ERROR' },
                { label: 'Rx Expiring', status: 'WARNING' },
                { label: 'Recent Hospitalization', status: 'STANDARD' },
              ],
              monthStats: {
                counselor: { numerator: 1, denominator: 4, statusTag: { label: 'Falling Behind', status: 'WARNING' } },
                uds: { numerator: 2, denominator: 4, statusTag: { label: 'At Risk', status: 'ERROR' } },
                provider: {
                  numerator: 1,
                  denominator: 1,
                  noStatus: true,
                  statusTag: { label: 'On Track', status: 'SUCCESS', secondaryStatus: 'STANDARD' },
                },
              },
              details: {
                threeMonthStats: {
                  attendance: [
                    {
                      label: 'Jan',
                      isPresent: [true, true, true, true],
                      count: '4/4',
                    },
                    {
                      label: 'Dec',
                      isPresent: [false, false, false, true],
                      count: '1/4',
                    },
                    {
                      label: 'Nov',
                      isPresent: [true, false, false, true],
                      count: '2/4',
                    },
                  ],
                  uds: [
                    {
                      label: 'Jan',
                      isPresent: [true, false, false, true],
                      count: '2/4',
                    },
                    {
                      label: 'Dec',
                      isPresent: [false, false, false, true],
                      count: '1/4',
                    },
                    {
                      label: 'Nov',
                      isPresent: [true, false, false, true],
                      count: '2/4',
                    },
                  ],
                },
                medical: {
                  rx: { text: 'Active, expires in 20d', subtext: '3/3/23' },
                  dosage: '16.0mg',
                  rxType: 'Tablet',
                  rxRec: '7 day duration, refills until next medical visit',
                  rxRecDetails: 'Multiple requirements incomplete',
                  medList: ['Klonopin', 'Adderall'],
                  pharmacy: 'CVS \n 1950 State St, New Albany, IN 47150 \n (812) 948-8305',
                  lastProviderVisit: { text: '15d ago', subtext: '3/3/23' },
                  lastPillCount: { text: 'The expected quantity, 4d ago', subtext: '3/3/23' },
                  lastFentanyl: { text: 'Negative, 20d ago', subtext: '3/3/23' },
                  lastUds: { text: 'Bup present, 7d ago ', subtext: '3/3/23' },
                },
                clinical: {
                  specialPopProblem: { text: 'Pregnant', subtext: 'from Health Check-in on 01/17/23' },
                  phaseOfCare: 'Maintenance',
                  engagementRec: { text: 'Provide Positive Reinforcement', status: 'SUCCESS' },
                  rss: 'No',
                  carePlan: { text: 'Compliant, expired in 20d', link: 'www.google.com', subtext: '02/20/23' },
                  openTasks: { text: 3, link: 'www.google.com' },
                  healthCheckin: {
                    text: '02/22/23',
                    statusText: 'Clinically Concerning',
                    status: 'ERROR',
                    subtext: 'Usage, Protective Factors',
                  },
                },
                demographics: {
                  mrn: '34134',
                  dob: '9/3/1975',
                  tx: 43,
                  preferredName: 'Elaine',
                  pronouns: 'She/Her',
                },
                attendance: [
                  {
                    label: '5/23/23',
                    weeks: [
                      {
                        dateTime: { date: '01/01/23', time: '5:00 PM ET' },
                        appointmentStatus: { status: 'Attended', provider: 'Makeup Counselor' },
                        screenings: { uds: 'Not Submitted', fent: 'Not Submitted' },
                      },

                      {
                        dateTime: { date: '01/02/23', time: '4:00 PM ET' },
                        appointmentStatus: { status: 'Ad Hoc UDS' },
                        screenings: { uds: 'Not Submitted', fent: 'Not Submitted' },
                      },
                    ],
                  },
                  {
                    label: '4/23/23',
                    weeks: [
                      {
                        dateTime: { date: '01/01/23', time: '5:00 PM ET' },
                        appointmentStatus: { status: 'Absent', provider: 'Empaneled Counselor' },
                        screenings: { uds: 'Bup Present', fent: 'Not Requested' },
                      },
                    ],
                  },
                  {
                    label: '4/23/23',
                    weeks: [
                      {
                        dateTime: { date: '01/01/23', time: '5:00 PM ET' },
                        appointmentStatus: { status: 'Absent', provider: 'Empaneled Counselor' },
                        screenings: { uds: 'Bup Present', fent: 'Not Requested' },
                      },
                    ],
                  },
                  {
                    label: '4/23/23',
                    weeks: [
                      {
                        dateTime: { date: '01/01/23', time: '5:00 PM ET' },
                        appointmentStatus: { status: 'Absent', provider: 'Empaneled Counselor' },
                        screenings: { uds: 'Bup Present', fent: 'Not Requested' },
                      },
                    ],
                  },
                  {
                    label: '4/23/23',
                    weeks: [
                      {
                        dateTime: { date: '01/01/23', time: '5:00 PM ET' },
                        appointmentStatus: { status: 'Absent', provider: 'Empaneled Counselor' },
                        screenings: { uds: 'Bup Present', fent: 'Not Requested' },
                      },
                    ],
                  },
                  {
                    label: '4/23/23',
                    weeks: [
                      {
                        dateTime: { date: '01/01/23', time: '5:00 PM ET' },
                        appointmentStatus: { status: 'Absent', provider: 'Empaneled Counselor' },
                        screenings: { uds: 'Bup Present', fent: 'Not Requested' },
                      },
                    ],
                  },
                  {
                    label: '4/23/23',
                    weeks: [
                      {
                        dateTime: { date: '01/01/23', time: '5:00 PM ET' },
                        appointmentStatus: { status: 'Absent', provider: 'Empaneled Counselor' },
                        screenings: { uds: 'Bup Present', fent: 'Not Requested' },
                      },
                    ],
                  },
                ],
              },
            }}
          />
        </ComponentExampleBlock>

        <ComponentExampleBlock label="Status Tags" codeText={code_examples.statuses}>
          <div className="mb-2 w-16">
            <StatusTag label="Label" status="SUCCESS" />
          </div>

          <div className="mb-2">
            <StatusTagsContainer statuses={statuses} />
          </div>

          <StatusTag isMedium label="Error Tag" isErrorFilled />
        </ComponentExampleBlock>

        <ComponentExampleBlock label="Text Row Container" codeText={code_examples.text_row_container}>
          <TextRowContainer label="Standard Text Row Container" text="Text" />

          <TextRowContainer label="Text Row Container with Subtext" text="Text" subtext="Sub-text" />

          <TextRowContainer
            label="Text Row Container with Newline Subtext"
            secondaryLabel="Secondary Label"
            text="Text"
            subtext="Subtext"
            isNewlineSubtext
          />

          <TextRowContainer
            label="Text Row Container with Concat List"
            text={['first item', 'second item']?.join(', ')}
          />

          <TextRowContainer label="Text Row Container with Link" text="Text" link="www.google.com" />

          <TextRowContainer label="Status Text Row Container" text="Text" status="SUCCESS" />

          <TextRowContainer label="Warning Status Text Row Container" text="Text" status="WARNING" />

          <TextRowContainer label="Error Status Text Row Container" text="Text" status="ERROR" />

          <TextRowContainer
            label="Text Row Container with No Bottom Border"
            text="Text"
            subtext="Subtext"
            hasBorder={false}
          />
        </ComponentExampleBlock>

        <ComponentExampleBlock label="Month Bar" codeText={code_examples.month_bar}>
          <div className="max-w-xs">
            <MonthBar data={{ label: 'Label', isPresent: [true, false, false, true] }} />
          </div>
        </ComponentExampleBlock>

        <ComponentExampleBlock label="Numerator Denominator" codeText={code_examples.numerator_denominator}>
          <div className="flex">
            <NumeratorDenominator numerator={1} denominator={2} status="WARNING" classes="flex-1" />

            <NumeratorDenominator numerator={1} denominator={2} status="SUCCESS" classes="flex-1" />

            <NumeratorDenominator numerator={2} denominator={2} status="ERROR" classes="flex-1" />

            <NumeratorDenominator numerator={0} denominator={2} classes="flex-1" />
          </div>
        </ComponentExampleBlock>

        <ComponentExampleBlock
          label="Numerator Denominator Container"
          codeText={code_examples.numerator_denominator_container}
        >
          <div className="flex">
            <NumeratorDenominatorContainer
              label="Label"
              numerator={1}
              denominator={2}
              statusTag={{ label: 'Label', status: 'WARNING' }}
              classes="flex-1"
            />

            <NumeratorDenominatorContainer
              label="Label"
              numerator={2}
              denominator={2}
              statusTag={{ label: 'Label', status: 'SUCCESS' }}
              classes="flex-1"
            />

            <NumeratorDenominatorContainer
              label="Label"
              numerator={0}
              denominator={2}
              statusTag={{ label: 'Label', status: 'ERROR' }}
              classes="flex-1"
            />

            <NumeratorDenominatorContainer
              label="Label"
              numerator={2}
              denominator={2}
              statusTag={{ label: 'Label' }}
              classes="flex-1"
            />

            <NumeratorDenominatorContainer
              label="Label"
              numerator={2}
              denominator={2}
              statusTag={{ label: 'Label', status: 'SUCCESS' }}
              noStatus
              classes="flex-1"
            />
          </div>
        </ComponentExampleBlock>
      </ExampleBlock>

      <ExampleBlock headerText="Button">
        <div className={`${flexBoxStyle}`}>
          <ComponentExampleBlock center codeText={code_examples.primaryButton}>
            <Button isPrimary onClick={() => {}}>
              Primary Button
            </Button>
          </ComponentExampleBlock>

          <ComponentExampleBlock center codeText={code_examples.secondaryButton}>
            <Button isSecondary onClick={() => {}}>
              Secondary Button
            </Button>
          </ComponentExampleBlock>
        </div>

        <div className={`${flexBoxStyle}`}>
          <ComponentExampleBlock center codeText={code_examples.iconButton}>
            <Button show withArrowIcon isArrowUp={false} onClick={() => {}}>
              Button with Icon
            </Button>
          </ComponentExampleBlock>

          <ComponentExampleBlock codeText={code_examples.buttonRowComponent}>
            <ButtonRow onPrimaryClick={() => {}} onSecondaryClick={() => {}} />
          </ComponentExampleBlock>
        </div>

        <div className={`${flexBoxStyle}`}>
          <ComponentExampleBlock center codeText={code_examples.tertiaryButton}>
            <Button isTertiary onClick={() => {}}>
              Tertiary Button
            </Button>
          </ComponentExampleBlock>

          <ComponentExampleBlock center codeText={code_examples.expand_button}>
            <ExpandButton onShowMore={setShowMore} />
            {showMore && <div>Show more text</div>}
          </ComponentExampleBlock>
        </div>

        <ComponentExampleBlock center codeText={code_examples.customStyleButton}>
          <Button
            show
            classes="mb-2"
            style={{
              padding: '50px',
              borderColor: 'black',
              borderRadius: 0,
              color: 'black',
            }}
            onClick={() => {}}
          >
            Button with Custom Styles
          </Button>
        </ComponentExampleBlock>
      </ExampleBlock>

      <ExampleBlock headerText="Filters">
        <div className={`${flexBoxStyle}`}>
          <ComponentExampleBlock codeText={code_examples.filters}>
            <Filters
              pageToCache="component-library"
              label="Empaneled Staff"
              data={[
                { value: '1', label: 'Drew Andrew' },
                { value: '2', label: 'Danny Adam' },
              ]}
              placeholder="Search Staff"
              stateChanger={() => {}}
            />
          </ComponentExampleBlock>

          <ComponentExampleBlock codeText={code_examples.search_filters}>
            <SearchFilter label="Member" placeholder="Search by name" value={member} onChange={() => {}} />
          </ComponentExampleBlock>
        </div>

        <div className={`${flexBoxStyle}`}>
          <ComponentExampleBlock codeText={code_examples.day_filters}>
            <Filters label="Day" data={dayOptions} stateChanger={() => {}} />
          </ComponentExampleBlock>
        </div>
      </ExampleBlock>

      <ExampleBlock headerText="Day and Time Pickers">
        <div className={`${flexBoxStyle}`}>
          <ComponentExampleBlock codeText={code_examples.mui_day_picker}>
            <MuiDatePicker label="Day Picker" onChange={() => {}} />
          </ComponentExampleBlock>

          <ComponentExampleBlock codeText={code_examples.time_picker}>
            <MuiTimePicker label="Time Picker" onChange={() => {}} />
          </ComponentExampleBlock>
        </div>
      </ExampleBlock>

      <ExampleBlock headerText="Headers">
        <div className={`${flexBoxStyle}`}>
          <ComponentExampleBlock codeText={code_examples.page_header}>
            <PageHeader text="Page Header" subtext="Header subtext">
              <Button isPrimary isFloatRight onClick={() => {}}>
                Apply Filters
              </Button>
            </PageHeader>
          </ComponentExampleBlock>

          <ComponentExampleBlock codeText={code_examples.header}>
            <Header text="Section Header" />
          </ComponentExampleBlock>
        </div>

        <div>
          <ComponentExampleBlock codeText={code_examples.breadcrumb_header}>
            <PageHeader text="Header" breadcrumbText="Breadcrumb Header" breadcrumbLink="/admin/component_library" />
          </ComponentExampleBlock>
        </div>
      </ExampleBlock>

      <ExampleBlock headerText="Tabs">
        <ComponentExampleBlock codeText={code_examples.tabs}>
          <Tabs activeTab={activeTab} tabs={tabs} onChange={tabChange} />

          {activeTab?.key === 'first_tab' ? <div>First Tab Div</div> : <div>Second Tab Div</div>}
        </ComponentExampleBlock>
      </ExampleBlock>

      <ExampleBlock headerText="Tooltip">
        <ComponentExampleBlock codeText={code_examples.tooltip}>
          <Tooltip style={{ marginRight: '10px' }} text="Some useful info" position="left" />
        </ComponentExampleBlock>
      </ExampleBlock>

      <ExampleBlock headerText="Pickers">
        <div className={`${flexBoxStyle}`}>
          <ComponentExampleBlock codeText={code_examples.radio_select}>
            <RadioSelect
              label="Status"
              options={['Active', 'Pending']}
              onChange={(event) => {
                setRadioSelect(event.target.value);
              }}
              value={radioSelect}
            />
          </ComponentExampleBlock>
        </div>
      </ExampleBlock>

      <ExampleBlock headerText="Table Components">
        <div className={`${flexBoxStyle}`}>
          <ComponentExampleBlock codeText={code_examples.default_cell}>
            <PageContainer>
              <PageTableContainer>
                <DefaultCell
                  preloadedData="Default Cell"
                  lineTwo="Line Two"
                  childText="Child Text"
                  customClassName="text-red-500"
                />
              </PageTableContainer>
            </PageContainer>
          </ComponentExampleBlock>

          <ComponentExampleBlock codeText={code_examples.member_cell}>
            <PageContainer>
              <PageTableContainer>
                <MemberCell id={1} preloadedData={{ full_name: 'First Last', external_id: 13435 }} />
              </PageTableContainer>
            </PageContainer>
          </ComponentExampleBlock>
        </div>

        <div className={`${flexBoxStyle}`}>
          <ComponentExampleBlock codeText={code_examples.empanelment_cell}>
            <PageContainer>
              <PageTableContainer>
                <EmpanelmentCell show hasEmpanelment dateTime={date} locationName="Location Name" />
              </PageTableContainer>
            </PageContainer>
          </ComponentExampleBlock>

          <ComponentExampleBlock codeText={code_examples.time_cell}>
            <PageContainer>
              <PageTableContainer>
                <TimeCell id={1} data={date} />
              </PageTableContainer>
            </PageContainer>
          </ComponentExampleBlock>
        </div>

        <div className={`${flexBoxStyle}`}>
          <ComponentExampleBlock codeText={code_examples.icon_text_cell}>
            <PageContainer>
              <PageTableContainer>
                <IconTextCell showCheckIcon text="Cell Text" subtext="Subtext" />
              </PageTableContainer>
            </PageContainer>

            <PageContainer>
              <PageTableContainer>
                <IconTextCell isWarning text="Warning Text" />
              </PageTableContainer>
            </PageContainer>

            <PageContainer>
              <PageTableContainer>
                <IconTextCell isError text="Error Text" />
              </PageTableContainer>
            </PageContainer>

            <PageContainer>
              <PageTableContainer>
                <IconTextCell isInline isSuccess text=" Inline Status Cell" subtext="Status Subtext" />
              </PageTableContainer>
            </PageContainer>

            <PageContainer>
              <PageTableContainer>
                <IconTextCell withoutIcon text="Standard Text Cell" subtext="Subtext" />
              </PageTableContainer>
            </PageContainer>

            <PageContainer>
              <PageTableContainer>
                <IconTextCell
                  withoutIcon
                  link="http://localhost:3000/staff/daily_action_list"
                  text="Cell with Link Text"
                />
              </PageTableContainer>
            </PageContainer>
          </ComponentExampleBlock>

          <ComponentExampleBlock codeText={code_examples.icon_cell}>
            <PageContainer>
              <PageTableContainer>
                <IconCell preloadedData showCheckIcon />
              </PageTableContainer>
            </PageContainer>

            <PageContainer>
              <PageTableContainer>
                <IconCell preloadedData showCheckIcon={false} />
              </PageTableContainer>
            </PageContainer>

            <PageContainer>
              <PageTableContainer>
                <IconCell preloadedData isWarning />
              </PageTableContainer>
            </PageContainer>

            <PageContainer>
              <PageTableContainer>
                <IconCell preloadedData isError />
              </PageTableContainer>
            </PageContainer>
          </ComponentExampleBlock>
        </div>

        <ComponentExampleBlock codeText={code_examples.table_header}>
          <PageContainer>
            <PageTableContainer>
              <TableHeader headers={tableHeaders} onHeaderClick={() => {}} />
            </PageTableContainer>
          </PageContainer>
        </ComponentExampleBlock>

        <ComponentExampleBlock codeText={code_examples.table_footer}>
          <PageContainer>
            <PageTableContainer>
              <TableFooter firstRangeValue={1} lastRangeValue={10} totalCount={100} />
            </PageTableContainer>
          </PageContainer>
        </ComponentExampleBlock>
      </ExampleBlock>

      <ExampleBlock headerText="Pagination">
        <ComponentExampleBlock codeText={code_examples.pagination}>
          <CustomPagination
            show
            page={page}
            totalPages={5}
            onPageChange={(_event, newPage) => {
              setPage(newPage);
            }}
          />
        </ComponentExampleBlock>
      </ExampleBlock>

      <ExampleBlock headerText="Re-usable Filters and Hooks">
        <ComponentExampleBlock codeText={code_examples.use_response} />
        <ComponentExampleBlock codeText={code_examples.use_shared_filters} />
        <ComponentExampleBlock codeText={code_examples.use_last_job_timestamp} />
      </ExampleBlock>

      <ExampleBlock headerText="Generic Modal">
        <ComponentExampleBlock codeText={code_examples.genericModal} center>
          <Button onClick={() => setFormModalVisible(true)}>Form Modal</Button>

          <GenericModal
            isOpen={formModalVisible}
            headerText="Form Modal"
            primaryButtonClick={() => setFormModalVisible(false)}
            secondaryButtonClick={() => {
              setFormModalVisible(false);
            }}
          >
            <FormRow classes="flex">
              <FormCell label="Cell Label" text="Cell Text" />
              <FormCell label="Cell Label" text="Cell Text" />
            </FormRow>

            <FormLineBreak />

            <FormRow>
              <Filters isFormLabel label="Day" data={dayOptions} stateChanger={() => {}} />
            </FormRow>

            <FormLineBreak />

            <FormRow>
              <TextArea label="Text Box" subtext="text box subtext" value="" />
            </FormRow>
          </GenericModal>
        </ComponentExampleBlock>
      </ExampleBlock>
    </div>
  );
}

export default ComponentLibrary;
