import React, { useState, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import { selectStyles, ClearIndicator, DropdownIndicator, Option, theme, OTHER_OPTION_VALUE } from './SelectCommons';

const customGetOptionValue = (option) => `${option.value}-${option.label}`;

const SelectAndTextInput = forwardRef(
  ({ name, options, value, onChange, placeholder, error, disabled, usePortal, classes, isOtherOptionEnabled }, ref) => {
    const innerRef = useRef();
    const ignoreBlur = useRef(false);
    const [inputValue, setInputValue] = useState('');
    const [isCreateEnabled, setIsCreateEnabled] = useState(false);
    const currentRef = ref || innerRef;

    const handleCreate = (createValue) => {
      if (!isCreateEnabled) return;

      const newOption = { value: createValue, label: createValue, wasCreated: true };
      onChange(newOption);
      setInputValue('');
    };

    const handleChange = (selectedOption) => {
      const isSelectedOther = selectedOption.value === OTHER_OPTION_VALUE;
      setIsCreateEnabled(isSelectedOther);
      onChange(isSelectedOther ? null : selectedOption);
      setInputValue('');

      if (isSelectedOther) {
        ignoreBlur.current = true;
        currentRef.current.blur();
        currentRef.current.focus();
      }
    };

    const handleBlur = () => {
      if (ignoreBlur.current) {
        ignoreBlur.current = false;
        return;
      }

      setIsCreateEnabled(false);

      if (inputValue) {
        handleCreate(inputValue);
      }
    };

    const handleInputChange = (input) => {
      setInputValue(input);
    };

    const selectOptions = isOtherOptionEnabled ? [...options, { label: 'Other', value: OTHER_OPTION_VALUE }] : options;
    const components = { DropdownIndicator, ClearIndicator, ...(isOtherOptionEnabled && { Option }) };

    return (
      <CreatableSelect
        ref={currentRef}
        name={name}
        value={value}
        onChange={handleChange}
        onCreateOption={handleCreate}
        isValidNewOption={() => isCreateEnabled && inputValue}
        options={selectOptions}
        formatCreateLabel={(inputLabel) => inputLabel}
        placeholder={isCreateEnabled ? null : placeholder}
        styles={selectStyles(error, disabled)}
        theme={theme}
        components={components}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onBlur={handleBlur}
        isDisabled={disabled}
        menuPortalTarget={usePortal ? document.body : null}
        className={classes}
        getOptionValue={customGetOptionValue}
      />
    );
  }
);

SelectAndTextInput.displayName = 'SelectAndTextInput';

const OptionPropType = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});

SelectAndTextInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: OptionPropType,
  options: PropTypes.arrayOf(OptionPropType),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  usePortal: PropTypes.bool,
  classes: PropTypes.string,
  isOtherOptionEnabled: PropTypes.bool,
};

SelectAndTextInput.defaultProps = {
  value: null,
  options: [],
  placeholder: 'Select or type an option',
  error: false,
  disabled: false,
  usePortal: false,
  classes: null,
  isOtherOptionEnabled: false,
};

export default SelectAndTextInput;
