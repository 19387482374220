export default {
  // BUTTONS
  primaryButton: `import Button from '../../shared/Button';
<Button isPrimary onClick={() => {}}>
  Primary Button
</Button>`,

  secondaryButton: `import Button from '../../shared/Button';
<Button isSecondary onClick={() => {}}>
  Secondary Button
</Button>`,

  tertiaryButton: `import Button from '../../shared/Button';  
<Button isTertiary onClick={() => {}}>
  Tertiary Button
</Button>`,

  iconButton: `import Button from '../../shared/Button';

// OPTIONAL, for custom button, pass in an icon as a child attribute
// (either before or after) text attribute

<Button
  show
  withArrowIcon
  isArrowUp={false}
  onClick={() => {}}
>
  Button with Icon
</Button>`,

  buttonRowComponent: `import ButtonRow from '../../shared/ButtonRow';
<ButtonRow
  primaryLabel="Continue"
  secondaryLabel="Back"
  onPrimaryClick={() => {}}
  onSecondaryClick={() => {}}
/>`,

  expand_button: `import ExpandButton from '../../shared/ExpandButton';
const [showMore, setShowMore] = useState(false);

<ExpandButton onShowMore={setShowMore} />
{showMore && (<div>Show more text</div>)}}`,

  customStyleButton: `import Button from '../../shared/Button;

// CAN PASS IN STYLES AS NEEDED TO @style PROP and @classes PROP

<Button
  show
  classes="mb-2"
  style={{
    padding: '50px', borderColor: 'black', borderRadius: 0, color: 'black',
  }}
  onClick={() => {}}
>
  Button with Custom Styles
</Button>`,

  day_picker: `import DayPicker from '../../shared/DayPicker';
const [dayOfWeek, setDayOfWeek] = useState('');

<DayPicker
  label="Day of Week"
  placeholder={null}
  defaultValue={dayOfWeek}
  onChange={setDayOfWeek}
/>`,

  mui_day_picker: `import MuiDatePicker from '../../shared/MuiDatePicker';
  <MuiDatePicker
    label="Mui Date Picker"
    onChange={() => {}}
    // value prop, defaults to current day
  />
  `,
  time_picker: `import MuiTimePicker from '../../shared/TimePicker';
  <MuiTimePicker
    label="Mui Time Picker"
    onChange={() => {}}
    // value prop, defaults to current time
  />
  `,

  // FILTERS
  filters: `import Filters from '../../Filters';

// TO IMPLEMENT CACHING, add pageToCache attribute and it will use a combination of
// pageToCache and type to save the filters to local storage

<Filters
  pageToCache="component-library"
  label="Empaneled Staff"
  data={[
    {value: '1', label: 'Drew Andrew' },
    {value: '2', label: 'Danny Adam' },
  ]}
  placeholder="Search Staff"
  stateChanger={() => {}}
/>`,

  day_filters: `<Filters
  label="Day"
  data={dayOptions}
  stateChanger={() => {}}
/>`,

  time_filters: `<Filters
  label="Time"
  data={timeOptions}
  stateChanger={() => {}}
/>`,

  search_filters: `import SearchFilter from '../../shared/SearchFilter';
<SearchFilter
  label="Member"
  placeholder="Search by name"
  value={member}
  onChange={() => { }}
/>`,

  // HEADERS
  header: `import Header from '../../shared/Header';
<Header text="Header" />`,

  page_header: `import PageHeader from '../../shared/PageHeader';
<PageHeader text="Page Header" subtext="Header subtext">
  <Button
    isPrimary
    isFloatRight
    onClick={() => {}}
  >
    Apply Filters
  </Button>
</PageHeader>`,

  breadcrumb_header: `import PageHeader from '../../shared/PageHeader';
<PageHeader text="Header" breadcrumbText={"Breadcrumb Header"} breadcrumbLink="/admin/component_library" />`,

  tabs: `import Tabs from '../../Tabs';

const [tabs] = useState([
  { id: 1, name: 'First Tab', key: 'first_tab' },
  {
    id: 2, name: 'SecondTab', key: 'second_tab', count: 1,
  },
]);
const [activeTab, setTab] = useState({ id: 1, name: 'First Tab', key: 'first_tab' });
const tabChange = async (tab) => { setTab(tab); };

<Tabs
  activeTab={activeTab}
  tabs={tabs}
  onChange={tabChange}
/>

{activeTab?.key === 'first_tab' ? (
<div>First Tab Div</div>
) : (
<div>Second Tab Div</div>
)}`,

  tooltip: `import Tooltip from '../../shared/Tooltip';
<Tooltip text={"Some useful info"} />`,

  radio_select: `import RadioSelect from '../../shared/RadioSelect';
<RadioSelect
  label="Status"
  options={['Active', 'Pending']}
  onChange={setRadioSelect}
  value={radioSelect}
/>`,

  // TABLE COMPONENTS
  table_header: `import PageContainer from '../../shared/PageContainer';
import PageTableContainer from '../../shared/PageTableContainer';
import TableHeader from '../../shared_dashboard/TableHeader';

// SEE EXAMPLE FILE FOR INITIALIZING TABLE HEADERS
import useTableHeaders from '../../daily_action_list/hooks/use-table-headers';

const { tableHeaders } = useTableHeaders();

// EXAMPLE FUNCTION
const handleSort = (key, direction) => {
  if (!key || !direction) { return; }

  const sortString = key, direction

  setCurrentPage(1);
  setCurrentSort(sortString);
  updateHeaders(false, sortString);

  fetchDailyActionLists(1, { ...currentFilters, sort_by: sortString });
};

<PageContainer>
  <PageTableContainer>
    <TableHeader
      headers={tableHeaders}
      onHeaderClick={(key, direction) => handleSort(key, direction)}
    />
</PageContainer>`,

  table_footer: `import TableFooter from '../shared_dashboard/TableFooter';
<TableFooter
  firstRangeValue={response?.firstRangeValue}
  lastRangeValue={response?.lastRangeValue}
  totalCount={response?.totalCount}
/>`,

  default_cell: `import DefaultCell from '../../shared_dashboard/DefaultCell';
<DefaultCell
  preloadedData="Default Cell"
  lineTwo="Line Two"
  childText="Child Text"
  customClassName="text-red-500"
/>`,

  empanelment_cell: `import EmpanelmentCell from '../../shared_dashboard/EmpanelmentCell';
<EmpanelmentCell
  show={!props.hideColumns}
  hasEmpanelment={rowData?.empanelment_id}
  dateTime={rowData?.empanelment_date_time}
  locationName={rowData?.empanelment_location}
/>`,

  member_cell: `import MemberCell from '../../shared_dashboard/MemberCell';
<MemberCell
  id={1}
  preloadedData={{ full_name: 'First Last', external_id: 13435 }}
/>`,

  time_cell: `import TimeCell from '../../shared_dashboard/TimeCell';
<TimeCell id={1} data={date} />`,

  icon_text_cell: `import IconTextCell from '../../shared_dashboard/IconTextCell';

<IconTextCell
  showCheckIcon
  text="Cell Text"
  subtext="Subtext"
/>

<IconTextCell
  isWarning
  text="Warning Text"
/>

<IconTextCell
  isError
  text="Error Text"
/>

<IconTextCell
  isInline
  isSuccess
  text=" Inline Status Cell"
  subtext="Status Subtext"
/>

<IconTextCell
  withoutIcon
  text="Standard Text Cell"
  subtext="Subtext"
/>

<IconTextCell
  withoutIcon
  link="http://localhost:3000/staff/daily_action_list"
  text="Cell with Link Text"
/>`,

  icon_cell: `import IconCell from '../../shared_dashboard/IconCell';

<IconCell
  preloadedData
  showCheckIcon
/>

<IconCell
  preloadedData
  showCheckIcon={false}
/>

<IconCell preloadedData isWarning />
<IconCell preloadedData isError />`,

  pagination: `import CustomPagination from '../../shared_dashboard/CustomPagination';

// EXAMPLE OF FUNCTION
const handleChangePage = (newPage) => {
  setCurrentPage(newPage);
  fetchDailyActionLists(newPage, { ...currentFilters, sort_by: currentSort });

  // TRACK page change
  window?.Matomo?.getAsyncTracker()?.trackEvent('dal', 'pagination-click');
};

<CustomPagination
  show={response?.totalPages > 1}
  page={currentPage}
  totalPages={response?.totalPages}
  onPageChange={(_event, newPage) => {
    resetTableView();
    handleChangePage(newPage);
  }}
/>`,

  use_response: `// USE the useResponse hook for any fetching of new data - simply pass in the endpoint and then either
// 1. call using a useEffect or
// 2. call using fetchResponse within a function (ex. for pagination, call fetchResponse onPageChange)
// 
// PARAMS
//    path - option param, can either put in the useResponse call or the fetchResponse call (depending on if you want to
//           be able to call more than one endpoint)
//    page - optional param
//    filters - optional object param

import useResponse from './hooks/use-response';

// EXAMPLE ONE
const { response, fetchResponse } = useResponse('example/path_to_controller_endpoint');

useEffect(() => {
  const page = 1
  const filters = { id: 1 }

  // page and filters OPTIONAL params
  fetchResponse(page, filters);
}, []);

// EXAMPLE TWO
const { response, fetchResponse } = useResponse();

useEffect(() => {
  const path = 'path/to/controller'
  const page = 1
  const filters = { id: 1 }

  // page and filters OPTIONAL params
  fetchResponse(path, page, filters);
}, []);

`,

  use_shared_filters: `import useSharedFilters from './hooks/use-shared-filters'

const {
  dayOptions, staff, locations, members, states, timeoptions,
  fetchStaff, fetchLocations, fetchMembers, fetchStates, fetchTimeOptions
} = useSharedFilters();

// PUT USE EFFECT IN PARENT COMPONENT TO CALL WHICHEVER FILTERS YOU NEED
// EXAMPLE USE_EFFECT BELOW
useEffect(() => {
  fetchStaff();
  fetchLocations();
  fetchMembers();
  fetchStates();
  fetchTimeOptions();
}, []);`,

  use_last_job_timestamp: `import useJobLastTimestamp from './hooks/use-job-last-timestamp';
const { lastJobTimestamp } = useJobLastTimestamp('path/to/controller');`,

  genericModal: `import GenericModal from '../../shared/GenericModal';

<GenericModal
  isOpen={formModalVisible}
  headerText="Form Modal"
  primaryButtonClick={() => setFormModalVisible(false)}
  secondaryButtonClick={() => { setFormModalVisible(false); }}
>
  <FormRow classes="flex">
    <FormCell label="Cell Label" text="Cell Text" />
    <FormCell label="Cell Label" text="Cell Text" />
  </FormRow>

  <FormLineBreak />

  <FormRow>
    <Filters
      isFormLabel
      label="Day"
      data={dayOptions}
      stateChanger={() => {}}
    />
  </FormRow>

  <FormLineBreak />

  <FormRow>
    <TextArea label="Text Box" subtext="text box subtext" value="" />
  </FormRow>
</GenericModal>`,

  panel_summary: `import MemberSummary from '../../panel_summary/MemberSummary';

<MemberSummary
  data={{
    memberInfo: {
      id: 1,
      name: 'Steve Grumplestein',
    },
    statuses: [
      { label: 'On Track', status: 'SUCCESS' },
      { label: 'Care Plan Expired', status: 'ERROR' },
      { label: 'Rx Expiring', status: 'WARNING' },
      { label: 'Recent Hospitalization', status: 'STANDARD' },
    ],
    monthStats: {
      counselor: { numerator: 1, denominator: 4, statusTag: { label: 'Falling Behind', status: 'WARNING' } },
      uds: { numerator: 2, denominator: 4, statusTag: { label: 'At Risk', status: 'ERROR' } },
      provider: {
        numerator: 1, denominator: 1, noStatus: true, statusTag: { label: 'On Track', status: 'SUCCESS', secondaryStatus: 'STANDARD' },
      },
    },
    details: {
      threeMonthStats: {
        attendance:
          [
            {
              label: 'Jan',
              isPresent: [
                true, false, false, true,
              ],
              count: '2/4',
            },
            {
              label: 'Dec',
              isPresent: [
                false, true, false, true,
              ],
              count: '2/4',
            },
            {
              label: 'Dec',
              isPresent: [
                true, false, false, true,
              ],
              count: '2/4',
            },
          ],
        uds: [
          {
            label: 'Jan',
            isPresent: [
              true, false, false, true,
            ],
            count: '2/4',
          },
          {
            label: 'Dec',
            isPresent: [
              false, true, false, true,
            ],
            count: '2/4',
          },
          {
            label: 'Dec',
            isPresent: [
              true, false, false, true,
            ],
            count: '2/4',
          },
        ],
      },
      medical: {
        rx: { text: 'Active, expires in 20d', subtext: '03/03/23' },
        dosage: '16.0mg',
        rxType: 'Tablet',
        rxRec: '7 day duration, refills until next medical visit',
        rxRecDetails: 'Multiple requirements incomplete',
        medList: ['Klonopin', 'Adderall'],
        pharmacy: 'CVS \n 1950 State St, New Albany, IN 47150 \n (812) 948-8305',
        lastProviderVisit: { text: '15d ago', subtext: '03/03/23' },
        lastPillCount: { text: 'The expected quantity, 4d ago', subtext: '03/03/23' },
        lastFentanyl: { text: 'Negative, 20d ago', subtext: '03/03/23' },
        lastUds: { text: 'Bup present, 7d ago ', subtext: '03/03/23' },
      },
      clinical: {
        specialPopProblem: { text: 'Pregnant', subtext: 'from Health Check-in on 1/17/23' },
        phaseOfCare: 'Maintenance',
        engagementRec: { text: 'Provide Positive Reinforcement', status: 'SUCCESS' },
        rss: 'No',
        carePlan: { text: 'Compliant, expired in 20d', link: 'www.google.com', subtext: '02/20/23' },
        openTasks: { text: 3, link: 'www.google.com' },
        healthCheckin: {
          text: '2/22/23', statusText: 'Clinically Concerning', status: 'ERROR', subtext: 'Usage, Protective Factors',
        },
      },
      demographics: {
        mrn: '34134',
        dob: '9/3/1975',
        tx: 43,
        preferredName: 'Elaine',
        pronouns: 'She/Her',
      },
      attendance: [
        {
          label: '05/23/2023',
          dateTime: { date: '01/01/2023', time: '5:00 PM EST' },
          appointmentStatus: { status: 'Attended', provider: 'Makeup Counselor' },
          screenings: { uds: 'Not Submitted', fent: 'Not Submitted' },
        },
        {
          label: '04/23/2023',
          dateTime: { date: '01/01/2023', time: '5:00 PM EST' },
          appointmentStatus: { status: 'Absent', provider: 'Empaneled Counselor' },
          screenings: { uds: 'Bup Present', fent: 'Not Requested' },
        },
        {
          label: '04/23/2023',
          dateTime: { date: '01/01/2023', time: '5:00 PM EST' },
          appointmentStatus: { status: 'Absent', provider: 'Empaneled Counselor' },
          screenings: { uds: 'Bup Present', fent: 'Not Requested' },
        },
        {
          label: '04/23/2023',
          dateTime: { date: '01/01/2023', time: '5:00 PM EST' },
          appointmentStatus: { status: 'Absent', provider: 'Empaneled Counselor' },
          screenings: { uds: 'Bup Present', fent: 'Not Requested' },
        },
        {
          label: '04/23/2023',
          dateTime: { date: '01/01/2023', time: '5:00 PM EST' },
          appointmentStatus: { status: 'Absent', provider: 'Empaneled Counselor' },
          screenings: { uds: 'Bup Present', fent: 'Not Requested' },
        },
        {
          label: '04/23/2023',
          dateTime: { date: '01/01/2023', time: '5:00 PM EST' },
          appointmentStatus: { status: 'Absent', provider: 'Empaneled Counselor' },
          screenings: { uds: 'Bup Present', fent: 'Not Requested' },
        },
        {
          label: '04/23/2023',
          dateTime: { date: '01/01/2023', time: '5:00 PM EST' },
          appointmentStatus: { status: 'Absent', provider: 'Empaneled Counselor' },
          screenings: { uds: 'Bup Present', fent: 'Not Requested' },
        },
        {
          label: '04/23/2023',
          dateTime: { date: '01/01/2023', time: '5:00 PM EST' },
          appointmentStatus: { status: 'Absent', provider: 'Empaneled Counselor' },
          screenings: { uds: 'Bup Present', fent: 'Not Requested' },
        },
      ],
    },
  }}
/>`,

  // PANEL SUMMARY
  statuses: `import StatusTag from '../../shared/StatusTag';
import StatusTagsContainer from '../../shared/StatusTagsContainer';

const statuses = [
  { label: 'On Track', status: 'SUCCESS' },
  { label: 'Care Plan Expired', status: 'ERROR' },
  { label: 'Rx Expiring', status: 'WARNING' },
  { label: 'Recent Hospitalization', status: 'STANDARD' },
  { label: 'Ongoing Substance Use', status: null },
];

<StatusTag label="Label" status="SUCCESS" />
<StatusTagsContainer statuses={statuses} />
<StatusTag isMedium label="Error Tag" isErrorFilled />`,

  text_row_container: `import TextRowContainer from '../../shared/TextRowContainer';

<TextRowContainer
  label="Standard Text Row Container"
  text="Text"
/>

<TextRowContainer
  label="Text Row Container with Subtext"
  text="Text"
  subtext="Sub-text"
/>

<TextRowContainer
  label="Text Row Container with Newline Subtext"
  secondaryLabel="Secondary Label"
  text="Text"
  subtext="Subtext"
  isNewlineSubtext
/>

<TextRowContainer
  label="Text Row Container with Concat List"
  text={['first item', 'second item']?.join(', ')}
/>

<TextRowContainer
  label="Text Row Container with Link"
  text="Text"
  link="www.google.com"
/>

<TextRowContainer
  label="Status Text Row Container"
  text="Text"
  status="SUCCESS"
/>

<TextRowContainer
  label="Warning Status Text Row Container"
  text="Text"
  status="WARNING"
/>

<TextRowContainer
  label="Error Status Text Row Container"
  text="Text"
  status="ERROR"
/>

<TextRowContainer
  label="Text Row Container with No Bottom Border"
  text="Text"
  subtext="Subtext"
  hasBorder={false}
/>`,

  month_bar: `import MonthBar from '../../shared/MonthBar';

<MonthBar data={{ label: 'Label', isPresent: [true, false, false, true] }} />`,

  numerator_denominator: `import NumeratorDenominator from '../../shared/NumeratorDenominator';

<div className="flex">
  <NumeratorDenominator
    numerator={1}
    denominator={2}
    status="WARNING"
  />

  <NumeratorDenominator
    numerator={1}
    denominator={2}
    status="SUCCESS"
  />

  <NumeratorDenominator
    numerator={2}
    denominator={2}
    status="ERROR"
  />

  <NumeratorDenominator
    numerator={0}
    denominator={2}
  />
</div>`,

  numerator_denominator_container: `import NumeratorDenominatorContainer from '../../shared/NumeratorDenominatorContainer';

<div className="flex">
  <NumeratorDenominatorContainer
    label="Label"
    numerator={1}
    denominator={2}
    statusTag={{ label: 'Label', status: 'WARNING' }}
    classes="flex-1"
  />

  <NumeratorDenominatorContainer
    label="Label"
    numerator={2}
    denominator={2}
    statusTag={{ label: 'Label', status: 'SUCCESS' }}
    classes="flex-1"
  />

  <NumeratorDenominatorContainer
    label="Label"
    numerator={0}
    denominator={2}
    statusTag={{ label: 'Label', status: 'ERROR' }}
    classes="flex-1"
  />

  <NumeratorDenominatorContainer
    label="Label"
    numerator={2}
    denominator={2}
    statusTag={{ label: 'Label' }}
    classes="flex-1"
  />

  <NumeratorDenominatorContainer
    label="Label"
    numerator={2}
    denominator={2}
    statusTag={{ label: 'Label', status: 'SUCCESS' }}
    noStatus
    classes="flex-1"
  />
</div>`,
};
