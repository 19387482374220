import React, { useState } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import MemberSummaryHeader from './MemberSummaryHeader';
import MemberSummaryDetails from './MemberSummaryDetails';

function MemberSummary({ data }) {
  const containerClasses = 'bg-white p-4 rounded';

  const [openStatus, setOpenStatus] = useState(true);

  function renderOpenContent() {
    if (openStatus) {
      return (
        <>
          <MemberSummaryDetails data={data?.details} />
        </>
      );
    }
    return <></>;
  }

  return (
    <div className={containerClasses}>
      <MemberSummaryHeader
        isOpen={openStatus}
        memberInfo={data?.memberInfo}
        statuses={data?.statuses}
        monthStats={data?.monthStats}
        setOpenStatus={setOpenStatus}
      />

      {renderOpenContent()}
    </div>
  );
}

MemberSummary.propTypes = {
  data: PropTypes.object.isRequired,
};

// EXAMPLE DATA STRUCT
// data={{
//   memberInfo: {
//     id: 1,
//     name: 'Steve Grumplestein',
//   },
//   statuses: [
//     { label: 'On Track', status: 'SUCCESS' },
//     { label: 'Care Plan Expired', status: 'ERROR' },
//     { label: 'Rx Expiring', status: 'WARNING' },
//     { label: 'Recent Hospitalization', status: 'STANDARD' },
//   ],
//   monthStats: {
//     counselor: { numerator: 1, denominator: 4, statusTag: { label: 'Falling Behind', status: 'WARNING' } },
//     uds: { numerator: 2, denominator: 4, statusTag: { label: 'At Risk', status: 'ERROR' } },
//     provider: {
//       numerator: 1, denominator: 1, noStatus: true, statusTag: { label: 'On Track', status: 'SUCCESS', secondaryStatus: 'STANDARD' },
//     },
//   },
//   details: {
//     threeMonthStats: {
//       attendance:
//         [
//           {
//             label: 'Jan',
//             isPresent: [
//               true, false, false, true,
//             ],
//           },
//           {
//             label: 'Dec',
//             isPresent: [
//               false, true, false, true,
//             ],
//           },
//           {
//             label: 'Dec',
//             isPresent: [
//               true, false, false, true,
//             ],
//           },
//         ],
//       uds: [
//         {
//           label: 'Jan',
//           isPresent: [
//             false, true, false, true,
//           ],
//         },
//         {
//           label: 'Dec',
//           isPresent: [
//             false, true, false, true,
//           ],
//         },
//         {
//           label: 'Dec',
//           isPresent: [
//             true, false, false, true,
//           ],
//         },
//       ],
//     },
//     medical: {
//       rx: { text: 'Active, expires in 20d', subtext: '03/03/23' },
//       dosage: '16.0mg',
//       rxType: 'Tablet',
//       rxRec: '7 day duration, refills until next medical visit',
//       rxRecDetails: 'Multiple requirements incomplete',
//       medList: ['Klonopin', 'Adderall'],
//       pharmacy: 'CVS \n 1950 State St, New Albany, IN 47150 \n (812) 948-8305',
//       lastProviderVisit: { text: '15d ago', subtext: '03/03/23' },
//       lastPillCount: { text: 'The expected quantity, 4d ago', subtext: '03/03/23' },
//       lastFentanyl: { text: 'Negative, 20d ago', subtext: '03/03/23' },
//       lastUds: { text: 'Bup present, 7d ago ', subtext: '03/03/23' },
//     },
//     clinical: {
//       specialPopProblem: { text: 'Pregnant', subtext: 'from Health Check-in on 1/17/23' },
//       phaseOfCare: 'Maintenance',
//       engagementRec: { text: 'Provide Positive Reinforcement', status: 'SUCCESS' },
//       rss: 'No',
//       carePlan: { text: 'Compliant, expired in 20d', link: 'www.google.com', subtext: '02/20/23' },
//       openTasks: { text: 3, link: 'www.google.com' },
//       healthCheckin: {
//         text: '2/22/23', statusText: 'Clinically Concerning', status: 'ERROR', subtext: 'Usage, Protective Factors',
//       },
//     },
//     demographics: {
//       mrn: '34134',
//       dob: '9/3/1975',
//       tx: 43,
//       preferredName: 'Elaine',
//       pronouns: 'She/Her',
//     },
//     attendance: [
//       {
//         label: '05/23/2023',
//         dateTime: { date: '01/01/2023', time: '5:00 PM EST' },
//         appointmentStatus: { status: 'Attended', provider: 'Makeup Counselor' },
//         screenings: { uds: 'Not Submitted', fent: 'Not Submitted' },
//       },
//       {
//         label: '04/23/2023',
//         dateTime: { date: '01/01/2023', time: '5:00 PM EST' },
//         appointmentStatus: { status: 'Absent', provider: 'Empaneled Counselor' },
//         screenings: { uds: 'Bup Present', fent: 'Not Requested' },
//       },
//       {
//         label: '04/23/2023',
//         dateTime: { date: '01/01/2023', time: '5:00 PM EST' },
//         appointmentStatus: { status: 'Absent', provider: 'Empaneled Counselor' },
//         screenings: { uds: 'Bup Present', fent: 'Not Requested' },
//       },
//       {
//         label: '04/23/2023',
//         dateTime: { date: '01/01/2023', time: '5:00 PM EST' },
//         appointmentStatus: { status: 'Absent', provider: 'Empaneled Counselor' },
//         screenings: { uds: 'Bup Present', fent: 'Not Requested' },
//       },
//       {
//         label: '04/23/2023',
//         dateTime: { date: '01/01/2023', time: '5:00 PM EST' },
//         appointmentStatus: { status: 'Absent', provider: 'Empaneled Counselor' },
//         screenings: { uds: 'Bup Present', fent: 'Not Requested' },
//       },
//       {
//         label: '04/23/2023',
//         dateTime: { date: '01/01/2023', time: '5:00 PM EST' },
//         appointmentStatus: { status: 'Absent', provider: 'Empaneled Counselor' },
//         screenings: { uds: 'Bup Present', fent: 'Not Requested' },
//       },
//       {
//         label: '04/23/2023',
//         dateTime: { date: '01/01/2023', time: '5:00 PM EST' },
//         appointmentStatus: { status: 'Absent', provider: 'Empaneled Counselor' },
//         screenings: { uds: 'Bup Present', fent: 'Not Requested' },
//       },
//       {
//         label: '04/23/2023',
//         dateTime: { date: '01/01/2023', time: '5:00 PM EST' },
//         appointmentStatus: { status: 'Absent', provider: 'Empaneled Counselor' },
//         screenings: { uds: 'Bup Present', fent: 'Not Requested' },
//       },
//     ],
//   },
// }}

export default MemberSummary;
