import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MemberDetailsBox from '../../member_summary/MemberDetailsBox';
import HistoricalDetailsSection from '../../member_summary/HistoricalDetailsSection';
import ChartingNotes from '../../empanelment/member_encounter_history/ChartingNotes';
import { useSelectedNotes } from './useSelectedNotes';
import MedicalSection from '../../member_summary/MedicalSection';
import { SmartPhrasesPropType } from '../../documentation/helpers/types';

function MemberDetails({ prescriptionTaskId, chartingNotesIds, memberData, authenticityToken, smartPhrases }) {
  const [isFullWidth, setIsFullWidth] = useState(false);
  const { setSelectedNotes } = useSelectedNotes();
  const { memberSummaries: { clinical, medical, demographics, historicalDetails, historicalStats } = {} } =
    memberData || {};
  const toggleFullWidth = useCallback(() => setIsFullWidth((prev) => !prev), []);

  return (
    <div className="bg-white">
      <div className="h-full flex flex-row justify-between items-start flex-wrap">
        <ChartingNotes
          toggleFullWidth={toggleFullWidth}
          isFullWidth={isFullWidth}
          member={memberData}
          authenticityToken={authenticityToken}
          onSelectedNotesChanged={setSelectedNotes}
          selectedDocumentationIds={chartingNotesIds}
          prescriptionTaskId={prescriptionTaskId}
          smartPhrases={smartPhrases}
        />
        <div
          className={classNames('flex p-4 mt-4 gap-x-2', { 'flex-col w-6/12': !isFullWidth, 'w-full': isFullWidth })}
        >
          <div className="flex-1 flex flex-col gap-3">
            {medical && <MedicalSection medical={medical} member={memberData} />}
            {clinical && <MemberDetailsBox {...clinical} />}
            {demographics && <MemberDetailsBox {...demographics} />}
          </div>
          <div className="flex-1">
            <HistoricalDetailsSection
              historicalStatsProps={{
                data: historicalStats?.componentData,
                lastSyncedDate: historicalStats?.updatedAt,
              }}
              attendanceWeeksProps={{
                data: historicalDetails?.componentData,
                lastSyncedDate: historicalDetails?.updatedAt,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

MemberDetails.propTypes = {
  prescriptionTaskId: PropTypes.number.isRequired,
  memberData: PropTypes.object.isRequired,
  chartingNotesIds: PropTypes.arrayOf(PropTypes.string),
  authenticityToken: PropTypes.string.isRequired,
  smartPhrases: SmartPhrasesPropType,
};

MemberDetails.defaultProps = {
  chartingNotesIds: [],
  smartPhrases: null,
};

export default MemberDetails;
