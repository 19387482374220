import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PiMinusSquareBold, PiPlusSquareBold } from 'react-icons/pi';
import StatusTagsContainer from '../shared/StatusTagsContainer';
import OneMonthStats from '../member_summary/OneMonthStats';
import GroupInformation from '../member_summary/GroupInformation';
import Notes from '../member_summary/Notes';
import MemberDetailsBox from '../member_summary/MemberDetailsBox';
import HistoricalDetailsSection from '../member_summary/HistoricalDetailsSection';
import MedicalSection from '../member_summary/MedicalSection';

function MemberInfo({ member }) {
  const [isOpen, setIsOpen] = useState(false);
  const { firstName, lastName, groupInformation, memberSummaries, mrn } = member || {};
  const { medical, clinical, demographics, healthieProfile, monthToDate, historicalStats, historicalDetails } =
    memberSummaries || {};
  const { componentData: healthieComponentData } = healthieProfile || {};
  const { tags, note } = healthieComponentData || {};
  const isHistoricalData = historicalStats || historicalDetails;
  const isMemberDetailsData = medical || clinical || demographics;

  return (
    <div data-testid="member-info" className="bg-white px-5 py-2.5 rounded-md shadow">
      <div className="flex gap-2.5">
        <div className="flex flex-col gap-2.5 flex-1">
          <div className="flex gap-2.5">
            <button type="button" className="p-1.5" onClick={() => setIsOpen((prevState) => !prevState)}>
              {isOpen ? (
                <PiMinusSquareBold data-testid="minus-square-icon" className="w-5 h-5" />
              ) : (
                <PiPlusSquareBold className="w-5 h-5" data-testid="plus-square-icon" />
              )}
            </button>
            <p className="text-2xl text-teal-700 font-bold mb-0">{`${firstName} ${lastName}`}</p>
          </div>
          {mrn && <div className="text-gray-600 font-semibold">{mrn}</div>}
          {tags?.length ? <StatusTagsContainer statuses={tags} fullHeightTags={false} /> : null}
        </div>
        <div className="flex-1">
          <OneMonthStats monthData={monthToDate?.componentData} />
        </div>
      </div>
      {isOpen && (
        <>
          {(groupInformation || note) && (
            <div className="flex justify-between mt-2.5 gap-8">
              {groupInformation && (
                <div className="flex-1">
                  <GroupInformation {...groupInformation} />
                </div>
              )}
              {note && (
                <div className="flex-1">
                  <Notes notes={note} />
                </div>
              )}
            </div>
          )}
          {(isMemberDetailsData || isHistoricalData) && (
            <div className="flex gap-2.5 mt-10">
              {isMemberDetailsData && (
                <div className="flex-1">
                  {medical && <MedicalSection member={member} medical={medical} />}
                  {clinical && <MemberDetailsBox {...clinical} />}
                  {demographics && <MemberDetailsBox {...demographics} />}
                </div>
              )}
              {isHistoricalData && (
                <div className="flex-1">
                  <HistoricalDetailsSection
                    historicalStatsProps={{
                      data: historicalStats?.componentData,
                      lastSyncedDate: historicalStats?.updatedAt,
                    }}
                    attendanceWeeksProps={{
                      data: historicalDetails?.componentData,
                      lastSyncedDate: historicalDetails?.updatedAt,
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export const memberSummaryPropType = PropTypes.shape({
  componentData: PropTypes.oneOfType(PropTypes.object, PropTypes.arrayOf(PropTypes.object)),
  componentType: PropTypes.string,
  createdAt: PropTypes.string,
  episodeOfCareId: PropTypes.number,
  id: PropTypes.number,
  updatedAt: PropTypes.string,
});
MemberInfo.propTypes = {
  member: PropTypes.shape({
    name: PropTypes.string.isRequired,
    memberSummaries: PropTypes.shape({
      clinical: memberSummaryPropType,
      demographics: memberSummaryPropType,
      healthieProfile: memberSummaryPropType,
      historicalDetails: memberSummaryPropType,
      historicalStats: memberSummaryPropType,
      medical: memberSummaryPropType,
      monthToDate: memberSummaryPropType,
    }),
    groupInformation: PropTypes.shape({
      clinicalModel: PropTypes.string,
      counselorName: PropTypes.string,
      dateTime: PropTypes.string,
      groupType: PropTypes.string,
      id: PropTypes.number,
      location: PropTypes.string,
      modality: PropTypes.string,
      officeManagerName: PropTypes.string,
      providerName: PropTypes.string,
    }),
  }).isRequired,
};

export default MemberInfo;
