import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import moment from 'moment';

import Picker from 'rc-picker';
import 'rc-picker/assets/index.css';
import enUS from 'rc-picker/lib/locale/en_US';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import classNames from 'classnames';
import TimezoneSelect from 'react-timezone-select';
import Filters from './Filters';
import DayPicker from './shared/DayPicker';
import UpsertConfirmationModal from './UpsertConfirmationModal';
import GroupsModal from './GroupsModal';

import { customStyles, theme } from '../helpers/FilterStyles';
import { FILTER_TYPES } from './empanelment/management/constants';
import useHttp from './shared/hooks/use-http';
import makeupGroupSchema, { clinicalModelRequirement } from '../schemas/makeupGroup';
import { CLINICAL_MODEL_LABELS, TIMEZONES } from '../helpers/constants';
import { DEFAULT_TIME_FORMAT } from '../helpers/DateFormatter';

const FORM_DEFAULT_VALUES = {
  facility: {},
  counselor: {},
  provider: {},
  officeManager: {},
  dayOfWeek: '',
  startTime: null,
  timezone: {},
  modality: {},
  clinicalModel: {},
  secondaryGroupTypes: [],
  groupOccurrence: null,
};

function parseFormValues(values) {
  return [
    {
      label: 'Day/Time',
      value: `${values.dayOfWeek.substring(0, 3)}, ${moment(values.startTime).format(
        DEFAULT_TIME_FORMAT
      )} ${values.timezone?.abbrev}`,
    },
    {
      label: 'Group Meeting Facility',
      value: values.facility.label,
    },
    {
      label: 'First Group Occurrence',
      value: moment(values.groupOccurrence).format('M/D/YY'),
    },
    {
      label: 'Type',
      value: values.clinicalModel.label,
    },
    {
      label: 'Other Information',
      value: values.secondaryGroupTypes.map((type) => type.label).join(', '),
    },
    {
      label: 'Modality',
      value: values.modality.label,
    },
    {
      label: 'Counselor',
      value: values.counselor.label,
    },
    {
      label: 'Provider',
      value: values.provider.label,
    },
    {
      label: 'Office Manager',
      value: values.officeManager.label,
    },
  ];
}

function UpsertMakeupGroupModal(props) {
  const [confirmationModalData, setConfirmationModalData] = useState(null);
  const { isLoading, sendRequest } = useHttp();
  const isEdit = !!props.editRowId;

  const testID = `createModal-${props.testID}`;

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(makeupGroupSchema),
    reValidateMode: 'onBlur',
    defaultValues: {
      ...FORM_DEFAULT_VALUES,
      ...props.defaultValues,
    },
  });
  const watchClinicalModel = watch('clinicalModel', props.defaultValues.clinicalModel);

  const isClinicalModelProviderOnly = watchClinicalModel.label === CLINICAL_MODEL_LABELS.PROVIDER_ONLY_MAKEUP_GROUP;
  const isClinicalModelRxAssistanceGroup = watchClinicalModel.label === CLINICAL_MODEL_LABELS.RX_ASSISTANCE_GROUP;
  const isClinicalModelCounselorMakeupGroup = watchClinicalModel.label === CLINICAL_MODEL_LABELS.COUNSELOR_MAKEUP_GROUP;
  const isCounselorSelectDisabled = isClinicalModelProviderOnly || isClinicalModelRxAssistanceGroup;

  const doesClinicalModelRequireProvider = watchClinicalModel?.label
    ? clinicalModelRequirement[watchClinicalModel?.label]?.provider
    : true;
  const doesClinicalModelRequireCounselor = watchClinicalModel?.label
    ? clinicalModelRequirement[watchClinicalModel?.label]?.counselor
    : true;

  useEffect(() => {
    if (isClinicalModelProviderOnly || isClinicalModelRxAssistanceGroup) setValue('counselor', {});
  }, [isClinicalModelProviderOnly, isClinicalModelRxAssistanceGroup, setValue]);

  useEffect(() => {
    if (isClinicalModelCounselorMakeupGroup) setValue('provider', {});
  }, [isClinicalModelCounselorMakeupGroup, setValue]);

  useEffect(() => {
    reset();

    return reset;
  }, [reset]);

  const onSubmit = async (data) => {
    const empanelment_group = {
      template_type: 'makeup',
      empanelment_group_modality_id: data.modality.value,
      clinical_model_id: data.clinicalModel.value,
      office_manager_id: data.officeManager.value,
      counselor_id: data.counselor.value,
      provider_id: data.provider.value,
      location_id: data.facility.value,
    };

    const base_params = {
      day: data.dayOfWeek,
      time: moment(data.startTime).format('HH:mm'),
      time_zone: data.timezone.value,
      empanelment_secondary_group_type_ids: data.secondaryGroupTypes.map((type) => type.value),
    };

    if (isEdit) {
      sendRequest(`/staff/empanelment_groups/update_template_group/${props.editRowId}`, {
        method: 'PUT',
        headers: { common: { 'X-CSRF-Token': props.authenticityToken } },
        data: {
          ...base_params,
          is_edit: true,
          empanelment_group: {
            ...empanelment_group,
            id: props.editRowId,
          },
          first_group_occurrence: moment(data.groupOccurrence).format('YYYY-MM-DD'),
        },
      })
        .then(props.onSuccess)
        .catch(props.onError);
    } else {
      sendRequest('/staff/empanelment_groups/create_template_group/', {
        method: 'POST',
        headers: { common: { 'X-CSRF-Token': props.authenticityToken } },
        data: {
          ...base_params,
          empanelment_group,
          first_group_occurrence: moment(data.groupOccurrence).format('YYYY-MM-DD'),
        },
      })
        .then(props.onSuccess)
        .catch(props.onError);
    }
  };

  if (confirmationModalData) {
    return (
      <UpsertConfirmationModal
        testID={testID}
        mode={isEdit ? 'edit' : 'create'}
        onBack={() => setConfirmationModalData(null)}
        onContinue={() => onSubmit(confirmationModalData)}
        values={parseFormValues(confirmationModalData)}
        isLoading={isLoading}
      />
    );
  }

  const footerButtons = [
    {
      label: 'Back',
      isSecondary: true,
      onClick: props.onCancel,
      'data-testid': `backBtn-${testID}`,
    },
    {
      label: 'Continue',
      isPrimary: true,
      type: 'submit',
      form: 'upsert-makeup-group-form',
      'data-testid': `continueBtn-${testID}`,
      isLoading,
    },
  ];

  return (
    <GroupsModal
      data-testid={testID}
      isOpen
      header={`${isEdit ? 'Edit' : 'Create'} Makeup Group`}
      footerButtons={footerButtons}
      size="large"
      isLoading={isLoading}
    >
      <p className="mb-0">*Required field</p>
      <form id="upsert-makeup-group-form" onSubmit={handleSubmit(setConfirmationModalData)}>
        <dl className="bg-white flex flex-col rounded-lg shadow">
          <div className="grid grid-cols-4">
            <div className="flex-filters-empanelment">
              <dt className="font-bold mx-6 pb-2 pt-5">Day of the Week*</dt>
              <Controller
                name="dayOfWeek"
                control={control}
                render={({ field, fieldState }) => (
                  <DayPicker
                    value={field.value}
                    onChange={field.onChange}
                    error={fieldState.error?.message}
                    placeholder="Select day"
                  />
                )}
              />
            </div>

            <div className="flex-filters-empanelment">
              <dt className="font-bold mx-6 pb-2 pt-5">Start Time*</dt>
              <div
                className={classNames('mx-6 pb-5 time-picker-container', {
                  danger: errors.startTime,
                })}
              >
                <Controller
                  name="startTime"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <TimePicker
                        showSecond={false}
                        value={field.value}
                        placeholder="Select time"
                        onChange={field.onChange}
                        use12Hours
                        inputReadOnly
                      />
                      {fieldState.error && <p className="text-red-500 text-xs italic">{fieldState.error.message}</p>}
                    </>
                  )}
                />
              </div>
            </div>

            <div className="flex-filters-empanelment">
              <dt className="font-bold mx-6 pb-2 pt-5">Time Zone*</dt>

              <div
                className={classNames('mx-6 pb-5 time-picker-container', {
                  danger: errors.timezone,
                })}
              >
                <Controller
                  name="timezone"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <TimezoneSelect
                        value={field.value}
                        onChange={field.onChange}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select time zone"
                        styles={customStyles}
                        theme={theme}
                        timezones={TIMEZONES}
                      />
                      {fieldState.error && <p className="text-red-500 text-xs italic">{fieldState.error.message}</p>}
                    </>
                  )}
                />
              </div>
            </div>
            <div className="flex-filters-empanelment">
              <dt className="font-bold mx-6 pb-2 pt-5">Group Meeting Facility*</dt>

              <dd className="mx-6 pb-5">
                <Controller
                  name="facility"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Filters
                      isMulti={false}
                      removeAllSelection
                      type={FILTER_TYPES.facility}
                      data={props.facilitiesData}
                      stateChanger={field.onChange}
                      value={field.value}
                      placeholder="Select facility"
                      error={fieldState.error?.message}
                      testID={`facilities-${testID}`}
                    />
                  )}
                />
              </dd>
            </div>
          </div>

          <hr className="w-full" />

          <div
            className={classNames('grid', {
              'grid-cols-4': props.secondaryGroupTypeFeatureFlag,
              'grid-cols-3': !props.secondaryGroupTypeFeatureFlag,
            })}
          >
            <div className="flex-filters-empanelment">
              <dt className="font-bold mx-6 pb-2 pt-5">
                {isEdit ? 'Next ' : 'First '}
                Group Occurrence*
              </dt>

              <dd
                className={classNames('mx-6 pb-5 time-picker-container', {
                  danger: errors.startTime,
                })}
              >
                <Controller
                  name="groupOccurrence"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Picker
                        type="date"
                        locale={enUS}
                        generateConfig={momentGenerateConfig}
                        placeholder="Select date"
                        value={field.value}
                        onChange={field.onChange}
                        className={{
                          danger: fieldState.error,
                        }}
                      />
                      {fieldState.error && <p className="text-red-500 text-xs italic">{fieldState.error.message}</p>}
                    </>
                  )}
                />
              </dd>
            </div>

            <div className="flex-filters-empanelment">
              <dt className="font-bold mx-6 pb-2 pt-5">Type*</dt>

              <dd className="mx-6 pb-5">
                <Controller
                  name="clinicalModel"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Filters
                      isMulti={false}
                      removeAllSelection
                      type="clinicalModel"
                      data={props.empanelmentClinicalModelData}
                      stateChanger={field.onChange}
                      value={field.value}
                      placeholder="Select type"
                      error={fieldState.error?.message}
                      testID={`clinicalModel-${testID}`}
                    />
                  )}
                />
              </dd>
            </div>

            {props.secondaryGroupTypeFeatureFlag ? (
              <div className="flex-filters-empanelment">
                <dt className="font-bold mx-6 pb-2 pt-5">Other information</dt>

                <dd className="mx-6 pb-5">
                  <Controller
                    name="secondaryGroupTypes"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Filters
                        isMulti
                        removeAllSelection
                        type="secondaryGroupTypes"
                        data={props.empanelmentSecondaryGroupTypeData}
                        stateChanger={field.onChange}
                        value={field.value}
                        placeholder="Select type"
                        error={fieldState.error?.message}
                        testID={`secondary-group-types-${testID}`}
                      />
                    )}
                  />
                </dd>
              </div>
            ) : null}

            <div className="flex-filters-empanelment">
              <dt className="font-bold mx-6 pb-2 pt-5">Modality*</dt>

              <dd className="mx-6 pb-5">
                <Controller
                  name="modality"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Filters
                      isMulti={false}
                      removeAllSelection
                      type="modalities"
                      data={props.modalityData}
                      stateChanger={field.onChange}
                      value={field.value}
                      placeholder="Select modality"
                      error={fieldState.error?.message}
                      testID={`modalities-${testID}`}
                    />
                  )}
                />
              </dd>
            </div>
          </div>

          <hr className="w-full" />

          <div className="grid grid-cols-3">
            <div className="flex-filters-empanelment">
              <dt className={classNames('font-bold mx-6 pb-2 pt-5', { 'opacity-50': isCounselorSelectDisabled })}>
                Counselor
                {doesClinicalModelRequireCounselor && '*'}
              </dt>

              <dd className="mx-6 pb-4">
                <Controller
                  name="counselor"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Filters
                      isMulti={false}
                      removeAllSelection
                      type="counselor"
                      data={props.staffData}
                      stateChanger={field.onChange}
                      value={field.value}
                      placeholder="Select counselor"
                      error={fieldState.error?.message}
                      testID={`counselors-${testID}`}
                      disabled={isCounselorSelectDisabled}
                      disabledMessage={null}
                    />
                  )}
                />
              </dd>
            </div>

            <div className="flex-filters-empanelment">
              <dt
                className={classNames('font-bold mx-6 pb-2 pt-5', {
                  'opacity-50': isClinicalModelCounselorMakeupGroup,
                })}
              >
                Provider
                {doesClinicalModelRequireProvider && '*'}
              </dt>

              <dd className="mx-6 pb-4">
                <Controller
                  name="provider"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Filters
                      isMulti={false}
                      removeAllSelection
                      type="provider"
                      data={props.staffData}
                      stateChanger={field.onChange}
                      value={field.value}
                      placeholder="Select provider"
                      error={fieldState.error?.message}
                      disabled={isClinicalModelCounselorMakeupGroup}
                      testID={`provider-${testID}`}
                      disabledMessage={null}
                    />
                  )}
                />
              </dd>
            </div>
            <div className="flex-filters-empanelment">
              <dt className="font-bold mx-6 pb-2 pt-5">Office Manager*</dt>

              <dd className="mx-6 pb-4">
                <Controller
                  name="officeManager"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Filters
                      isMulti={false}
                      removeAllSelection
                      type="office_managers"
                      data={props.officeManagerData}
                      stateChanger={field.onChange}
                      value={field.value}
                      placeholder="Select office manager"
                      error={fieldState.error?.message}
                      testID={`office-managers-${testID}`}
                    />
                  )}
                />
              </dd>
            </div>
          </div>
        </dl>
      </form>
    </GroupsModal>
  );
}

const FormShape = {
  facility: PropTypes.object,
  counselor: PropTypes.object,
  provider: PropTypes.object,
  officeManager: PropTypes.object,
  dayOfWeek: PropTypes.string,
  startTime: PropTypes.string,
  timezone: PropTypes.object,
  modality: PropTypes.object,
  clinicalModel: PropTypes.object,
  secondaryGroupTypes: PropTypes.array,
  groupOccurrence: null,
};

UpsertMakeupGroupModal.propTypes = {
  testID: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  secondaryGroupTypeFeatureFlag: PropTypes.bool,

  // filters
  facilitiesData: PropTypes.array.isRequired,
  staffData: PropTypes.array.isRequired,
  officeManagerData: PropTypes.array.isRequired,
  modalityData: PropTypes.array.isRequired,
  empanelmentClinicalModelData: PropTypes.array.isRequired,
  empanelmentSecondaryGroupTypeData: PropTypes.array.isRequired,

  defaultValues: FormShape,
  editRowId: PropTypes.number,

  // functions
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

UpsertMakeupGroupModal.defaultProps = {
  secondaryGroupTypeFeatureFlag: false,
  editRowId: null,
  onCancel: () => {},
  defaultValues: FORM_DEFAULT_VALUES,
};

export default UpsertMakeupGroupModal;
