import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import GeneralCell from '../../GeneralCell';
import SimpleTable from '../../shared/SimpleTable';
import { mdyyyy, daysOverdue } from '../../../helpers/DateFormatter';
import AppointmentOccurrenceCell from '../../AppointmentOccurrenceCell';
import RxNote from './RxNote';
import { parseStatus } from './helper';
import { RX_STATUS } from '../list/types';
import EmpanelmentCell from '../shared/EmpanelmentCell';

const testID = 'taskDetails';
const tableHeaders = ['Member', 'Status', 'Reason', 'Appointment', 'Due Date', 'Assignee', 'Empanelment'];

function TaskDetails({ memberURL, memberMrn, prescriptionTaskDetails }) {
  const { member, status, reason, appointmentOccurrence, empanelmentGroup, dueDate, assignee } =
    prescriptionTaskDetails ?? {};
  const memberName = `${member?.firstName} ${member?.lastName}`;
  const assigneeName = `${assignee?.firstName} ${assignee?.lastName}`;
  const { label: statusLabel, styles: statusStyles } = parseStatus(status);
  const days = daysOverdue(dueDate);
  const readyForReview = status === RX_STATUS.READY_FOR_REVIEW;

  return (
    <div className="flex flex-col gap-2.5">
      <h2 className="text-2xl font-bold font-body">Task Details</h2>
      <SimpleTable headers={tableHeaders}>
        <tr>
          <GeneralCell
            testID={`name-${testID}`}
            data={
              <a href={memberURL} target="_top" className="underline">
                {memberName}
              </a>
            }
            subText={memberMrn}
            classes="mb-2"
          />
          <GeneralCell
            testID={`status-${testID}`}
            classes="mt-2 mb-2"
            data={<span className={statusStyles}>{statusLabel}</span>}
          />
          <GeneralCell
            testID={`payer-${testID}`}
            classes="mt-2 mb-2"
            data={<span className={classNames({ 'italic text-gray-700': !reason })}>{reason ?? 'None provided'}</span>}
          />
          <AppointmentOccurrenceCell testID={testID} appointmentOccurrence={appointmentOccurrence} />
          <GeneralCell
            testID={`status-${testID}`}
            classes="mt-2 mb-2 flex flex-col"
            data={
              <>
                <span>{mdyyyy(dueDate)}</span>
                {readyForReview && !!days && <span className="text-error-700 text-sm font-semibold">{days}</span>}
              </>
            }
          />
          <GeneralCell testID={`status-${testID}`} classes="mt-2 mb-2" data={assigneeName} />
          <EmpanelmentCell empanelment={empanelmentGroup} testID={`empanelment-${testID}`} />
        </tr>
      </SimpleTable>
      <RxNote note={prescriptionTaskDetails?.note} testID={testID} />
    </div>
  );
}

TaskDetails.propTypes = {
  memberURL: PropTypes.string,
  memberMrn: PropTypes.string,
  prescriptionTaskDetails: PropTypes.schema({
    id: PropTypes.number.isRequired,
    note: PropTypes.string,
    dueDate: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    reason: PropTypes.string,
    assignee: PropTypes.schema({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
    chartingNotesIds: PropTypes.arrayOf(PropTypes.string),
    appointmentOccurrence: PropTypes.schema({
      id: PropTypes.number.isRequired,
      appointmentType: PropTypes.string.isRequired,
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        })
      ).isRequired,
      memberStatus: PropTypes.string,
      scheduledAt: PropTypes.string.isRequired,
      status: PropTypes.string,
    }),
    empanelmentGroup: PropTypes.schema({
      id: PropTypes.number.isRequired,
    }),
    member: PropTypes.schema({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

TaskDetails.defaultProps = {
  memberURL: '',
  memberMrn: '',
};

export default TaskDetails;
