import * as Yup from 'yup';

const addTaskValidationSchema = Yup.object()
  .shape({
    member: Yup.object().required('Member is required'),
    due_date: Yup.string()
      .required('Due date is required')
      .test('is-future', 'Due date must be in the future', (value) => {
        const now = new Date();
        now.setHours(0, 0, 0, 0);
        const dueDate = new Date(value);
        return dueDate >= now;
      }),
  })
  .required();

export default addTaskValidationSchema;
