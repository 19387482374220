import transform from 'lodash/transform';
import isObject from 'lodash/isObject';
import camelCase from 'lodash/camelCase';

export const areStringsEqual = (string1, string2, ignoreCase = true, trim = true) => {
  let string1ToCheck = string1;
  let string2ToCheck = string2;

  if (ignoreCase) {
    string1ToCheck = string1?.toLowerCase();
    string2ToCheck = string2?.toLowerCase();
  }

  if (trim) {
    string1ToCheck = string1ToCheck?.trim();
    string2ToCheck = string2ToCheck?.trim();
  }

  return string1ToCheck === string2ToCheck;
};

export const convertObjKeysToCamelCase = (obj, skipKeys = []) => {
  if (!obj) return obj;
  if (!isObject(obj)) return obj;

  return transform(obj, (result, value, key) => {
    if (!skipKeys.includes(key)) {
      if (Array.isArray(value)) {
        // eslint-disable-next-line no-param-reassign
        value = value.map((item) => convertObjKeysToCamelCase(item, skipKeys));
      } else if (isObject(value)) {
        // eslint-disable-next-line no-param-reassign
        value = convertObjKeysToCamelCase(value, skipKeys);
      }
    }

    if (skipKeys.includes(key)) {
      // eslint-disable-next-line no-param-reassign
      result[key] = value;
      return;
    }

    // eslint-disable-next-line no-param-reassign
    result[camelCase(key)] = value;
  });
};

export const onEnterKey = (cb) => (e) => e.key === 'Enter' && cb();

export const copyToClipboard = (text) => {
  if (!navigator.clipboard) {
    return Promise.reject(new Error('Clipboard API not available'));
  }

  // eslint-disable-next-line consistent-return
  return navigator.clipboard.writeText(text);
};

export const openLinkInNewTab = (link) => {
  if (!link) return;
  window.open(link, '_blank')?.focus();
};

export const navigateTopToLink = (link) => {
  if (!link) return;
  window.top.location.replace(link);
};
