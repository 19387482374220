import React from 'react';
import propTypes from 'prop-types';
import get from 'lodash/get';
import MemberInformation from '../../member_summary/MemberInformation';
import OneMonthStats from '../../member_summary/OneMonthStats';
import GroupInformation from '../../member_summary/GroupInformation';
import Notes from '../../member_summary/Notes';

function MemberOverview({ memberData }) {
  const { groupInformation, memberSummaries: { monthToDate, healthieProfile } = {} } = memberData || {};
  const notes = get(healthieProfile, 'componentData.note', '');
  const tags = get(healthieProfile, 'componentData.tags', []);

  return (
    <div className="bg-white mb-7 flex flex-col gap-11">
      <div className="flex flex-col md:flex-row justify-between gap-10">
        <div className="flex-1">
          <MemberInformation member={memberData} statuses={tags} />
        </div>
        {monthToDate && (
          <div className="flex-1">
            <OneMonthStats monthData={monthToDate.componentData} />
          </div>
        )}
      </div>
      <div className="flex flex-col md:flex-row justify-between gap-10">
        {groupInformation && (
          <div className="flex-1">
            <GroupInformation {...groupInformation} />
          </div>
        )}
        <div className="flex-1 md:w-1/2">{notes && <Notes notes={notes} />}</div>
      </div>
    </div>
  );
}

MemberOverview.propTypes = {
  memberData: propTypes.object.isRequired,
};

export default MemberOverview;
