/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import { getUserTimeZoneAbbrev } from '../../../helpers/TimezoneHelper';

export const EVERY_STATE_TYPE_APPOINTMENT = 'Orientation Group';
export const CHARTING_NOTE_TYPE = 'charting_note';
export const APPOINTMENT_TYPE = 'appointment';
export const PRESCRIPTION_TYPE = 'prescription';
export const OUTBOUND_FORM_TYPE = 'outbound_form';

export const TABLE_HEADERS = [
  {
    name: 'Date',
    showColumn: true,
    isClickable: true,
    actionName: 'scheduledAt',
  },
  {
    name: `Time (${getUserTimeZoneAbbrev()})`,
    showColumn: true,
    isClickable: true,
    actionName: 'scheduledAtTime',
  },
  {
    name: 'Type',
    showColumn: true,
    isClickable: true,
    actionName: 'type',
  },
  {
    name: 'Status',
    showColumn: true,
    isClickable: true,
    actionName: 'status',
  },
  {
    name: 'Documentation',
    showColumn: true,
    isClickable: true,
    actionName: 'document',
  },
  {
    name: 'Staff',
    showColumn: true,
    isClickable: true,
    actionName: 'staff',
  },
  {
    name: 'Facility',
    showColumn: true,
    isClickable: true,
    actionName: 'location',
  },
  {
    name: 'Reason',
    showColumn: true,
    isClickable: true,
    actionName: 'reason',
  },
  {
    name: '',
    key: 'actions',
    showColumn: true,
    isClickable: false,
    actionColumn: true,
  },
];

export const FILTER_TYPES = {
  type: 'type',
  appointmentType: 'appointmentType',
  staff: 'staff',
  fromDate: 'fromDate',
  toDate: 'toDate',
  status: 'status',
  facility: 'facility',
  templates: 'templates',
  document: 'document',
  documentStatus: 'documentStatus',
};

export const DOCUMENT_STATUS = [
  { label: 'All', value: '*' },
  { label: 'Locked', value: 'locked' },
  { label: 'Unlocked', value: 'unlocked' },
];

export const APPOINTMENT_STATUS = [
  { label: 'All', value: '*' },
  { label: 'Occurred', value: 'occurred' },
  { label: 'Cancelled', value: 'cancelled' },
  { label: 'No Show', value: 'no-show' },
  { label: 'Re-scheduled', value: 're-scheduled' },
];

export const OUTBOUND_STATUS = [
  { label: 'All', value: '*' },
  { label: 'Pending', value: 'pending' },
  { label: 'Open', value: 'open' },
  { label: 'Finished', value: 'finished' },
  { label: 'Cancelled', value: 'cancelled' },
  { label: 'Handwritten', value: 'handwritten' },
];

export const DOCUMENT_FILTER_KEY = {
  label: 'Document',
  value: CHARTING_NOTE_TYPE,
};

export const APPOINTMENT_FILTER_KEY = {
  label: 'Appointment',
  value: APPOINTMENT_TYPE,
};

export const PRESCRIPTION_FILTER_KEY = {
  label: 'Prescription',
  value: PRESCRIPTION_TYPE,
};

export const OUTBOUND_FILTER_KEY = {
  label: 'Forms',
  value: OUTBOUND_FORM_TYPE,
};

export const ENCOUNTERS_TYPES = [
  DOCUMENT_FILTER_KEY,
  APPOINTMENT_FILTER_KEY,
  PRESCRIPTION_FILTER_KEY,
  OUTBOUND_FILTER_KEY,
];

export const DEFAULT_FILTERS = {
  [FILTER_TYPES.type]: [],
  [FILTER_TYPES.appointmentType]: [],
  [FILTER_TYPES.staff]: [],
  [FILTER_TYPES.fromDate]: moment().subtract(4, 'weeks'),
  [FILTER_TYPES.toDate]: moment().add(1, 'weeks'),
  [FILTER_TYPES.status]: [],
  [FILTER_TYPES.facility]: [],
  [FILTER_TYPES.templates]: [],
  [FILTER_TYPES.document]: [],
  [FILTER_TYPES.documentStatus]: [],
};

export const PAGES = {
  TIMELINE: 'timeline',
  ADD_DOCUMENTATION: 'add_documentation',
  ADD_GROUP_DOCUMENTATION: 'add_group_documentation',
};

export const DEFAULT_PAGE = PAGES.TIMELINE;
