/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import { components } from 'react-select';
import { LuChevronsUpDown } from 'react-icons/lu';
import { IoClose } from 'react-icons/io5';

export const OTHER_OPTION_VALUE = 'custom-address-enabled';

export const selectStyles = (error, disabled) => ({
  control: (baseStyles, { isFocused }) => ({
    ...baseStyles,
    border: classNames('2px solid', {
      'var(--color-error-700)': error,
      'var(--color-gray-300)': !isFocused && !error,
      'var(--color-teal-700)': isFocused && !error,
    }),
    borderRadius: '0.5rem',
    boxShadow: 'none',
    '&:hover': {
      border: '2px solid var(--color-teal-700)',
    },
    backgroundColor: classNames({
      'var(--color-gray-200)': disabled,
      white: !disabled,
    }),
    outline: classNames({
      '1px solid var(--color-teal-700)': isFocused,
    }),
    outlineOffset: classNames({
      '2px': isFocused,
    }),
  }),
  menuPortal: (baseStyles) => ({
    ...baseStyles,
    color: 'black',
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    padding: '0.625rem',
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    margin: '0',
    padding: '0',
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    margin: '0',
    padding: '0',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    zIndex: 20,
  }),
  option: (baseStyles, { isFocused, isSelected, isDisabled }) => ({
    ...baseStyles,
    ...((!isDisabled || isSelected) && {
      color: isFocused || isSelected ? 'white' : 'black',
    }),
  }),
});

export const theme = (parentTheme) => ({
  ...parentTheme,
  colors: {
    ...parentTheme.colors,
    primary: 'var(--color-teal-700)',
    primary25: 'var(--color-teal-700)',
    primary50: 'var(--color-teal-700)',
    primary75: 'var(--color-teal-700)',
  },
});

export function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <LuChevronsUpDown />
    </components.DropdownIndicator>
  );
}

export function ClearIndicator(props) {
  return (
    <components.ClearIndicator {...props}>
      <IoClose />
    </components.ClearIndicator>
  );
}

export function Option(props) {
  return (
    <>
      {props.data.value === OTHER_OPTION_VALUE && <hr className="my-2 bg-gray-300 h-0.5 border-none" />}
      <components.Option {...props}>{props.children}</components.Option>
    </>
  );
}
