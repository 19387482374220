import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const TimelineURLsContext = createContext({
  appointmentFinderUrl: '',
  encounterHistoryUrl: '',
  prescriptionDetailsUrl: '',
});

export function TimelineURLsController({
  appointmentFinderUrl,
  encounterHistoryUrl,
  prescriptionDetailsUrl,
  children,
}) {
  const memoizedValue = useMemo(
    () => ({ appointmentFinderUrl, encounterHistoryUrl, prescriptionDetailsUrl }),
    [appointmentFinderUrl, encounterHistoryUrl, prescriptionDetailsUrl]
  );

  return <TimelineURLsContext.Provider value={memoizedValue}>{children}</TimelineURLsContext.Provider>;
}

TimelineURLsController.propTypes = {
  appointmentFinderUrl: PropTypes.string.isRequired,
  encounterHistoryUrl: PropTypes.string.isRequired,
  prescriptionDetailsUrl: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export const useTimelineURLs = () => useContext(TimelineURLsContext);
