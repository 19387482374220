import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FaArrowsLeftRight } from 'react-icons/fa6';
import { FormAnswerPropType, MemberPropType } from './helpers/types';
import { onEnterKey } from '../../helpers/utils';
import AnswerPill from './AnswerPill';
import { getAnswerPillVariant } from './helpers/healthieFormHelpers';
import NoteReadModeAnswer from './note_read_mode_view/NoteReadModeAnswer';

const IS_DELETED = 'isDeleted';

const MembersNotesRow = forwardRef(
  ({ formAnswer, members, onRowClick, onApplyAnswerToAll, lockedNoteIds, memberMrnsToValidate }, ref) => {
    const formAnswerLabels = Object.values(formAnswer)?.[0];
    const isAllLocked = lockedNoteIds?.length === members?.length;

    const getVariant = (answer) => {
      if (!answer) return IS_DELETED;

      return getAnswerPillVariant(answer.extensions, answer.isEdited);
    };

    return (
      <tr ref={ref} className="divide-x divide-gray-300">
        <td className="bg-white p-4 flex gap-1 font-semibold sticky left-0 h-full">
          {formAnswerLabels?.customModule?.required && !isAllLocked && <span className="text-error-700">*</span>}
          <span>{formAnswerLabels?.label}</span>
        </td>
        {members?.map((member) => {
          const memberNoteAnswer = formAnswer?.[member?.healthieMrn];
          const answer = memberNoteAnswer?.answer || '-';
          const isLocked = lockedNoteIds?.includes(memberNoteAnswer?.formId);

          return (
            <td
              data-testid="members-notes-row-answer"
              key={member?.id}
              className={classNames('p-4', {
                'bg-blue-100': !memberNoteAnswer?.isEdited || isLocked,
                'bg-white': memberNoteAnswer?.isEdited && !isLocked,
                'bg-error-100 text-error-900':
                  memberMrnsToValidate?.includes(member.healthieMrn) && memberNoteAnswer?.isValidationError,
              })}
            >
              {memberNoteAnswer?.isVisible ? (
                <div data-testid="members-notes-row-answer-content" className="flex items-center justify-between">
                  <div
                    className="flex gap-1 items-center flex-1"
                    onClick={memberNoteAnswer && (() => onRowClick(member.healthieMrn, memberNoteAnswer?.id))}
                    onKeyDown={
                      memberNoteAnswer && onEnterKey(() => onRowClick(member.healthieMrn, memberNoteAnswer?.id))
                    }
                    role="button"
                    tabIndex="0"
                  >
                    {!isLocked && <AnswerPill variant={getVariant(memberNoteAnswer)} isLabelVisible={false} />}
                    <span className="overflow-hidden overflow-ellipsis" title={memberNoteAnswer?.answer}>
                      <NoteReadModeAnswer answer={answer} type={memberNoteAnswer?.customModule?.modType} />
                    </span>
                  </div>
                  {!isLocked && (
                    <button
                      type="button"
                      className="border-2 border-teal-700 rounded-full p-0.5 w-5 h-5 flex items-center justify-center"
                      onClick={() =>
                        onApplyAnswerToAll({ [memberNoteAnswer?.customModule?.id]: answer }, memberNoteAnswer?.formId)
                      }
                    >
                      <FaArrowsLeftRight className="text-teal-700" />
                    </button>
                  )}
                </div>
              ) : null}
            </td>
          );
        })}
      </tr>
    );
  }
);

MembersNotesRow.displayName = 'MembersNotesRow';

MembersNotesRow.propTypes = {
  formAnswer: FormAnswerPropType.isRequired,
  members: PropTypes.arrayOf(MemberPropType),
  onRowClick: PropTypes.func.isRequired,
  onApplyAnswerToAll: PropTypes.func.isRequired,
  lockedNoteIds: PropTypes.arrayOf(PropTypes.string),
  memberMrnsToValidate: PropTypes.arrayOf(PropTypes.string),
};

MembersNotesRow.defaultProps = {
  members: [],
  lockedNoteIds: [],
  memberMrnsToValidate: [],
};

export default MembersNotesRow;
