import { object, string, array } from 'yup';
import { camelCase } from 'lodash';

const DROPDOWN = 'dropdown';
const CHECKBOX = 'checkbox';
const IS_MULTI = 'is_multi';
const PROBLEM_LIST = 'problem_list';
const DROPDOWN_ITEM_SCHEMA = object({ value: string(), label: string() });
const REQUIRED_TEXT = 'This field is required';
const NULLABLE_DEFAULT_VALUES = [DROPDOWN, CHECKBOX];
const AVAILABLE_EXTENSIONS = ['default_value', 'is_last_charting_note', 'default_member_value'];
const ANSWER_MODIFIED = 'answerModified';

const getInitialSchema = (modType, extensions) => {
  const isMulti = extensions?.includes(IS_MULTI);

  if (modType === DROPDOWN) return isMulti ? array(DROPDOWN_ITEM_SCHEMA).nullable() : DROPDOWN_ITEM_SCHEMA.nullable();

  if (modType === CHECKBOX) return array(string()).nullable();

  return string();
};

const getConditionalInputValue = (inputValue) => {
  if (Array.isArray(inputValue)) {
    return inputValue.map((item) => item.value || item);
  }

  return inputValue.value || inputValue;
};

export const isConditionalQuestionVisible = (answer, inputValue) => {
  if (!inputValue) return false;

  const conditionalInputValue = getConditionalInputValue(inputValue);
  const isInputValueArray = Array.isArray(conditionalInputValue);
  const isEqualTo = isInputValueArray
    ? conditionalInputValue.includes(answer.valueToFilter)
    : conditionalInputValue === answer.valueToFilter;

  if (answer.filterType === 'includes' || answer.filterType === 'is_equal_to') return isEqualTo;

  if (answer.filterType === 'contains') {
    return isInputValueArray
      ? !!conditionalInputValue.find((item) => item.includes(answer.valueToFilter))
      : conditionalInputValue.includes(answer.valueToFilter);
  }

  if (answer.filterType === 'is_not_equal_to') return !isEqualTo;

  return false;
};

export const isConditionalQuestionVisibleChain = (formAnswer, formAnswers) => {
  if (!formAnswer.conditionalCustomModuleId) return true;

  const formAnswerToCompare = formAnswers.find(
    (answer) => answer?.customModule?.id === formAnswer.conditionalCustomModuleId
  );
  const isVisible = isConditionalQuestionVisible(formAnswer, formAnswerToCompare.answer);

  if (isVisible) return isConditionalQuestionVisibleChain(formAnswerToCompare, formAnswers);

  return isVisible;
};

const getSchemaConditionalInputNamesChain = (formAnswer, formAnswers) => {
  if (!formAnswer.conditionalCustomModuleId) return [];

  const formAnswerToCompare = formAnswers.find(
    (answer) => answer?.customModule?.id === formAnswer.conditionalCustomModuleId
  );

  if (formAnswerToCompare)
    return [
      formAnswer.conditionalCustomModuleId,
      ...getSchemaConditionalInputNamesChain(formAnswerToCompare, formAnswers),
    ];

  return [formAnswer.conditionalCustomModuleId];
};

const getSchemaFormAnswersChain = (inputNames, inputValues, formAnswers) => {
  const inputs = inputNames.reduce((prevValue, currentValue, i) => ({
    ...prevValue,
    [currentValue]: inputValues[i],
  }));

  return formAnswers.map((answer) =>
    inputValues[answer.customModule.id]
      ? {
          ...answer,
          answer: inputs[answer.customModule.id],
        }
      : answer
  );
};

export const getSchema = (formAnswer, formAnswers) => {
  const { conditionalCustomModuleId, customModule, extensions } = formAnswer;
  const initialSchema = getInitialSchema(customModule.modType, extensions);

  if (!conditionalCustomModuleId && customModule.required) {
    return initialSchema.required(REQUIRED_TEXT);
  }

  if (conditionalCustomModuleId && customModule.required) {
    const inputNames = getSchemaConditionalInputNamesChain(formAnswer, formAnswers);
    return initialSchema.when(inputNames, {
      is: (...values) => {
        const formAnswersChain = getSchemaFormAnswersChain(inputNames, values, formAnswers);
        return isConditionalQuestionVisibleChain(formAnswer, formAnswersChain);
      },
      then: (schema) => schema.required(REQUIRED_TEXT),
    });
  }

  return initialSchema;
};

const getDropdownAnswer = (answer, extensions, options) => {
  const answerArray = answer.split('\n');

  if (extensions?.includes(PROBLEM_LIST)) {
    return answerArray.map((item) => {
      const problemOption = options.find((option) => `${option.tag} ${option.code} ${option.description}` === item);

      return {
        label: problemOption ? `${problemOption.tag} ${problemOption.code}` : item,
        value: item,
      };
    });
  }

  return answerArray.map((item) => ({
    label: item,
    value: item,
  }));
};

export const getDefaultAnswer = ({ answer, customModule, extensions }) => {
  const isDropdown = customModule.modType === DROPDOWN;
  const isMulti = extensions?.includes(IS_MULTI);

  if (!answer) {
    if (isDropdown && isMulti) return [];

    return NULLABLE_DEFAULT_VALUES.includes(customModule.modType) ? null : '';
  }

  if (isDropdown) {
    const newAnswer = getDropdownAnswer(answer, extensions, customModule.optionsArray);

    if (!isMulti) return newAnswer[0];

    return newAnswer;
  }

  if (customModule.modType === CHECKBOX) {
    return answer.split('\n').filter((item) => item);
  }

  return answer;
};

export const getUpdatedAnswer = (answer) => {
  if (answer?.value) return answer.value;

  if (Array.isArray(answer)) return answer.map((item) => item.value || item).join('\n');

  return answer || '';
};

export const getAnswerPillVariant = (extensions, isEdited) => {
  if (!extensions?.length) return null;

  const variant = camelCase(AVAILABLE_EXTENSIONS.find((extension) => extensions.includes(extension)));

  if (variant && isEdited) return ANSWER_MODIFIED;

  return variant;
};

export const isProblemList = (noteAnswer) => noteAnswer?.extensions?.includes(PROBLEM_LIST);

export const isMulti = (noteAnswer) => noteAnswer?.extensions?.includes(IS_MULTI);
