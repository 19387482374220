import React from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import moment from 'moment-timezone';
import { getSupportedTimeZone } from '../../helpers/TimezoneHelper';
import useToasts from '../shared/hooks/use-toasts';
import useHttp from '../shared/hooks/use-http';
import GroupsModal from '../GroupsModal';
import Input from '../shared/Input';
import DateInput from '../shared/DateInput';
import Toasts from '../shared/Toasts';

const FORM_ID = 'manual-upload-care-plan-form';
const REQUIRED_TEXT = 'This field is required';

function ManualUploadModalDP2({ isOpen, onClose, onSuccess, member, carePlanId, authenticityToken }) {
  const { sendRequest, isLoading } = useHttp();
  const { toasts, addToast, removeToast } = useToasts();

  const schema = object({
    carePlanFile: string().required(REQUIRED_TEXT),
    memberSignedAt: string().required(REQUIRED_TEXT),
  });

  const { handleSubmit, control, reset } = useForm({
    values: {
      carePlanFile: '',
      memberSignedAt: '',
    },
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = async (data, e) => {
    const carePlanFile = e.target.carePlanFile.files[0];
    const memberSignedAt = moment.tz(data.memberSignedAt, getSupportedTimeZone());

    const formData = new FormData();
    formData.append('care_plan_pdf', carePlanFile);
    formData.append('member_signed_date', memberSignedAt);

    try {
      await sendRequest(`/staff/members/${member.id}/care_plans/${carePlanId}/manual_upload`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': authenticityToken,
        },
        data: formData,
      });

      onSuccess(memberSignedAt);
    } catch (error) {
      addToast({
        header: 'Something went wrong',
        message: 'Care plan upload did not work. Please try again. If the issue continues, please contact IT',
        type: 'error',
      });
      window.Sentry.captureException(error);
    }
  };

  return (
    <GroupsModal
      isOpen={isOpen}
      header="Upload Care Plan"
      footerButtons={[
        {
          label: 'Back',
          isSecondary: true,
          onClick: handleClose,
        },
        {
          label: 'Save',
          isPrimary: true,
          isLoading,
          form: FORM_ID,
          type: 'submit',
        },
      ]}
      size="medium"
      testID="manual-upload-modal"
    >
      <Toasts toasts={toasts} removeToast={removeToast} />
      <div>
        <p className="mb-2">Care Plan must be completed and signed by member and staff.</p>
        <p className="mb-2">
          <span className="text-error-700">*</span>
          Required field
        </p>
        <div className="bg-white rounded-lg shadow-sm">
          <form id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
            <div className="py-6 px-4 border-b-2">
              <Controller
                control={control}
                name="carePlanFile"
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    label="Select PDF"
                    type="file"
                    accept="application/pdf"
                    required
                    error={error?.message}
                  />
                )}
              />
            </div>
            <div className="flex gap-6 py-6 px-4 border-b">
              <div className="flex-1">
                <p className="font-semibold mb-0">Member</p>
                <p data-testid="lock-and-sign-template-name" className="mt-2 mb-0">
                  {member.name}
                </p>
              </div>
              <div className="flex-1">
                <Controller
                  control={control}
                  name="memberSignedAt"
                  render={({ field, fieldState: { error } }) => (
                    <DateInput
                      {...field}
                      label="Date of Member Signature"
                      required
                      error={error?.message}
                      isSuffixIconVisible
                    />
                  )}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </GroupsModal>
  );
}

ManualUploadModalDP2.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  member: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  carePlanId: PropTypes.number.isRequired,
  authenticityToken: PropTypes.string.isRequired,
};

export default ManualUploadModalDP2;
