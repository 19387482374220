import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CircularProgress } from '@mui/material';
import { PiLockSimple } from 'react-icons/pi';
import Accordion from '../../shared/Accordion';
import HealthieForm from '../../documentation/HealthieForm';
import MemberSummary from '../member_summary/Main';
import useHttp from '../../shared/hooks/use-http';
import Button from '../../shared/Button';
import DeleteNote from '../../documentation/DeleteNote';
import LockAndSign from './LockAndSign';
import { getDateTime, prepareNoteReadModeProps } from './helpers';
import NoteReadMode from '../../documentation/note_read_mode_view/NoteReadMode';
import useChartingNote from './useChartingNote';
import ChartingNotesTemplateSelector from '../../documentation/ChartingNotesTemplateSelector';
import { convertObjKeysToCamelCase } from '../../../helpers/utils';
import ChartingNotesHeader from '../../documentation/ChartingNotesHeader';
import AddAddendum from './AddAddendum';
import CoSign from './CoSign';
import useAddDocumentation from './useAddDocumentation';
import { SmartPhrasesPropType } from '../../documentation/helpers/types';

function ChartingNotes({
  toggleFullWidth,
  isFullWidth,
  member,
  authenticityToken,
  selectedDocumentationIds,
  prescriptionTaskId,
  appointmentOccurrenceId,
  states,
  onSelectedNotesChanged,
  isTemplateSelectorDisabled,
  hideActions,
  smartPhrases,
}) {
  const { id: memberId, firstName, lastName, mrn } = member || {};

  const {
    selectedNotes,
    setSelectedNotes,
    isCoSignModalOpen,
    setIsCoSignModalOpen,
    isAddAddendumModalOpen,
    setIsAddAddendumModalOpen,
    handleChartingNoteCoSign,
    handleChartingNoteCoSignClick,
    handleAddAddendumClick,
    handleChartingNoteAddAddendum,
    handleChartingNoteLockAndSign,
    handleChartingNoteDelete,
    lockedAndSignedChartingNotes,
    hasPermissionToEditSomeChartingNotes,
  } = useAddDocumentation({
    onSelectedNotesChanged,
  });

  const [isNoteUpdating, setIsNoteUpdating] = useState(false);
  const [isLockAndSignModalOpen, setIsLockAndSignModalOpen] = useState(false);
  const [currentNote, setCurrentNote] = useState();
  const { chartingNotes, isLoading: isChartingNoteLoading } = useChartingNote(selectedDocumentationIds);
  const { isLoading: isCreateChartingNoteLoading, sendRequest: createChartingNote } = useHttp();
  const { isLoading: isUpdateChartingNoteLoading, sendRequest: updateChartingNote } = useHttp();
  const memberIds = useMemo(() => [memberId], [memberId]);

  useEffect(() => {
    if (chartingNotes) {
      setSelectedNotes(convertObjKeysToCamelCase(chartingNotes));
    }
  }, [chartingNotes, setSelectedNotes]);

  const latestNoteDate =
    selectedNotes.length && getDateTime(Math.max(...selectedNotes.map((note) => new Date(note.updatedAt))));

  const getSelectedNoteAnswer = useCallback(
    (noteId, answerId) =>
      selectedNotes
        .find((selectedNote) => selectedNote.id === noteId)
        ?.formAnswers.find((selectedNoteAnswer) => selectedNoteAnswer.id === answerId),
    [selectedNotes]
  );

  const getUpdatedNoteAnswersWithExtensions = useCallback(
    (updatedNote) =>
      updatedNote?.formAnswers?.map((answer) => {
        const selectedNoteAnswer = getSelectedNoteAnswer(updatedNote.id, answer.id);

        return {
          ...answer,
          customModule: selectedNoteAnswer?.customModule,
          extensions: selectedNoteAnswer?.extensions,
          isEdited: selectedNoteAnswer?.isEdited || selectedNoteAnswer?.answer !== answer.answer,
          lastChartingNoteCreatedAt: selectedNoteAnswer?.lastChartingNoteCreatedAt,
        };
      }),
    [getSelectedNoteAnswer]
  );

  const handleTemplateChange = useCallback(
    async (response) => {
      const note = convertObjKeysToCamelCase(response[0].data?.createFormAnswerGroup?.form_answer_group);
      setSelectedNotes((prevState) => [note, ...prevState]);
    },
    [setSelectedNotes]
  );

  const handleHealthieFormUpdate = useCallback(
    async (noteId, data) => {
      const noteAnswers = selectedNotes.find((note) => note.id === noteId)?.formAnswers;
      const newNoteAnswers = noteAnswers.map(({ customModule, id, label }) => ({
        answer: data[customModule.id],
        custom_module_id: customModule.id,
        id,
        label,
      }));

      const response = await updateChartingNote('/staff/documentation/charting_notes', {
        method: 'PUT',
        headers: {
          'X-CSRF-Token': authenticityToken,
        },
        data: {
          charting_note_ids: [noteId],
          form_answers: [newNoteAnswers],
        },
      });
      const newNote = convertObjKeysToCamelCase(response?.[0]?.data?.updateFormAnswerGroup?.formAnswerGroup);
      const updatedNote = {
        ...newNote,
        formAnswers: getUpdatedNoteAnswersWithExtensions(newNote),
      };
      setSelectedNotes((prevState) => prevState.map((note) => (note.id === updatedNote.id ? updatedNote : note)));
    },
    [selectedNotes, updateChartingNote, authenticityToken, getUpdatedNoteAnswersWithExtensions, setSelectedNotes]
  );

  const handleChartingNoteSubmit = (note) => {
    setCurrentNote(note);
    setIsLockAndSignModalOpen(true);
  };

  const getAccordionActions = (noteId, name) => {
    const disabled = isNoteUpdating || isUpdateChartingNoteLoading;

    return (
      <>
        <DeleteNote
          authenticityToken={authenticityToken}
          noteIds={[noteId]}
          onSuccess={handleChartingNoteDelete}
          disabled={disabled}
          templateName={name}
        />
        <Button isPrimary isPrimaryMarginBottom={false} type="submit" form={noteId} disabled={disabled}>
          Lock & Sign
        </Button>
      </>
    );
  };

  return (
    <div className={classNames('bg-white pb-4 mt-4', { 'sticky top-0 w-6/12': !isFullWidth, 'w-full': isFullWidth })}>
      {currentNote && (
        <LockAndSign
          authenticityToken={authenticityToken}
          chartingNoteIds={[currentNote.id]}
          members={[
            {
              id: memberId,
              firstName,
              lastName,
              mrn,
            },
          ]}
          templateName={currentNote.name}
          onSuccess={handleChartingNoteLockAndSign}
          isModalOpen={isLockAndSignModalOpen}
          closeModal={() => setIsLockAndSignModalOpen(false)}
        />
      )}
      <AddAddendum
        authenticityToken={authenticityToken}
        onSuccess={handleChartingNoteAddAddendum}
        isModalOpen={isAddAddendumModalOpen}
        closeModal={() => setIsAddAddendumModalOpen(false)}
        chartingNotes={lockedAndSignedChartingNotes}
        members={[
          {
            value: memberId,
            label: `${firstName} ${lastName}`,
          },
        ]}
      />
      <CoSign
        authenticityToken={authenticityToken}
        onSuccess={handleChartingNoteCoSign}
        isModalOpen={isCoSignModalOpen}
        closeModal={() => setIsCoSignModalOpen(false)}
        chartingNotes={lockedAndSignedChartingNotes}
      />
      <ChartingNotesHeader
        latestNoteDate={latestNoteDate}
        onAddAddendumClick={handleAddAddendumClick}
        onCoSignClick={handleChartingNoteCoSignClick}
        disabled={!lockedAndSignedChartingNotes.length}
        isFullWidth={isFullWidth}
        toggleFullWidth={toggleFullWidth}
        hideActionButtons={!hasPermissionToEditSomeChartingNotes || hideActions}
      />
      <hr />
      <div className="mt-4">
        <div>
          <ChartingNotesTemplateSelector
            authenticityToken={authenticityToken}
            memberIds={memberIds}
            createChartingNote={createChartingNote}
            onTemplateChange={handleTemplateChange}
            prescriptionTaskId={prescriptionTaskId}
            isLoading={isCreateChartingNoteLoading}
            isDisabled={isTemplateSelectorDisabled || hideActions}
            appointmentOccurrenceId={appointmentOccurrenceId}
          />
          <div className="relative mt-6">
            {isCreateChartingNoteLoading && (
              <div className="absolute w-full h-full bg-white bg-opacity-50 no-data-loader">
                <CircularProgress />
              </div>
            )}
            {selectedNotes.length ? (
              hideActions ||
              selectedNotes.map((note) => (
                <Accordion
                  key={note.id}
                  title={note.name}
                  description={`Added by ${note.filler?.name} on ${getDateTime(note.createdAt)}`}
                  actions={
                    !note.hasPermissionToEdit || note.lockedAt ? (
                      <PiLockSimple className="text-lg text-gray-500" />
                    ) : (
                      getAccordionActions(note.id, note.name)
                    )
                  }
                >
                  {!note.hasPermissionToEdit || note.lockedAt ? (
                    <NoteReadMode note={prepareNoteReadModeProps(note)} isFullWidthSet={isFullWidth} />
                  ) : (
                    <HealthieForm
                      formId={note.id}
                      formAnswers={note.formAnswers}
                      onSubmit={() => handleChartingNoteSubmit({ id: note.id, name: note.name })}
                      onUpdate={handleHealthieFormUpdate}
                      setIsNoteUpdating={setIsNoteUpdating}
                      isVertical={!isFullWidth}
                      disabled={isUpdateChartingNoteLoading}
                      states={states}
                      smartPhrases={smartPhrases}
                    />
                  )}
                </Accordion>
              ))
            ) : (
              <div className="text-center border border-gray-400 shadow-md rounded-lg py-12">
                {selectedDocumentationIds && isChartingNoteLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <div className="font-semibold text-gray-700">No Templates Selected</div>
                    <div className="text-gray-700">Select templates above to get started</div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

ChartingNotes.propTypes = {
  toggleFullWidth: PropTypes.func.isRequired,
  isFullWidth: PropTypes.bool.isRequired,
  member: PropTypes.shape({
    ...MemberSummary.propTypes.member,
    episodeOfCares: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        created_at: PropTypes.string.isRequired,
        updated_at: PropTypes.string.isRequired,
        start_date: PropTypes.string.isRequired,
        end_date: PropTypes.string.isRequired,
        discharge_reason: PropTypes.string,
        member_id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  authenticityToken: PropTypes.string.isRequired,
  selectedDocumentationIds: PropTypes.arrayOf(PropTypes.string),
  onSelectedNotesChanged: PropTypes.func,
  prescriptionTaskId: PropTypes.number,
  states: PropTypes.objectOf(PropTypes.string),
  isTemplateSelectorDisabled: PropTypes.bool,
  appointmentOccurrenceId: PropTypes.number,
  hideActions: PropTypes.bool,
  smartPhrases: SmartPhrasesPropType,
};

ChartingNotes.defaultProps = {
  selectedDocumentationIds: [],
  prescriptionTaskId: null,
  appointmentOccurrenceId: null,
  states: null,
  onSelectedNotesChanged: null,
  isTemplateSelectorDisabled: false,
  hideActions: false,
  smartPhrases: null,
};

export default ChartingNotes;
