import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import difference from 'lodash/difference';
import { LuUnlock, LuLock } from 'react-icons/lu';
import { PiMinusSquareBold as HideIcon, PiPlusSquareBold as ExpandIcon } from 'react-icons/pi';
import classNames from 'classnames';
import Button from '../shared/Button';
import { convertToSupportedTimeZone } from '../../helpers/TimezoneHelper';
import { MemberNotesFormAnswerGroupPropType, MemberPropType } from './helpers/types';
import { onEnterKey } from '../../helpers/utils';
import MembersNotesRow from './MembersNotesRow';
import DeleteNote from './DeleteNote';
import LockAndSign from '../empanelment/member_encounter_history/LockAndSign';
import MembersSignersAddendumsRow from './MembersSignersAddendumsRow';
import { isConditionalQuestionVisibleChain } from './helpers/healthieFormHelpers';
import { DEFAULT_DATETIME_FORMAT } from '../../helpers/DateFormatter';

const isAnyFormAnswerPresentByKey = (formAnswer, key) =>
  formAnswer && !!Object.values(formAnswer).find((answer) => answer?.[key]);

const isAnyFormAnswerVisible = (formAnswer) => isAnyFormAnswerPresentByKey(formAnswer, 'isVisible');

const isAnyFormAnswerValidationError = (formAnswer) => isAnyFormAnswerPresentByKey(formAnswer, 'isValidationError');

function MembersNotesGroup({
  formAnswerGroup,
  members,
  onRowClick,
  authenticityToken,
  onChartingNotesDelete,
  onChartingNoteLockAndSign,
  onApplyAnswerToAll,
}) {
  const noteIds = formAnswerGroup?.ids && Object.values(formAnswerGroup.ids);
  const allLockedAndSigned = noteIds?.length === formAnswerGroup?.lockedNoteIds?.length;

  const [isExpanded, setIsExpanded] = useState(true);
  const [isLockAndSignModalOpen, setIsLockAndSignModalOpen] = useState(false);
  const [lockAndSignProps, setLockAndSignProps] = useState({
    members,
    chartingNoteIds: noteIds,
  });
  const [memberMrnsToValidate, setMemberMrnsToValidate] = useState([]);

  const handleLockAndSign = ({ chartingNoteIds, lockedAndSignedBy, lockedAt }) => {
    if (onChartingNoteLockAndSign)
      onChartingNoteLockAndSign({
        lockedAt,
        lockedBy: lockedAndSignedBy,
        lockedNoteIds: chartingNoteIds,
      });
  };

  const getMemberFormAnswers = (memberHealthieMrn) =>
    formAnswerGroup?.formAnswers?.map((formAnswer) => formAnswer[memberHealthieMrn]);

  const isQuestionVisible = (memberHealthieMrn, formAnswer) => {
    const memberFormAnswers = getMemberFormAnswers(memberHealthieMrn);
    return isConditionalQuestionVisibleChain(formAnswer, memberFormAnswers);
  };

  const isQuestionValidationError = (memberHealthieMrn, formAnswer) =>
    formAnswer.customModule.required && !formAnswer.answer && isQuestionVisible(memberHealthieMrn, formAnswer);

  const validatedFormAnswers = formAnswerGroup?.formAnswers?.map((formAnswer) => ({
    answers: Object.entries(formAnswer).reduce(
      (prevFormAnswer, [memberHealthieMrn, memberFormAnswer]) => ({
        ...prevFormAnswer,
        [memberHealthieMrn]: {
          ...memberFormAnswer,
          isValidationError: isQuestionValidationError(memberHealthieMrn, memberFormAnswer),
          isVisible: isQuestionVisible(memberHealthieMrn, memberFormAnswer),
        },
      }),
      {}
    ),
    ref: createRef(),
  }));

  const onLockAndSignClick = ({ healthieMrn }) => {
    const shouldValidateQuestion = validatedFormAnswers.find((formAnswer) =>
      healthieMrn
        ? formAnswer?.answers?.[healthieMrn]?.isValidationError
        : isAnyFormAnswerValidationError(formAnswer?.answers)
    );

    if (shouldValidateQuestion) {
      setMemberMrnsToValidate(healthieMrn ? [healthieMrn] : Object.keys(formAnswerGroup.ids));
      shouldValidateQuestion.ref?.current?.scrollIntoView();
      return;
    }

    if (healthieMrn) {
      setLockAndSignProps({
        members: members.filter((member) => member.healthieMrn === healthieMrn),
        chartingNoteIds: [formAnswerGroup?.ids[healthieMrn]],
      });
    } else {
      const lockedNoteIds = formAnswerGroup?.lockedNoteIds;
      const chartingNoteIds = lockedNoteIds?.length ? difference(noteIds, lockedNoteIds) : noteIds;
      const filteredMembers = members.filter((member) => {
        const chartingNoteAssignedToMember = formAnswerGroup?.ids[member.healthieMrn];
        return chartingNoteIds.includes(chartingNoteAssignedToMember);
      });

      setLockAndSignProps({
        members: filteredMembers,
        chartingNoteIds,
      });
    }
    setIsLockAndSignModalOpen(true);
  };

  let formattedDate = '';
  if (formAnswerGroup?.createdAt) {
    const [dateTimeWithOffset, supportedTimeZone] = convertToSupportedTimeZone(formAnswerGroup.createdAt);
    formattedDate = ` on ${dateTimeWithOffset.format(DEFAULT_DATETIME_FORMAT)} ${supportedTimeZone.abbrev}`;
  }

  const noteNameCell = (
    <td className="p-5 border border-gray-300 gap-2 sticky left-0 bg-gray-100 sm:w-420px 2xl:w-560px">
      <div className="flex justify-between items-center gap-5">
        <div className="flex flex-col gap-1">
          <span className="text-lg font-semibold">{formAnswerGroup?.name}</span>
          <span className="text-xs">{`Added by ${formAnswerGroup?.filler?.name}${formattedDate}`}</span>
        </div>
        <div className="flex gap-2">
          {formAnswerGroup?.hasPermissionToEdit && (
            <>
              <DeleteNote
                authenticityToken={authenticityToken}
                noteIds={noteIds}
                onSuccess={onChartingNotesDelete}
                templateName={formAnswerGroup?.name}
                disabled={allLockedAndSigned}
              />
              <Button isPrimary disabled={allLockedAndSigned} onClick={onLockAndSignClick}>
                Lock & Sign
              </Button>
            </>
          )}
          <button
            data-testid="members-notes-group-expand-icon"
            type="button"
            onClick={() => setIsExpanded((value) => !value)}
          >
            {isExpanded ? <HideIcon className="w-5 h-5" /> : <ExpandIcon className="w-5 h-5" />}
          </button>
        </div>
      </div>
    </td>
  );

  const noNotesCell = (
    <td
      className="px-5 py-28 border border-gray-300 gap-2 text-center bg-white sticky left-0 sm:w-420px 2xl:w-560px"
      rowSpan="2"
    >
      <div className="font-semibold text-gray-700">No Templates Selected</div>
      <div className="text-gray-700">Select templates above to get started</div>
    </td>
  );

  const noteHeaderCell = formAnswerGroup ? noteNameCell : noNotesCell;
  const visibleFormAnswers = validatedFormAnswers?.filter((formAnswer) => isAnyFormAnswerVisible(formAnswer.answers));

  return (
    <>
      <LockAndSign
        authenticityToken={authenticityToken}
        chartingNoteIds={lockAndSignProps.chartingNoteIds}
        members={lockAndSignProps.members}
        templateName={formAnswerGroup?.name || '-'}
        onSuccess={handleLockAndSign}
        isModalOpen={isLockAndSignModalOpen}
        closeModal={() => setIsLockAndSignModalOpen(false)}
      />
      {/* Heading rows (formAnswer name and members) */}
      <tr
        data-testid="members-notes-group-row"
        className={classNames({
          'sticky top-0 z-10': isExpanded,
        })}
      >
        {noteHeaderCell}
        {members.map(({ id, healthieMrn, mrn, firstName, lastName }) => {
          const chartingNoteAssignedToMember = formAnswerGroup?.ids[healthieMrn];
          const isLocked = formAnswerGroup?.lockedNoteIds?.includes(chartingNoteAssignedToMember) || false;

          const cellProps = {
            onClick: !isLocked ? () => onLockAndSignClick({ healthieMrn }) : undefined,
            onKeyDown: !isLocked ? onEnterKey(() => onLockAndSignClick({ healthieMrn })) : undefined,
            role: !isLocked ? 'button' : undefined,
            tabIndex: !isLocked ? '0' : undefined,
          };

          return (
            <td key={id} className="px-3 py-5 border border-gray-300 bg-gray-100 sm:w-220px 2xl:w-268px">
              <div className="flex gap-4 justify-between items-center" {...cellProps}>
                <div className="flex flex-col gap-2">
                  <span className="font-semibold text-teal-700">{`${firstName} ${lastName}`}</span>
                  {mrn && <span className="text-sm text-gray-700">{mrn}</span>}
                </div>
                {formAnswerGroup &&
                  (isLocked ? (
                    <LuLock className="text-gray-500 w-6 h-6" />
                  ) : (
                    <LuUnlock className="text-teal-700 w-6 h-6" />
                  ))}
              </div>
            </td>
          );
        })}
      </tr>
      {!formAnswerGroup && (
        <>
          <tr>
            {members.map((member) => (
              <td key={member?.id} className="border border-gray-300 bg-gray-100" style={{ height: '207px' }} />
            ))}
          </tr>
        </>
      )}

      {/* Answers row (formAnswer labels and members' answers) */}
      {isExpanded &&
        visibleFormAnswers?.map((formAnswer) => (
          <MembersNotesRow
            key={Object.values(formAnswer.answers)?.[0]?.id}
            formAnswer={formAnswer.answers}
            members={members}
            onRowClick={onRowClick}
            onApplyAnswerToAll={onApplyAnswerToAll}
            lockedNoteIds={formAnswerGroup?.lockedNoteIds}
            memberMrnsToValidate={memberMrnsToValidate}
            ref={formAnswer.ref}
          />
        ))}

      {isExpanded && formAnswerGroup && (
        <MembersSignersAddendumsRow formAnswerGroup={formAnswerGroup} members={members} />
      )}
    </>
  );
}

MembersNotesGroup.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  formAnswerGroup: MemberNotesFormAnswerGroupPropType,
  members: PropTypes.arrayOf(MemberPropType),
  onRowClick: PropTypes.func,
  onChartingNotesDelete: PropTypes.func,
  onChartingNoteLockAndSign: PropTypes.func,
  onApplyAnswerToAll: PropTypes.func,
};

MembersNotesGroup.defaultProps = {
  formAnswerGroup: null,
  members: [],
  onRowClick: null,
  onChartingNotesDelete: null,
  onChartingNoteLockAndSign: null,
  onApplyAnswerToAll: null,
};

export default MembersNotesGroup;
