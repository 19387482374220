import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Table from '../../../Table';
import Button from '../../../shared/Button';
import useSelect from '../../../shared/hooks/use-select';
import { AttendanceTableHeaders } from './constants';
import useAttendanceTracker from './useAttendanceTracker';
import { AttendanceTrackerController } from './useAttendanceTrackerContext';
import BulkStatusModal from './BulkStatusModal';
import { GroupAppointmentCamelCasePropTypes } from '../shared/types';

function AttendanceTab({ appointment, authenticityToken, addToast, onUpdate }) {
  const attendanceTracker = useAttendanceTracker({ appointment, authenticityToken, addToast });
  const { selected, setSelected, toggle: toggleMemberSelection } = useSelect();
  const getSelectedMembersCounter = () => {
    if (selected.length === 1) return '1 member selected';

    return `${selected.length} members selected`;
  };

  const [showBulkStatusEditModal, setShowBulkStatusEditModal] = useState(false);

  const allMembersSelected = selected.length === appointment.members?.length;
  const handleCheckboxChange = useCallback(
    (checked) => {
      setSelected(checked ? appointment.members.map((member) => member.id) : []);
    },
    [appointment, setSelected]
  );

  const onSaveButtonClick = async () => {
    try {
      await attendanceTracker.handleSave();
      onUpdate(attendanceTracker.formState);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col gap-5">
      <BulkStatusModal
        appointment={appointment}
        selectedMembersIds={selected}
        isOpen={showBulkStatusEditModal}
        onClose={() => setShowBulkStatusEditModal(false)}
        onSuccess={attendanceTracker.patchForm}
      />
      <div className="flex flex-wrap justify-between items-center">
        <span className="text-gray-600 text-base font-semibold">{getSelectedMembersCounter()}</span>
        <div className="flex gap-2.5">
          <Button isSecondary isLoading={attendanceTracker.isLoading} onClick={onSaveButtonClick}>
            Save changes
          </Button>
          <Button isSecondary onClick={() => setShowBulkStatusEditModal(true)} disabled={!selected?.length}>
            Set status
          </Button>
        </div>
      </div>
      <AttendanceTrackerController {...attendanceTracker}>
        <Table
          tableType="membersAttendanceGroupAppointmentTable"
          enableActionsColumn={false}
          enableCheckboxColumn
          onCheckboxChange={handleCheckboxChange}
          checkboxAllSelected={allMembersSelected}
          onRowCheckboxChange={toggleMemberSelection}
          selectedKeys={selected}
          tableRows={appointment.members}
          tableColumnHeaders={AttendanceTableHeaders}
          paginationVariant={null}
        />
      </AttendanceTrackerController>
    </div>
  );
}

AttendanceTab.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  addToast: PropTypes.func.isRequired,
  appointment: GroupAppointmentCamelCasePropTypes.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default AttendanceTab;
