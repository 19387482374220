import React from 'react';
import PropTypes from 'prop-types';
import LastSynced from './LastSynced';
import AttendanceWeek from './AttendanceWeek';
import { ColoredLabelPropTypes } from './ColoredLabel';

function AttendanceWeeks({ data, lastSyncedDate }) {
  return (
    <div className="flex flex-col gap-2">
      {!data?.length ? (
        <p className="mb-0">No data to display</p>
      ) : (
        data?.map(({ weekDate, appointments }) => (
          <AttendanceWeek key={weekDate} weekDate={weekDate} appointments={appointments} />
        ))
      )}
      {lastSyncedDate && <LastSynced dateTime={lastSyncedDate} />}
    </div>
  );
}

export const attendanceWeeksPropTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      weekDate: PropTypes.string.isRequired,
      appointments: PropTypes.arrayOf(
        PropTypes.shape({
          dateTime: PropTypes.string.isRequired,
          provider: PropTypes.string.isRequired,
          attendanceStatus: ColoredLabelPropTypes.isRequired,
          screenings: PropTypes.shape({
            uds: PropTypes.arrayOf(ColoredLabelPropTypes),
            fent: PropTypes.arrayOf(ColoredLabelPropTypes),
          }).isRequired,
        }).isRequired
      ).isRequired,
    })
  ),
  lastSyncedDate: PropTypes.string,
};

AttendanceWeeks.propTypes = attendanceWeeksPropTypes;

AttendanceWeeks.defaultProps = {
  lastSyncedDate: undefined,
  data: [],
};

export default AttendanceWeeks;
