import { useEffect, useState } from 'react';
import useHttp from '../../../shared/hooks/use-http';
import {
  ATTENDANCE_STATUSES,
  ATTENDED_ATTENDANCE_STATUS,
  DEFAULT_ATTENDANCE_STATUS,
  NO_SHOW_ATTENDANCE_STATUS,
  NO_SHOW_PREFIX,
  PENDING_ATTENDANCE_STATUS,
  RESCHEDULED_PREFIX,
  RESCHEDULED_ATTENDANCE_STATUS,
} from '../shared/constants';
import { filterOptionsByPrefix } from '../shared/helpers';

const useAttendanceTracker = ({ appointment, setAppointment, addToast, reasons, locations, authenticityToken }) => {
  const { sendRequest, isLoading } = useHttp();

  const attendance = appointment?.attendance;

  const reasonsMap = {
    [NO_SHOW_ATTENDANCE_STATUS.value]: filterOptionsByPrefix(reasons, NO_SHOW_PREFIX),
    [RESCHEDULED_ATTENDANCE_STATUS.value]: filterOptionsByPrefix(reasons, RESCHEDULED_PREFIX),
  };

  const [status, setStatus] = useState(() => {
    const foundStatus = ATTENDANCE_STATUSES.find(({ value }) => value === attendance?.status);
    if (!foundStatus || !attendance?.status) return DEFAULT_ATTENDANCE_STATUS;
    return foundStatus;
  });

  const [reason, setReason] = useState(() => {
    const foundReason = reasons.find((r) => r.value === attendance?.reasonAbsent);
    if (!foundReason || !attendance?.reasonAbsent) return null;
    return foundReason;
  });

  const [location, setLocation] = useState(() => {
    if (attendance?.location) {
      return locations.find((loc) => loc.value === attendance?.location?.id);
    }

    if (attendance?.customLocation) {
      const locationText = attendance?.customLocation;
      return {
        value: locationText,
        label: locationText,
        wasCreated: true,
      };
    }

    return null;
  });

  const isCurrentStatus = {
    Pending: status?.value === PENDING_ATTENDANCE_STATUS.value,
    Attended: status?.value === ATTENDED_ATTENDANCE_STATUS.value,
    NoShow: status?.value === NO_SHOW_ATTENDANCE_STATUS.value,
    Rescheduled: status?.value === RESCHEDULED_ATTENDANCE_STATUS.value,
  };

  const isReasonColumnVisible =
    [NO_SHOW_ATTENDANCE_STATUS.value, RESCHEDULED_ATTENDANCE_STATUS.value].includes(status?.value) &&
    appointment.isIntake;

  const isReasonRequired = isReasonColumnVisible;
  const isLocationAllowed = isCurrentStatus.Attended;

  const isSaveDisabled = isReasonRequired && !reason;

  const columns = [
    { label: 'Status', isRequired: false },
    { label: 'Reason', isHidden: !isReasonColumnVisible, isRequired: isReasonRequired },
    { label: 'Location', isRequired: false },
  ];

  const handleSave = async () => {
    if (isSaveDisabled) return;

    const attendanceData = {
      member_id: appointment?.member?.id,
      status: status?.value,
      reason_absent: isReasonRequired ? reason?.value : null,
      custom_location: isLocationAllowed && location?.wasCreated ? location?.value : null,
      location_id: isLocationAllowed && !location?.wasCreated ? location?.value : null,
    };

    try {
      await sendRequest(
        `/staff/appointment_occurrences/${appointment?.appointmentId}/update_appointment_occurrence_members`,
        {
          method: 'PATCH',
          headers: { common: { 'X-CSRF-Token': authenticityToken } },
          data: {
            appointment_occurrence_members: [attendanceData],
          },
        }
      );
      addToast({
        header: 'Attendance successfully updated',
        type: 'success',
      });
      setAppointment((prev) => ({
        ...prev,
        attendance: {
          ...prev.attendance,
          status: status?.value,
        },
      }));
    } catch (e) {
      addToast({
        header: 'Something went wrong',
        message: e?.parsedMessage ?? 'There was an error while updating the attendance. Please try again.',
        type: 'error',
      });
      window.Sentry?.captureException(e);
    }
  };

  const clearUnallowedReason = () => {
    const isUnallowedNoShow = isCurrentStatus.NoShow && reason?.label?.startsWith(RESCHEDULED_PREFIX);
    const isUnallowedReschedule = isCurrentStatus.Rescheduled && reason?.label?.startsWith(NO_SHOW_PREFIX);

    if (isUnallowedNoShow || isUnallowedReschedule) {
      setReason(null);
    }
  };

  useEffect(clearUnallowedReason, [isCurrentStatus.NoShow, isCurrentStatus.Rescheduled, reason?.label]);

  return {
    columns,
    status,
    setStatus,
    reason,
    setReason,
    reasonsMap,
    isReasonRequired,
    location,
    setLocation,
    isLocationAllowed,
    isReasonColumnVisible,
    handleSave,
    isSaveDisabled,
    isLoading,
  };
};

export default useAttendanceTracker;
