import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import Tooltip from './Tooltip';

function ButtonPopup({ children, buttonLabel, isDisabled, disabledMessage }) {
  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => setShowPopup((prev) => !prev);
  const buttonRef = useRef(null);

  useEffect(() => {
    const closePopup = (event) => {
      if (buttonRef.current && buttonRef.current.contains(event.target)) return;

      setShowPopup(false);
    };

    if (showPopup) {
      document.addEventListener('click', closePopup);
    }

    return () => {
      document.removeEventListener('click', closePopup);
    };
  }, [showPopup, setShowPopup]);

  const getTooltipWrapper = (content) => {
    if (isDisabled && disabledMessage) {
      return (
        <Tooltip text={disabledMessage} isSecondaryVariant>
          {content}
        </Tooltip>
      );
    }

    return content;
  };

  return getTooltipWrapper(
    <div className="relative w-min">
      <Button
        ref={buttonRef}
        onClick={togglePopup}
        isSelected={showPopup}
        isSecondary
        withArrowIcon
        isArrowUp={showPopup}
        disabled={isDisabled}
      >
        {buttonLabel}
      </Button>
      {showPopup && (
        <div className="absolute top-14 right-0 z-10 bg-white shadow-md px-5 py-7 rounded-2xl flex flex-col gap-6 text-gray-900 items-start">
          {children}
        </div>
      )}
    </div>
  );
}

ButtonPopup.propTypes = {
  buttonLabel: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
};

ButtonPopup.defaultProps = {
  isDisabled: false,
  disabledMessage: null,
};

export default ButtonPopup;
