/* eslint-disable react/no-unused-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

// ICONS
import CheckIcon from '@mui/icons-material/Check';
import ReassignIcon from '../shared/ReassignIcon';
import QuestionIcon from '../shared/QuestionIcon';
import UndoActiveIcon from '../shared/UndoActiveIcon';

// COMPONENTS
import TaskModal from './TaskModal';

function TaskTableActions(props) {
  const [modalType, setModalType] = useState('');
  const [headerText, setHeader] = useState('');
  const [statusInactive, setStatusInactive] = useState(props?.data?.status === 'INACTIVE');
  const [activeInactiveIconDisabled, setActiveInactiveIconDisabled] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen((value) => !value);

  useEffect(() => {
    setStatusInactive(props?.data?.status === 'INACTIVE');
  }, [props?.data?.status]);

  useEffect(() => {
    if (!props.dp2AlCheckmark) {
      return;
    }

    const tasksToDisableActiveInactiveButton = ['Assign Member to Home Group', 'Document UDS Results'];
    if (tasksToDisableActiveInactiveButton.includes(props?.data?.task_type)) {
      setActiveInactiveIconDisabled(true);
    }
  }, [props?.data?.task_type, props.dp2AlCheckmark]);

  function resolveTask(noteDetails = null) {
    axios.defaults.headers.common['X-CSRF-Token'] = props.authenticityToken;
    const params = { task_id: props?.data?.id, details: noteDetails };

    axios
      .put('/staff/daily_tasks/resolve_task', params)
      .then((response) => {
        props.onActionSubmit(response?.data);
      })
      .catch((error) => {
        window.Sentry.captureException(error);
      });
  }

  function unresolveTask(noteDetails = null) {
    axios.defaults.headers.common['X-CSRF-Token'] = props.authenticityToken;
    const params = { task_id: props?.data?.id, details: noteDetails };

    axios
      .put('/staff/daily_tasks/unresolve_task', params)
      .then((response) => {
        props.onActionSubmit(response?.data);
      })
      .catch((error) => {
        window.Sentry.captureException(error);
      });
  }

  function question(noteDetails = null) {
    axios.defaults.headers.common['X-CSRF-Token'] = props.authenticityToken;
    const params = {
      task_id: props?.data?.id,
      reassign_staff_id: props?.data?.recipient_id,
      details: noteDetails,
    };

    axios
      .put('/staff/daily_tasks/question', params)
      .then((response) => {
        props.onActionSubmit(response?.data, true);
      })
      .catch((error) => {
        window.Sentry.captureException(error);
      });
  }

  function reassign(noteDetails = null, selectedStaff = null) {
    axios.defaults.headers.common['X-CSRF-Token'] = props.authenticityToken;
    const params = {
      task_id: props?.data?.id,
      reassign_staff_id: selectedStaff?.value,
      details: noteDetails,
    };

    axios
      .put('/staff/daily_tasks/reassign', params)
      .then((response) => {
        props.onActionSubmit(response?.data, true);
      })
      .catch((error) => {
        window.Sentry.captureException(error);
      });
  }

  const submitRequest = (noteDetails, selectedStaff = null) => {
    if (modalType === 'RESOLVE') {
      resolveTask(noteDetails);
    }
    if (modalType === 'UNRESOLVE') {
      unresolveTask(noteDetails);
    }
    if (modalType === 'QUESTION') {
      question(noteDetails);
    }
    if (modalType === 'REASSIGN') {
      reassign(noteDetails, selectedStaff);
    }

    toggleOpen();
  };

  function prepareTaskModal(headerName, type) {
    setHeader(headerName);
    setModalType(type);

    toggleOpen();
  }

  return (
    <>
      <TaskModal
        isOpen={isOpen}
        data={props?.data}
        modalType={modalType}
        headerText={headerText}
        staff={props?.staff}
        onCancel={() => toggleOpen()}
        onContinue={submitRequest}
      />

      <div className="flex">
        {!props.data?.is_autoinactive &&
          (statusInactive ? (
            <UndoActiveIcon
              className="m-2 cursor-pointer"
              color={activeInactiveIconDisabled ? '#D1D1D1' : '#217886'}
              stroke={activeInactiveIconDisabled ? '#D1D1D1' : '#888888'}
              fill={activeInactiveIconDisabled ? '#D1D1D1' : '#237786'}
              style={{
                width: '30px',
                height: '30px',
                passedColor: activeInactiveIconDisabled ? '#D1D1D1' : '#217886',
                pointerEvents: activeInactiveIconDisabled ? 'none' : 'inherit',
              }}
              showHover
              onClick={() => {
                prepareTaskModal('Mark Task as Unresolved', 'UNRESOLVE');
              }}
            />
          ) : (
            <CheckIcon
              className="m-2 cursor-pointer"
              sx={{
                '&:hover': {
                  backgroundColor: '#E6F3F4 !important',
                  color: '#217886 !important',
                },
              }}
              style={{
                color: activeInactiveIconDisabled ? '#D1D1D1' : '#217886',
                pointerEvents: activeInactiveIconDisabled ? 'none' : 'inherit',
                border: activeInactiveIconDisabled ? '2px solid #D1D1D1' : '2px solid #217886',
                backgroundColor: 'white',
                borderRadius: '40px',
                padding: '3px',
                width: '30px',
                height: '30px',
              }}
              onClick={() => {
                prepareTaskModal('Resolve a Task', 'RESOLVE');
              }}
            />
          ))}

        <QuestionIcon
          className="m-2 cursor-pointer"
          color="#217886"
          style={{
            color: '#217886',
            borderRadius: '40px',
            border: '2px solid #217886',
            width: '30px',
            height: '30px',
          }}
          showHover
          onClick={() => {
            prepareTaskModal('Ask a Question', 'QUESTION');
          }}
        />

        <ReassignIcon
          className="m-2 cursor-pointer"
          color="#217886"
          style={{
            passedColor: '#217886',
            borderRadius: '40px',
            border: '2px solid #217886',
            width: '30px',
            height: '30px',
          }}
          showHover
          onClick={() => {
            prepareTaskModal('Reassign Task', 'REASSIGN');
          }}
        />
      </div>
    </>
  );
}

TaskTableActions.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  staff: PropTypes.array.isRequired,
  onActionSubmit: PropTypes.func.isRequired,
  dp2AlCheckmark: PropTypes.bool,
};

TaskTableActions.defaultProps = {
  dp2AlCheckmark: false,
};

export default TaskTableActions;
