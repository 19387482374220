import 'rc-time-picker/assets/index.css';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import Input from './shared/Input';
import TimeInput from './shared/TimeInput';
import SelectInput from './shared/SelectInput';
import schedulesFormValidationSchema from '../schemas/schedulesForm';

const DEFAULT_TIME_ZONE = 'US/Eastern';

const TIMEZONES = {
  'America/New_York': 'US/Eastern',
  'America/Los_Angeles': 'US/Pacific',
  'America/Denver': 'US/Mountain',
  'America/Phoenix': 'US/Arizona',
  'America/Chicago': 'US/Central',
};

const FORM_DEFAULT_VALUES = {
  schedule: {
    user_id: null,
    location_id: null,
    weekday: null,
    start_time: null,
    end_time: null,
    working_location: null,
    time_zone: DEFAULT_TIME_ZONE,
  },
};

const getOptions = (options) =>
  options.map((option) => {
    if (Array.isArray(option)) {
      const [label, value] = option;
      const timeZone = option[2]?.location_time_zone;

      if (timeZone) {
        return { label, value, timeZone };
      }

      return { label, value };
    }

    return {
      label: option,
      value: option,
    };
  });

const getInputDefaultValue = (value, options) => {
  if (options) {
    return getOptions(options).find((option) => option.value === value) || null;
  }

  return {
    label: value,
    value,
  };
};

const getTimeZone = (location) => TIMEZONES[location?.timeZone] || DEFAULT_TIME_ZONE;

const getTime = (time) => {
  if (!time) return null;

  const [hours, minutes] = time.split(':');
  return moment().set({ hours, minutes });
};

function SchedulesForm({ staffOptions, locationOptions, dayOptions, workingLocationOptions, defaultValues }) {
  const defaultLocation = getInputDefaultValue(defaultValues?.['schedule[location_id]'], locationOptions);
  const formDefaultValues = defaultValues
    ? {
        schedule: {
          user_id: getInputDefaultValue(defaultValues['schedule[user_id]'], staffOptions),
          location_id: defaultLocation,
          weekday: getInputDefaultValue(defaultValues['schedule[weekday]']),
          start_time: getTime(defaultValues['schedule[start_time]']),
          end_time: getTime(defaultValues['schedule[end_time]']),
          working_location: getInputDefaultValue(defaultValues['schedule[working_location]']),
          time_zone: getTimeZone(defaultLocation),
        },
      }
    : FORM_DEFAULT_VALUES;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef(null);
  const { control, setValue, reset, handleSubmit } = useForm({
    resolver: yupResolver(schedulesFormValidationSchema),
    defaultValues: formDefaultValues,
  });

  useEffect(() => {
    const resetForm = () => reset();
    formRef.current.addEventListener('ajax:success', resetForm);

    return () => {
      formRef.current.removeEventListener('ajax:success', resetForm);
    };
  }, [formRef, reset]);

  useEffect(() => {
    const onAjaxFinished = () => setIsSubmitting(false);
    formRef.current.addEventListener('ajax:complete', onAjaxFinished);

    return () => formRef.current.removeEventListener('ajax:complete', onAjaxFinished);
  }, [formRef, setIsSubmitting]);

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsSubmitting(true);

    handleSubmit(
      () => {
        formRef.current.requestSubmit();
      },
      () => setIsSubmitting(false)
    )();
  };

  return (
    <>
      <div
        className="bg-white"
        ref={(element) => {
          formRef.current = element?.closest('form');
        }}
      >
        <p className="whitespace-pre-wrap text-error-700 font-bold text-sm error my-2" data-modal-target="errors" />
        <div>
          <div className="mb-4 mr-8 ml-8 pt-4">
            <Controller
              name="schedule[user_id]"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <SelectInput
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="Select a staff..."
                    label="Staff"
                    options={getOptions(staffOptions)}
                  />
                  {fieldState.error && <p className="text-red-500 text-xs italic">{fieldState.error.message}</p>}
                </>
              )}
            />
          </div>
          <hr className="w-full mt-4" />
          <div className="mb-4 mr-8 ml-8 mt-4">
            <Controller
              name="schedule[location_id]"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <SelectInput
                    name={field.name}
                    value={field.value}
                    onChange={(value) => {
                      setValue('schedule[time_zone]', getTimeZone(value));
                      field.onChange(value);
                    }}
                    placeholder="Select an office.."
                    label="Office"
                    options={getOptions(locationOptions)}
                  />
                  {fieldState.error && <p className="text-red-500 text-xs italic">{fieldState.error.message}</p>}
                </>
              )}
            />
          </div>
          <hr className="w-full mt-4" />
          <div className="mr-8 ml-8 mt-4 mb-4">
            <Controller
              name="schedule[weekday]"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <SelectInput
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="Select a day..."
                    label="Day of the Week"
                    options={getOptions(dayOptions)}
                  />
                  {fieldState.error && <p className="text-red-500 text-xs italic">{fieldState.error.message}</p>}
                </>
              )}
            />
          </div>
          <hr className="w-full mt-4" />
          <div className="mr-8 ml-8 mt-4 grid overflow-hidden grid-cols-1 lg:grid-cols-2 grid-rows-auto gap-4 text-left">
            <div>
              <Controller
                name="schedule[start_time]"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <TimeInput
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      label="Start Time"
                      placeholder="--:--"
                    />
                    {fieldState.error && <p className="text-red-500 text-xs italic">{fieldState.error.message}</p>}
                  </>
                )}
              />
            </div>
            <div>
              <Controller
                name="schedule[end_time]"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <TimeInput
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      label="End Time"
                      placeholder="--:--"
                    />
                    {fieldState.error && <p className="text-red-500 text-xs italic">{fieldState.error.message}</p>}
                  </>
                )}
              />
            </div>
          </div>
          <div className="mb-4 mr-8 ml-8 mt-4">
            <Controller
              name="schedule[working_location]"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <SelectInput
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="Select a location..."
                    label="Working Location"
                    options={getOptions(workingLocationOptions)}
                  />
                  {fieldState.error && <p className="text-red-500 text-xs italic">{fieldState.error.message}</p>}
                </>
              )}
            />
          </div>
          <div className="mb-4 mr-8 ml-8 mt-4 pb-4" style={{ pointerEvents: 'none' }}>
            <Controller
              name="schedule[time_zone]"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <Input
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    label="Time Zone"
                  />
                  {fieldState.error && <p className="text-red-500 text-xs italic">{fieldState.error.message}</p>}
                </>
              )}
            />
          </div>
        </div>
      </div>
      <div className="bg-blue-100 border-t border-gray-400 w-full grid overflow-hidden grid-cols-2 grid-rows-1 gap-4 text-left pl-4 pr-4 pt-4">
        <button
          type="button"
          className="justify-center btn btn--rounded btn--secondary flex-1 mr-2"
          data-action="click->modal#hideModal"
          onClick={reset}
          disabled={isSubmitting}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="btn btn--primary h-12 flex items-center mb-1"
          onClick={onSubmit}
          disabled={isSubmitting}
        >
          Save
        </button>
      </div>
    </>
  );
}

SchedulesForm.propTypes = {
  staffOptions: PropTypes.array.isRequired,
  locationOptions: PropTypes.array.isRequired,
  dayOptions: PropTypes.array.isRequired,
  workingLocationOptions: PropTypes.array.isRequired,
  defaultValues: PropTypes.shape({
    'schedule[user_id]': PropTypes.number,
    'schedule[location_id]': PropTypes.number,
    'schedule[weekday]': PropTypes.string,
    'schedule[start_time]': PropTypes.string,
    'schedule[end_time]': PropTypes.string,
    'schedule[working_location]': PropTypes.string,
    'schedule[time_zone]': PropTypes.string,
  }),
};

SchedulesForm.defaultProps = {
  defaultValues: null,
};

export default SchedulesForm;
