import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';

// ICONS
import { PiCaretUpBold, PiCaretDownBold } from 'react-icons/pi';

import Tooltip from './Tooltip';

const Button = forwardRef(
  (
    {
      onClick,
      classes,
      children,
      show,
      isSelected,
      isDefaultButton,
      isPrimary,
      isSecondary,
      isTertiary,
      isWarning,
      isSuccess,
      isFloatRight,
      style,
      withArrowIcon,
      isArrowUp,
      disabled,
      disabledMessage,
      isLoading,
      type,
      form,
      isPrimaryMarginBottom,
      testID,
    },
    ref
  ) => {
    const primaryClassNames = classNames('btn--primary h-12 flex items-center', {
      'mb-1': isPrimaryMarginBottom,
    });

    const buttonClassName = classNames({
      [classes]: !!classes,
      [primaryClassNames]: isPrimary,
      'btn--state-selected': isSelected,
      'btn--secondary btn--rounded': isSecondary,
      'btn text-teal-700': isTertiary || isDefaultButton,
      'btn--warning': isWarning,
      'btn--success': isSuccess,
      'float-right flex items-center mx-auto my-auto mb-auto': isFloatRight,
    });

    function renderWithArrowIcon() {
      if (!withArrowIcon) {
        return <></>;
      }

      if (isArrowUp) {
        return <PiCaretUpBold className="my-auto ml-1" style={{ strokeWidth: '10px' }} />;
      }
      return <PiCaretDownBold className="my-auto ml-1" style={{ strokeWidth: '10px' }} />;
    }

    function renderText() {
      if (isLoading) {
        return (
          <div className="flex align-center justify-center gap-4">
            {children}
            <CircularProgress style={{ width: '1.5rem', height: '1.5rem', color: 'var(--color-black)' }} />
          </div>
        );
      }

      if (!withArrowIcon) {
        return <>{children}</>;
      }

      return (
        <div className="flex">
          {children}

          {renderWithArrowIcon()}
        </div>
      );
    }

    const getWrapper = (content) => {
      if (disabled && disabledMessage) {
        return (
          <Tooltip text={disabledMessage} isSecondaryVariant>
            {content}
          </Tooltip>
        );
      }

      return content;
    };

    if (!show) return null;
    return getWrapper(
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        form={form}
        disabled={disabled || isLoading}
        onClick={onClick}
        className={buttonClassName}
        style={style}
        data-testid={testID}
        ref={ref}
      >
        {renderText()}
      </button>
    );
  }
);

Button.displayName = 'Button';

Button.propTypes = {
  show: PropTypes.bool,
  isSelected: PropTypes.bool,
  isDefaultButton: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isTertiary: PropTypes.bool,
  isWarning: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isFloatRight: PropTypes.bool,

  // ICONS
  withArrowIcon: PropTypes.bool,
  isArrowUp: PropTypes.bool,

  children: PropTypes.node,

  classes: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  // eslint-disable-next-line react/require-default-props
  form: PropTypes.string,
  isPrimaryMarginBottom: PropTypes.bool,
  testID: PropTypes.string,
};

Button.defaultProps = {
  show: true,
  isSelected: false,
  isDefaultButton: true,
  isPrimary: false,
  isSecondary: false,
  isTertiary: false,
  isWarning: false,
  isSuccess: false,
  isFloatRight: false,
  disabled: false,
  isLoading: false,

  disabledMessage: null,

  // ICONS
  withArrowIcon: false,
  isArrowUp: false,

  children: null,

  classes: null,
  style: {},
  type: 'button',
  form: '',
  isPrimaryMarginBottom: true,
  testID: undefined,
};

export default Button;
