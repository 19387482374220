import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { HiChevronDown, HiChevronUp } from 'react-icons/hi';

import Button from '../../../shared/Button';
import useFilterData from './fetch-filter-data';
import AvailableFilters from './AvailableFilters';
import AppliedFilters from '../../../shared/AppliedFilters';
import Toasts from '../../../shared/Toasts';
import useToasts from '../../../shared/hooks/use-toasts';
import useFilters from '../../../shared/hooks/use-filters';
import Tabs from '../../../Tabs';
import { DEFAULT_FILTERS, PageTabs, FILTER_TYPES } from './constants';
import BookAppointmentTab from './BookAppointmentTab';
import ScheduledTab from './ScheduledTab';
import { AppointmentsURLsController } from './useAppointmentsURLs';
import { OptionsPropTypes, OptionPropType } from '../shared/types';
import { SESSION_STORAGE_KEYS } from '../../../../helpers/constants';

const testID = 'appointmentsMainEmp';
function Main({
  authenticityToken,
  secondaryGroupTypeFeatureFlag,
  appointmentFinderMemberAssignmentPermission,
  memberSummaryUrl,
  appointmentFinderUrl,
  reasons,
  currentUserProvider,
}) {
  const { toasts, addToast, removeToast } = useToasts();
  const [activeTab, setTab] = useState(PageTabs[0]);

  const allTabsFilters = {
    Scheduled: useFilters({
      ...DEFAULT_FILTERS.scheduled,
      [FILTER_TYPES.provider]: currentUserProvider ? [currentUserProvider] : [],
    }),
    'Book Appointment': useFilters(DEFAULT_FILTERS.book_appointment),
  };

  const { filters, setFilter, addFilter, filtersArray } = allTabsFilters[activeTab.name];

  const {
    daysData,
    facilitiesData,
    statesData,
    availabilityData,
    providersData,
    appointmentTypesData,
    isLoading: isFilterDataLoading,
  } = useFilterData();

  useEffect(() => {
    const isAppointmentCancelled = sessionStorage.getItem(SESSION_STORAGE_KEYS.SHOW_APPOINTMENT_CANCELLED_TOAST);

    if (isAppointmentCancelled === 'true') {
      addToast({
        header: 'Appointment has been canceled',
        type: 'error',
      });
      sessionStorage.removeItem(SESSION_STORAGE_KEYS.SHOW_APPOINTMENT_CANCELLED_TOAST);
    }
  }, [addToast]);

  const [showFilters, setShowFilters] = useState(false);
  const FilterBtnIcon = showFilters ? HiChevronUp : HiChevronDown;

  const toggleFilters = () => setShowFilters((s) => !s);

  return (
    <AppointmentsURLsController memberSummaryUrl={memberSummaryUrl} appointmentFinderUrl={appointmentFinderUrl}>
      <div data-testid={testID} className="mt-3">
        <Toasts toasts={toasts} removeToast={removeToast} className="mb-6" isSecondaryVariant />
        <div className="border-b border-gray-400 pb-2 flex justify-between flex-row items-end">
          <div>
            <h2 data-testid={`title-${testID}`} className="font-body font-semibold mb-1">
              Appointment Finder
            </h2>
          </div>
          <Button testID={`toggle-filters-button-${testID}`} onClick={toggleFilters} isPrimary classes="max-h-12">
            <div className="flex items-center">
              Apply Filters
              <FilterBtnIcon className="text-2xl ml-2" />
            </div>
          </Button>
        </div>
        <AppliedFilters currentlyAppliedFilters={filtersArray} />
        {showFilters && !isFilterDataLoading && (
          <AvailableFilters
            currentlyAppliedFilters={filters}
            appointmentTypesData={appointmentTypesData}
            availabilityData={availabilityData}
            providersData={providersData}
            daysData={daysData}
            facilitiesData={facilitiesData}
            statesData={statesData}
            setFilter={setFilter}
            addFilter={addFilter}
            showAvailabilityFilter={activeTab.id === 1}
            testID={testID}
          />
        )}
        <div className="mt-4">
          <Tabs activeTab={activeTab} tabs={PageTabs} onChange={setTab} testID={testID} />
          {activeTab.id === 0 && <ScheduledTab filters={filters} />}
          {activeTab.id === 1 && (
            <BookAppointmentTab
              authenticityToken={authenticityToken}
              secondaryGroupTypeFeatureFlag={secondaryGroupTypeFeatureFlag}
              appointmentFinderMemberAssignmentPermission={appointmentFinderMemberAssignmentPermission}
              addToast={addToast}
              filters={filters}
              reasons={reasons}
            />
          )}
        </div>
      </div>
    </AppointmentsURLsController>
  );
}

Main.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  secondaryGroupTypeFeatureFlag: PropTypes.bool.isRequired,
  appointmentFinderMemberAssignmentPermission: PropTypes.bool.isRequired,
  memberSummaryUrl: PropTypes.string.isRequired,
  appointmentFinderUrl: PropTypes.string.isRequired,
  reasons: OptionsPropTypes.isRequired,
  currentUserProvider: OptionPropType,
};

Main.defaultProps = {
  currentUserProvider: null,
};

export default Main;
